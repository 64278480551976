import { ScrollView, View } from "react-native";
import tailwind from "twrnc";
import RNModal from "react-native-modal";
import { width } from "../constants/constants";

const Modal = ({ children, visible, setVisible, maxHeight }) => {
  return (
    <View>
      <RNModal
        hasBackdrop={true}
        backdropOpacity={0.5}
        backdropColor="black"
        isVisible={visible}
        onRequestClose={() => {
          setVisible(!visible);
        }}
        onBackdropPress={() => setVisible(false)}
      >
        <View style={tailwind`${width > 500 && "flex items-center"}`}>
          <View style={tailwind`${width > 500 && "max-w-[500px] w-full"} `}>
            {maxHeight ? (
              <ScrollView
                style={tailwind`bg-white px-5 py-5 rounded-lg  max-h-[${maxHeight}px] `}
              >
                {children}
              </ScrollView>
            ) : (
              <View style={tailwind`bg-white px-5 py-5 rounded-lg`}>
                {children}
              </View>
            )}
          </View>
        </View>
      </RNModal>
    </View>
  );
};

export default Modal;
