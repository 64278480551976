import React, { useState, useEffect } from "react";
import {
	TouchableOpacity,
	View,
	ActivityIndicator,
	StyleSheet,
	FlatList
} from "react-native";
import tailwind from "twrnc";
import { Feather } from "@expo/vector-icons";
import FilterModal from "../common/filter_modal";
import useClassesFilter from "../common/context";
import { TEACHERS } from "../../../../../api/instance";
import useUser from "../../../../../context/user";
import Text from "../../../../../components/Text";
import SessionItem from "./session_items";
import moment from "moment";
import { height, isWeb } from "../../../../../constants/constants";

const OneToOne = () => {
	let { user } = useUser();
	const [modalOpen, setModalOpen] = useState(false);
	let [sessions, setSessions] = useState([]);
	let [loading, setLoading] = useState(true);
	let [sessionStatuses, setSessionStatuses] = useState([]);
	let [currentPage, setCurrentPage] = useState(1);
	let [lastPage, setLastPage] = useState(0);
	let [loadMoreLoader, setLoadMoreLoader] = useState(false);

	// Access Range here
	const { range, status, setStatuses, teacherSelected, slot } =
		useClassesFilter();

	useEffect(() => {
		getSessionStatuses();
		if (user.is_live_teacher) {
			getTeacherClasses()
		} else {
			getClasses();
		}

	}, []);

	async function getSessionStatuses() {
		await TEACHERS.get(`/statuses?type=SESSION`)
			.then((response) => {
				let res = response.data.data;
				setSessionStatuses(response.data.data);
				let newArray = res.map((item, index) => {
					return { key: index + 1, value: item.slug };
				});
				setStatuses(newArray);
			})
			.catch((error) => {
				setSessionStatuses([]);
				console.log(error);
			});
	}

	async function getClasses(getMore = false) {
		let params = {
			phone: user.phone,
			limit: 15,
			page: getMore ? currentPage + 1 : 1,
		};

		// if (range && range.startDate && range.endDate) {
		// 	params.start_date = moment(range.startDate).format("DD-MM-YYYY");
		// 	params.end_date = moment(range.endDate).format("DD-MM-YYYY");
		// }

		if (status) {
			params.status = status;
		}

		if (teacherSelected) {
			params.teacher_id = teacherSelected.id;
		}

		if (slot) {
			params.slot = slot;
		}

		// await TEACHERS.get(`/session/all`, { params })
		await TEACHERS.post(`/session/all`, params)
			.then((response) => {
				// setSessions([...sessions, ...response.data.data.data]);
				setSessions((prev) => [...prev, ...response.data.data.data]);
				setLastPage(response.data.data.last_page);
				setLoading(false);
				setLoadMoreLoader(false);
			})
			.catch((error) => {
				console.log(error.response.data);
				setLoading(false);
				setLoadMoreLoader(false);
			});
	}

	async function getTeacherClasses(getMore = false) {
		let params = {
			phone: user.phone,
			app_user_id: user._id,
			limit: 15,
			page: getMore ? currentPage + 1 : 1,
		};

		if (range && range.startDate && range.endDate) {
			params.start_date = moment(range.startDate).format("DD-MM-YYYY");
			params.end_date = moment(range.endDate).format("DD-MM-YYYY");
		}

		if (status) {
			params.status = status;
		}

		if (teacherSelected) {
			params.teacher_id = teacherSelected.id;
		}

		if (slot) {
			params.slot = slot;
		}

		await TEACHERS.post(`/teacher/session/all`, params)
			.then((response) => {
				// setSessions([...sessions, ...response.data.data.data]);
				setSessions((prev) => [...prev, ...response.data.data.data]);
				setLastPage(response.data.data.last_page);
				setLoading(false);
				setLoadMoreLoader(false);
			})
			.catch((error) => {
				console.log(error.response.data);
				setLoading(false);
				setLoadMoreLoader(false);
			});
	}

	let renderLoader = () => {
		return loadMoreLoader ? (
			<View>
				<ActivityIndicator />
			</View>
		) : null;
	};

	let loadMoreItem = () => {
		if (!loadMoreLoader) {
			if (lastPage != currentPage) {
				setLoadMoreLoader(true);
				setCurrentPage((prev) => prev + 1);
				if (user.is_live_teacher) {
					getTeacherClasses(true)
				} else {
					getClasses(true);
				}
			} else setLoadMoreLoader(false);
		}
	};

	let filterApply = () => {
		setSessions([]);
		setLoadMoreLoader(false);
		setLoading(true);
		setCurrentPage(1);
		setLastPage(1);
		if (user.is_live_teacher) {
			getTeacherClasses()
		} else {
			getClasses();
		}
	};

	return (
		<View style={tailwind`w-full mt-5`}>
			<FilterModal
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
				getClasses={filterApply}
			/>
			<TouchableOpacity
				onPress={() => setModalOpen(true)}
				style={tailwind`border border-[#9E9E9E] min-h-[80px] rounded px-5 py-3 w-full bg-[#fafafa] relative`}
			>
				<Feather
					style={tailwind`absolute right-[10px] top-[10px]`}
					name="filter"
					size={24}
					color="black"
				/>

				<View style={[tailwind`flex-row`]}>
					{range.startDate && range.endDate && (
						<View style={[tailwind`flex-row`]}>
							<Text
								style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-[12px]`}
							>
								{moment(range.startDate).format("DD-MM-YYYY")}
							</Text>
							<Text style={tailwind`text-center mx-1`}>-</Text>
							<Text
								style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-[12px]`}
							>
								{moment(range.endDate).format("DD-MM-YYYY")}
							</Text>
						</View>
					)}

					{status && (
						<Text
							style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-[12px] mx-2`}
						>
							{status}
						</Text>
					)}

					{slot && (
						<Text
							style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-[12px] mx-2`}
						>
							{slot}
						</Text>
					)}

					{teacherSelected && (
						<Text
							style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-[12px] mx-2`}
						>
							{teacherSelected.name}
						</Text>
					)}
				</View>



				{(!range.startDate && !status && !slot && !teacherSelected) && (
					<View style={[tailwind`flex-row`]}>
						<Text>Add Filters</Text>
					</View>
				)}
			</TouchableOpacity>

			{!loading && sessions.length > 0 && (
				<View style={[tailwind`mt-2 w-full`, { maxHeight: height - 260 }]}>
					<Text style={tailwind`text-blue-500 font-bold`}>
						Total Records : {sessions.length}
					</Text>

					<FlatList
						data={sessions}
						renderItem={({ item }) => <SessionItem item={item} />}
						keyExtractor={(item, i) => i}
						style={{ marginBottom: isWeb ? 0 : 20 }}
						ListFooterComponent={renderLoader}
						onEndReached={loadMoreItem}
						onEndReachedThreshold={0.2}
					/>
				</View>
			)}

			{loading && !loadMoreLoader && (
				<View style={[tailwind` my-4 w-full h-full`, styles.loadercontainer]}>
					<ActivityIndicator />
				</View>
			)}

			{!loading && !loadMoreLoader && sessions.length == 0 && (
				<View
					style={[tailwind` my-4 w-full justify-center h-full items-center`]}
				>
					<Text>Records Not Found.</Text>
				</View>
			)}
		</View>
	);
};

export default OneToOne;

const styles = StyleSheet.create({
	loadercontainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		verticalAlign: "middle",
		marginTop: 20,
		// marginBottom: "50%",
	},
	activityIndicator: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		verticalAlign: "middle",
	},
});
