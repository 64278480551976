import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../components/Text";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import Modal from "../../../components/modal";
import { useNavigation } from "@react-navigation/native";
import useClasses from "../../../context/classes";

const HomeHeader = () => {
  const [supportModal, setSupportModal] = useState(false);

  const { chatUnread, notificationsUnread } = useClasses();

  const { navigate } = useNavigation();

  return (
    <View
      style={tailwind` px-5 flex flex-row h-full items-center justify-between`}
    >
      <Text style={tailwind`text-[18px] font-semibold`}>Home</Text>

      <View style={tailwind`flex items-center h-full  flex-row gap-x-4`}>
        <TouchableOpacity onPress={() => navigate("Chats")}>
          <Ionicons name="chatbox-ellipses" size={28} color="#2096F3" />
          {chatUnread > 0 && (
            <View
              style={tailwind`absolute top-[-5px] left-[-10px] bg-[#FF9958] rounded-full w-[20px] h-[20px] justify-center items-center`}
            >
              <Text style={tailwind`text-white text-xs`}>{chatUnread}</Text>
            </View>
          )}
        </TouchableOpacity>

        <TouchableOpacity onPress={() => navigate("Notifications")}>
          <AntDesign name="bells" size={26} color="#2096F3" />

          {notificationsUnread > 0 && (
            <View
              style={tailwind`absolute top-[-5px] right-[-10px] bg-[#FF9958] rounded-full w-[20px] h-[20px] justify-center items-center`}
            >
              <Text style={tailwind`text-white text-xs`}>
                {notificationsUnread}
              </Text>
            </View>
          )}
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => setSupportModal(true)}
          style={tailwind`flex flex-row items-center bg-[#2096F3] px-3 py-2 rounded`}
        >
          <Ionicons name="call" size={20} color="#fff" />
          <Text style={tailwind`ml-2 text-[#fff] font-medium`}>Support</Text>
        </TouchableOpacity>

        <Modal visible={supportModal} setVisible={setSupportModal}>
          <View style={tailwind`flex items-start w-full`}>
            <View
              style={tailwind`flex flex-row items-center w-full justify-between`}
            >
              <Text style={tailwind.style("text-[16px] font-semibold")}>
                Support
              </Text>

              <AntDesign
                name="close"
                size={18}
                color="black"
                onPress={() => setSupportModal(false)}
              />
            </View>
            <Text style={tailwind`mt-5 mb-1 opacity-50 text-[14px]`}>
              For any help please contact us at
            </Text>
            <Text>
              Email:
              <Text style={tailwind`text-[#2096f3] underline`}>
                {" "}
                support@multibhashi.com
              </Text>
            </Text>

            <TouchableOpacity
              onPress={() => setSupportModal(false)}
              style={tailwind`bg-[#2096f3] mt-5 px-5 py-2 rounded-lg`}
            >
              <Text style={tailwind`text-[#fff] font-medium`}>Close</Text>
            </TouchableOpacity>
          </View>
        </Modal>
      </View>
    </View>
  );
};

export default HomeHeader;
