import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import SecondaryHeader from "../../headers/secondary.header";
import { ActivityIndicator, View } from "react-native";
import Text from "../../../../components/Text";
import tailwind from "twrnc";
import { TEACHERS } from "../../../../api/instance";
import RecordingPlayer from "./recording_player";
import Alert from "../../../../utils/alert";

const Recording = ({ route }) => {
	const { params } = route;

	const [loading, setLoading] = useState(true);
	const [recordings, setRecordings] = useState([]);

	const getRecordings = () => {
		setLoading(true);
		// const payload = `?type=${params.type}&${params.type === "SESSION"
		// 	? `session_id=${params.id}`
		// 	: `session_id=${params.id}`
		// 	}`;

		const payload = `?type=${params.type}&session_id=${params.id}`;

		TEACHERS.get(`/recordings${payload}`)
			.then(({ data }) => {
				setRecordings(data.data);
				setLoading(false);
			})
			.catch((error) => {
				console.log("Recording error")
				if (error?.response?.data?.data?.code == "old_recording_access_disabled") {
					Alert.alert1("An error occured; please contact support@multibhashi.com");
				} else {
					Alert.alert("An error occured, please try again");
				}
				setLoading(false);
			});
	};

	useEffect(() => {
		getRecordings();
	}, []);

	return (
		<Layout
			tabbarVisible={false}
			HeaderContent={<SecondaryHeader title="Recording" />}
			scrollView={false}
		>
			{loading ? (
				<View style={tailwind`w-full h-full flex items-center mt-10`}>
					<ActivityIndicator />
				</View>
			) : (
				<View style={tailwind`w-full`}>
					{recordings && recordings[0] ? (
						<View>
							<RecordingPlayer recordings={recordings} />
						</View>
					) : (
						<View style={tailwind`w-full h-full flex items-center mt-10`}>
							<Text>Recording is not available</Text>
						</View>
					)}
				</View>
			)}
		</Layout>
	);
};

export default Recording;
