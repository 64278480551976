import React from "react";
import { Image, SafeAreaView, ScrollView, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../components/Text";
import { height, width } from "../../constants/constants";
import Toast from "react-native-toast-message";

const AuthLayout = ({ children, showHeader = true }) => {
  return (
    <>
      <SafeAreaView>
        <ScrollView behavior="padding" style={tailwind`w-[${width}px]`}>
          {showHeader && (
            <View style={tailwind`px-5 mt-5 flex justify-center`}>
              <View style={tailwind`flex flex-row items-center`}>
                <Image
                  source={require("../../../assets/logo_with_shadow.png")}
                  style={tailwind`w-[35px] h-[35px]`}
                />
                <Text
                  style={tailwind`text-[#2C9CDB] font-bold text-[20px] ml-1`}
                >
                  Multibhashi
                </Text>
              </View>
            </View>
          )}

          <View
            style={tailwind`flex flex-col mt-5 items-center ${
              showHeader ? "h-[90%]" : "h-full"
            }`}
          >
            <View style={tailwind`w-[${width <= 500 ? width : 500}px] h-full `}>
              <View
                style={tailwind`flex flex-col items-center w-full px-5  ${
                  showHeader ? "h-full" : `h-[${height}px]`
                }
              relative`}
              >
                {children}
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
      <Toast />
    </>
  );
};

export default AuthLayout;
