import { createContext, useContext } from "react";
import { UserContextProvider } from "./user";
import { ClassesContextProvider } from "./classes";
import { AppCoursesContextProvider } from "./app_courses";
import { ChatContextProvider } from "../screens/dashboard/screens/chats/chat/context";
import { QuestionProvider } from "../screens/dashboard/screens/courses/partials/common/context";

import { QuizProvider } from "../screens/dashboard/screens/quizzes/common/context";
const AppContext = createContext();

const useApp = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
  return (
    <AppContext.Provider value={{}}>
      <UserContextProvider>
        <ClassesContextProvider>
          <ChatContextProvider>
            <QuestionProvider>
              <QuizProvider>
                {children}
              </QuizProvider>
            </QuestionProvider>
          </ChatContextProvider>
        </ClassesContextProvider>

      </UserContextProvider>
    </AppContext.Provider>
  );
};

export default useApp;
