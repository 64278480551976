import { TouchableOpacity, View } from "react-native";
import { Bubble } from "react-native-gifted-chat";
import useUser from "../../../../../../context/user";
import tailwind from "twrnc";
import useChat from "../context";
import { isWeb } from "../../../../../../constants/constants";
import ChatBubbleWeb from "./chat_bubble_web";

const ReportMessage = (props) => {
  const { currentMessage: message } = props;
  const { user } = useUser();
  const { messageReportIds, setMessageReportIds } = useChat();
  const showCheck = user._id !== message.sender_id;

  const checked = messageReportIds.includes(message._id);

  const handleToggle = () => {
    if (messageReportIds.includes(message._id)) {
      setMessageReportIds(messageReportIds.filter((id) => id !== message._id));
    } else {
      setMessageReportIds([...messageReportIds, message._id]);
    }
  };

  return (
    <View style={tailwind`flex flex-row items-center`}>
      {showCheck && (
        <TouchableOpacity
          onPress={handleToggle}
          style={tailwind`w-[15px] h-[15px]  rounded-full mr-2 ${
            checked ? "bg-blue-600" : "border"
          }`}
        ></TouchableOpacity>
      )}
      {!isWeb ? <Bubble {...props} /> : <ChatBubbleWeb {...props} />}
    </View>
  );
};

export default ReportMessage;
