import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList,
    Platform,
    ActivityIndicator,
    Button
} from "react-native";
import React, { useState, useEffect } from "react";
import tailwind from "twrnc";
import { height, isWeb, width } from "../../../../../constants/constants";
import { useNavigation, useNavigationParam } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";

import { API } from "../../../../../api/instance";
import Layout from "../../../layout";
import PageHeader from "../../../headers/page.header";
let wd = width <= 500 ? width : 499.99
import useQuestion from "./common/context";
import moment from "moment";
import useUser from "../../../../../context/user";
import _ from "lodash";

export default function LessonFinished(props) {
    const { navigate } = useNavigation();
    let { user } = useUser();

    const {
        module,
        unit,
        setUnit,
        setLesson,
        setQuestions,
        setQuestionIds,
        setCurrentQuestionIndex,
        setLessonStartTimestamp,
        total_correct_answer,
        setTotalCorrectAnswer,
        total_incorrect_answer,
        setTotalIncorrectAnswer,
        setTotalQuestionAttempt,
        total_question,
        setTotalQuestion,
        current_unit_index,
        setCurrentUnitIndex,
        current_lesson_index,
        setCurrentLessonIndex,
        current_level_index,
        wq_text_answer,
        setWqTextAnswer
    } = useQuestion();

    let is_course_finished = props.route?.params?.is_course_finished;

    let percentage = (total_correct_answer / total_question) * 100
    percentage = Math.round(percentage)

    useEffect(() => {
    }, []);

    let [card_colors, setCardColors] = useState([
        "EBFFFF",
        "FFF5E9",
        "FFE8E7",
        "FFF5E9",
        "FFE8DF",
        "E7F0FF"
    ]);

    let [lesson_passed_images, setLessonPassedImages] = useState([
        "HIN_BOY_kisses_half.png",
        "HIN_GIRL_kisses_half.png"
    ]);

    let [lesson_failed_images, setLessonFailedImages] = useState([
        "HIN_BOY_straight_half.png",
        "HIN_GIRL_straight_half.png"
    ]);

    let tryThisLessonAgain = () => {
        setCurrentQuestionIndex(0)
        setLessonStartTimestamp(moment().format('x'))
        let obj = {
            from_page: "Modules",
        }
        navigate("Questions", obj)
    }

    let nextLesson = async () => {
        if (unit.lessons.length == current_lesson_index + 1) {
            let find_unit_index = _.findIndex(module.levels[current_level_index].units, function (o) { return o._id == unit._id; });

            if (find_unit_index + 1 != module.levels[current_level_index].units.length) {
                setCurrentUnitIndex(find_unit_index + 1)
                setCurrentLessonIndex(0)
                let question_ids = module.levels[current_level_index].units[find_unit_index + 1].lessons[0].questions

                if (question_ids.length > 0) {
                    try {
                        let qns = await getQuestionByIds(question_ids)
                        let res = qns.data.data
                        setTotalCorrectAnswer(0)
                        setTotalIncorrectAnswer(0)
                        setTotalQuestionAttempt(0)
                        setQuestions(res)
                        setCurrentQuestionIndex(0)
                        setUnit(module.levels[current_level_index].units[find_unit_index + 1])
                        setLesson(module.levels[current_level_index].units[find_unit_index + 1].lessons[0])
                        setQuestionIds(question_ids)
                        setLessonStartTimestamp(moment().format('x'))
                        let obj = {
                            from_page: "Modules",
                        }

                        let total_qns = 0
                        const q_arr = res.filter(x =>
                            x.type_code != "LC" &&
                            x.type_code != "CVC" &&
                            x.type_code != "LCL"
                        );
                        setTotalQuestion(q_arr.length)

                        navigate("Questions", obj)
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }
    }

    async function getQuestionByIds(question_ids) {
        let params = {
            questions: question_ids,
        };
        return await API.post(`questions/ids`, params)
    }


    let redirectCourseCompleted = () => {
        navigate("CourseCompleted")
    }

    return (
        <Layout
            HeaderContent={
                <PageHeader backRoute={"Home"} pageTitle={unit.name} bgColor={"#17AAF5"} textColor={"#f7faf8"} />
            }
            tabbarVisible={false}
        // scrollView={false}
        >
            <View style={[tailwind`w-full h-full`]}>

                <View style={tailwind` flex flex-row w-full text-center items-center`}>
                    <View style={[tailwind`w-full px-0 text-center items-center`]}>
                        {
                            percentage >= 50 &&

                            <Image
                                source={require(`../../../../../../assets/datacharacter_illustrations/${lesson_passed_images[Math.floor(Math.random() * lesson_passed_images.length)]}`)}
                                style={[tailwind``, styles.img]}
                            />
                        }

                        {
                            percentage < 50 &&
                            <Image
                                source={require(`../../../../../../assets/datacharacter_illustrations/${lesson_failed_images[Math.floor(Math.random() * lesson_failed_images.length)]}`)}
                                style={[tailwind``, styles.img]}
                            />
                        }
                    </View>

                </View>

                <View style={[tailwind`px-2 shadow-lg mx-4 rounded py-4 text-center items-center`]}>
                    {
                        percentage >= 50 &&
                        <Text style={[tailwind`text-black font-bold text-[20px]`]}>
                            Awesome...
                        </Text>
                    }

                    {
                        percentage < 50 &&
                        <Text style={[tailwind`text-black font-bold text-[20px]`]}>
                            You Can Do Much Better!
                        </Text>
                    }

                    <View style={tailwind`mt-5 flex flex-row w-full text-center items-center justify-center`}>

                        <View style={tailwind`flex flex-row text-center items-center`}>
                            <Ionicons name="checkmark-circle-outline" size={23} color="#14b8a6" />
                            <Text style={[tailwind`pl-2 text-slate-500 font-semibold text-[16px] items-center`]}>
                                {total_correct_answer}/{total_question}
                            </Text>
                        </View>

                        <View style={[tailwind`mx-2`, styles.verticleLine]}></View>


                        <View style={tailwind`flex flex-row text-center items-center justify-center`}>
                            <Ionicons name="close-circle-outline" size={23} color="#eb0712" />
                            <Text style={[tailwind`pl-2 text-slate-500 font-semibold text-[16px] items-center`]}>
                                {total_incorrect_answer}/{total_question}
                            </Text>
                        </View>
                    </View>
                </View>

                {
                    percentage < 50 && <View style={[tailwind`px-4 mt-4`, {
                        width: (wd - 40)
                    }]}>
                        <Pressable
                            style={[tailwind``, styles.buttonStyle, {
                                backgroundColor: "#3EBD3E"
                            }]}
                            onPress={() => tryThisLessonAgain()}
                        >
                            <Text style={[styles.buttonText, {
                                color: "#FFFFFF"
                            }]}> Try This Lesson Again </Text>
                        </Pressable>
                    </View>
                }


                {!is_course_finished && current_unit_index + 1 < module.levels[0].units.length && <Pressable
                    style={[tailwind`px-2 mt-4 shadow-lg mx-4 rounded py-6  bg-[#FFF1E7] relative`, { backgroundColor: "#" + card_colors[Math.floor(Math.random() * card_colors.length)] }]}
                    onPress={() => nextLesson()}
                >
                    <Image
                        style={tailwind`h-[115px] w-[110px] absolute bottom-0 right-0`}
                        source={require("../../../../../../assets/img_background_expiry_card.png")}
                    />
                    <Image
                        style={tailwind`h-[70px] w-[50px] absolute right-[40px] bottom-0`}
                        source={require("../../../../../../assets/girl_namaste.png")}
                    />
                    <View style={tailwind`flex flex-col items-start`}>
                        <Text style={tailwind`font-bold text-[16px] text-center items-center w-full`}>
                            Next Lesson
                        </Text>
                        <Text style={tailwind`mt-8 pt-4`}>
                            {module.levels[0].units[current_unit_index + 1].name}
                        </Text>
                    </View>
                </Pressable>}

                {
                    is_course_finished && current_unit_index + 1 == module.levels[0].units.length && <View style={[tailwind`px-4 mt-4`, {
                        width: (wd - 40)
                    }]}>
                        <Pressable
                            style={[tailwind`bg-stone-900`, styles.buttonStyle]}
                            onPress={() => redirectCourseCompleted()}
                        >
                            <Text style={[styles.buttonText, {
                                color: "#FFFFFF"
                            }]}> Continue </Text>
                        </Pressable>
                    </View>
                }
            </View>
        </Layout >
    );
}

const styles = StyleSheet.create({
    img: {
        resizeMode: "contain",
        width: 200,
        height: 200,
        // objectFit: "contain"
    },
    loadercontainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
        marginTop: 50,
        marginBottom: "50%",
    },
    activityIndicator: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
    },
    buttonStyle: {
        // borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 18,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginVertical: 30,
    },
    buttonText: {
        fontWeight: "bold",
        // color: "#fff",
    },

    card_sec1: {
        width: "50%",
        // backgroundColor: "red"
        justifyContent: "center",
        alignItems: "center",
    },
    card_sec2: {
        width: "50%",
        justifyContent: "center",
        // alignItems: "center",
        // backgroundColor: "blue"
    },
    verticleLine: {
        height: '100%',
        width: 1,
        backgroundColor: '#909090',
    }
});
