import React, { useState, useEffect } from "react";
import Text from "../../../../components/Text";
import Layout from "../../layout";
import ProfileHeader from "../../headers/profile.header";
import {
	Image,
	View,
	StyleSheet,
	TouchableOpacity,
	Pressable,
	ActivityIndicator,
} from "react-native";
import tailwind from "twrnc";
import { AntDesign } from "@expo/vector-icons";
import useUser from "../../../../context/user";
import { API } from "../../../../api/instance";
import { useNavigation } from "@react-navigation/native";

const Profile = () => {
	const { navigate } = useNavigation();
	let { user, logout } = useUser();
	const [loading, setLoading] = useState(true);
	const [user_data, setUserData] = useState(null);

	useEffect(() => {
		setLoading(true);
		if (user) {
			getUser();
		}
	}, []);

	async function getUser() {
		let res = await API.get(`/user/${user._id}`);
		// let dt = res.data.data
		// console.log(dt)
		setUserData(res.data.data);
		setLoading(false);
	}

	const handleLinkedTeacher = async () => {
		navigate("LinkTeacher");
	};
	let editProfile = () => {
		navigate("UserProfile");
	};
	return (
		<>
			<Layout HeaderContent={<ProfileHeader />}>
				{!loading && user_data &&
					<View style={tailwind` my-4 h-full w-full flex justify-center`}>
						<View style={tailwind` w-full flex flex-row justify-center`}>
							<Image
								source={require("../../../../../assets/img_default_user.png")}
								style={tailwind`w-[100px] h-[100px]`}
							/>
						</View>

						<View style={tailwind` my-2 w-full flex flex-row justify-center`}>
							<Text style={tailwind` text-gray-400 text-2xl`}>
								{user_data.name}
							</Text>
						</View>

						{/* <View style={tailwind` my-2 w-full flex flex-row justify-center`}>
							<Text style={tailwind` text-gray-400 text-2xl`}>{user_data.name}</Text>
						</View> */}

						<View style={tailwind` my-2 w-full flex flex-row justify-center`}>
							<Text style={tailwind` text-gray-400 text-xl`}>{user.email}</Text>
						</View>

						<TouchableOpacity
							onPress={editProfile} style={tailwind` my-2 w-full flex flex-row justify-center`}>
							<Text style={tailwind` underline text-blue-600 text-lg`}>Edit Profile</Text>
						</TouchableOpacity>

						<TouchableOpacity
							onPress={handleLinkedTeacher}
							style={tailwind`ml-5 my-1 w-full flex flex-row `}
						>
							<AntDesign
								name="setting"
								size={26}
								color="#2096F3"
								style={tailwind`mr-5`}
							/>
							<Text style={tailwind`ml-5 text-gray-500 text-lg`}>
								Link Teacher Account
							</Text>
						</TouchableOpacity>

						<View style={styles.lineStyle} />

						{/* <TouchableOpacity
							onPress={handleLinkedTeacher} style={tailwind`ml-5 my-1 w-full flex flex-row `}>
							<AntDesign name="setting" size={26} color="#2096F3" style={tailwind`mr-5`} />
							<Text style={tailwind`ml-5 text-gray-500 text-lg`}>Link Teacher Account</Text>
						</TouchableOpacity> */}

						{/* <View style={styles.lineStyle} /> */}

						<View style={tailwind`ml-5 my-1 w-full flex flex-row`}>
							<AntDesign name="poweroff" size={20} color="#f43f5e" style={tailwind`mr-5`} />
							<Pressable onPress={logout} >
								<Text style={tailwind`ml-5 text-gray-500 text-lg`}>Logout</Text>
							</Pressable>
						</View>

					</View>
				}

				{loading && < View style={[tailwind` my-4 h-full w-full `, styles.loadercontainer]}>
					<ActivityIndicator size="large" color="##60a5fa" style={styles.activityIndicator} />
				</View>}

			</Layout >
		</>
	);
};
const styles = StyleSheet.create({
	lineStyle: {
		borderWidth: 0.2,
		borderColor: "#9ca3af",
		margin: 10,
	},
	loadercontainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginTop: "50%",
		marginBottom: "50%",
	},
	activityIndicator: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
});

export default Profile;
