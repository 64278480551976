import React, { useState } from "react";
import {
  ActivityIndicator,
  Image,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";
import _ from "lodash";
import { TEACHERS } from "../../../../../api/instance";
import useUser from "../../../../../context/user";
import useClassesFilter from "./context";

const TeacherInput = () => {
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const [searchResults, setSearchResults] = useState([]);

  const { teacherSelected, setTeacherSelected } = useClassesFilter();

  const { user } = useUser();

  const handleSearch = async () => {
    if (searchText === "") return;
    try {
      setLoading(true);
      const { data } = await TEACHERS.get(
        `/users/${user.phone}/teachers?type=WEBINAR&search=${searchText}`
      );
      setSearchResults(data.data);
      setLoading(false);
    } catch (error) {
      console.log(JSON.stringify(error));
      setLoading(false);
    }
  };
  const debouncedSearch = _.debounce(handleSearch, 1000);

  return (
    <View style={tailwind`mt-3`}>
      <View style={tailwind`flex items-center flex-row justify-between`}>
        <Text style={tailwind`text-[#878787] font-semibold text-[16px]`}>
          Teacher
        </Text>
        <TouchableOpacity
          onPress={() => {
            setSearchText("");
            setTeacherSelected(null);
            setSearchResults([]);
          }}
          style={tailwind`text-[#878787] font-semibold text-[12px]`}
        >
          <Text>Clear</Text>
        </TouchableOpacity>
      </View>
      <View style={tailwind`relative`}>
        {teacherSelected ? (
          <View
            style={tailwind`flex items-center flex-row  border border-[#9E9E9E] rounded px-2 py-2`}
          >
            <Image
              source={{ uri: teacherSelected.profile.avatar }}
              style={tailwind`w-[30px] h-[30px] mr-3`}
            />
            <Text>{teacherSelected.name}</Text>
          </View>
        ) : (
          <TextInput
            onChangeText={(text) => {
              setSearchText(text);
              debouncedSearch(); // Debounced search function
            }}
            placeholder="Teacher Name"
            style={tailwind`w-full border border-[#9E9E9E] rounded px-2 py-2`}
          />
        )}

        {!teacherSelected && (
          <View style={tailwind`max-h-[500px] bg-[#fafafa] w-full`}>
            {loading ? (
              <View style={tailwind`py-5`}>
                <ActivityIndicator />
              </View>
            ) : (
              <>
                {searchResults.map((item, i) => (
                  <TouchableOpacity
                    onPress={() => setTeacherSelected(item)}
                    style={tailwind`px-5 py-3 bg-[#ECECEC]`}
                    key={i}
                  >
                    <Text>{item.name}</Text>
                  </TouchableOpacity>
                ))}
              </>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default TeacherInput;
