import React, { useState } from "react";
import {
  ActivityIndicator,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../../components/Text";
import useChat from "../context";
import { TEACHERS } from "../../../../../../api/instance";
import useUser from "../../../../../../context/user";
import Alert from "../../../../../../utils/alert";

const SubmitReport = ({ chat }) => {
  const { messageReportIds, setReport } = useChat();
  const { isTeacher } = useUser();
  const [loading, setLoading] = useState(false);

  const [feedback, setFeedback] = useState("");

  const submitReport = async () => {
    if (!messageReportIds[0]) {
      return Alert.alert("Please select a message");
    }
    setLoading(true);
    try {
      await TEACHERS.post(`/conversation/${chat.id}/report`, {
        flagged_by: isTeacher ? "TEACHER" : "STUDENT",
        message_ids: messageReportIds,
        report_issue: feedback,
      });
      setLoading(false);
      setReport(false);
    } catch (error) {
      setLoading(false);
      Alert.alert("An error occured");
    }
  };

  return (
    <View style={tailwind`flex flex-row justify-between w-full items-center`}>
      <TextInput
        placeholder="Enter Feedback here"
        multiline
        value={feedback}
        onChangeText={(text) => setFeedback(text)}
        style={tailwind`w-full max-w-[200px] outline-none`}
      />

      <View style={tailwind`flex flex-row`}>
        <TouchableOpacity
          disabled={loading}
          onPress={() => setReport(false)}
          style={tailwind`bg-[#ffffff] border border-blue-500 mt-2 px-2 py-2 rounded`}
        >
          <Text style={tailwind`text-blue-500 text-center font-bold`}>
            Cancel
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={submitReport}
          disabled={loading}
          style={tailwind` mt-5 py-2 mt-2 px-2 ml-1 rounded ${
            loading ? "bg-[#c6c6c6]" : "bg-blue-500"
          }`}
        >
          {loading ? (
            <ActivityIndicator color={"#ffff"} />
          ) : (
            <Text style={tailwind`text-white text-center font-bold`}>
              Submit
            </Text>
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SubmitReport;
