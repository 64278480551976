import React, { useState } from "react";
import useChat from "../context";
import Text from "../../../../../../components/Text";
import Modal from "../../../../../../components/modal";
import tailwind from "twrnc";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  ActivityIndicator,
  Platform,
  TouchableOpacity,
  View,
} from "react-native";
import useUser from "../../../../../../context/user";
import { TEACHERS } from "../../../../../../api/instance";
import Alert from "../../../../../../utils/alert";

const UploadFileModal = ({ chat }) => {
  const { fileSelected, setFileSelected } = useChat();

  const { user, isTeacher } = useUser();

  const [loading, setLoading] = useState(false);

  const uploadFile = async () => {
    setLoading(true);

    if (fileSelected && fileSelected.assets && fileSelected.assets[0]) {
      const { uri, name, mimeType } = fileSelected.assets[0];

      const formData = new FormData();

      formData.append("message_type", mimeType);
      formData.append("sender", isTeacher ? chat.teacher_name : chat.user_name);
      formData.append("sender_id", user._id);
      formData.append("sender_type", isTeacher ? "TEACHER" : "STUDENT");

      if (Platform.OS === "web") {
        // Fetch the file from the URI for web
        try {
          const response = await fetch(uri);
          const blob = await response.blob();
          formData.append("file", blob, name);
        } catch (error) {
          console.error("Error fetching file:", error);
          setLoading(false);
          Alert.alert("File Error", "Error fetching the file for upload.");
          return;
        }
      } else {
        // For non-web platforms, append the file as it is
        formData.append("file", {
          uri,
          name,
          mimeType,
        });
      }

      try {
        await TEACHERS.post(
          `/conversation/${
            chat.id
          }/upload?file_name=${name}&message_type=${mimeType}&sender=${
            isTeacher ? chat.teacher_name : chat.user_name
          }&sender_id=${user._id}&sender_type=${
            isTeacher ? "TEACHER" : "STUDENT"
          }`,
          formData
        );
        setLoading(false);
        setFileSelected(null);
      } catch (error) {
        setLoading(false);
        console.log("upload error", JSON.stringify(error));
        Alert.alert("Upload failed", "The file upload failed.");
      }
    }
  };

  return (
    <>
      <Modal maxHeight={220} visible={fileSelected ? true : false}>
        {fileSelected && (
          <>
            <Text style={tailwind`font-bold`}>Upload File</Text>

            <View
              style={tailwind`mt-5 flex flex-row items-start max-w-[180px]`}
            >
              <MaterialCommunityIcons
                name="file-document-outline"
                size={34}
                color="#4A4A4A"
                style={tailwind.style("mr-2")}
              />

              <Text>File Name: {fileSelected.assets[0].name}</Text>
            </View>

            <View>
              <TouchableOpacity
                onPress={uploadFile}
                disabled={loading}
                style={tailwind` mt-5 py-2 rounded ${
                  loading ? "bg-[#c6c6c6]" : "bg-blue-500"
                }`}
              >
                {loading ? (
                  <ActivityIndicator color={"#ffff"} />
                ) : (
                  <Text style={tailwind`text-white text-center font-bold`}>
                    Send File
                  </Text>
                )}
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => setFileSelected(null)}
                disabled={loading}
                style={tailwind`bg-[#ffffff] border border-blue-500 mt-2 py-2 rounded`}
              >
                <Text style={tailwind`text-blue-500 text-center font-bold`}>
                  Cancel
                </Text>
              </TouchableOpacity>
            </View>
          </>
        )}
      </Modal>
    </>
  );
};

export default UploadFileModal;
