import { createContext, useContext, useState } from "react";

const QuestionContext = createContext();

const useQuestion = () => useContext(QuestionContext);

export const QuestionProvider = ({ children }) => {
    const [course_modules, setCourseModules] = useState([]);
    const [current_module_index, setCurrentModuleIndex] = useState("");
    const [module, setModule] = useState(null);
    const [level, setLevel] = useState(null);
    const [current_level_index, setCurrentLevelIndex] = useState(0);
    const [unit, setUnit] = useState([]);
    const [lesson, setLesson] = useState(null);
    const [current_unit_index, setCurrentUnitIndex] = useState("");
    const [current_lesson_index, setCurrentLessonIndex] = useState("");
    const [selected_course, setSelectedCourse] = useState(null);
    const [selected_answer, setSelectedAnswer] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [question_ids, setQuestionIds] = useState([]);
    const [current_question_index, setCurrentQuestionIndex] = useState("");
    const [answer_result, setAnswerResult] = useState("");
    const [question, setQuestion] = useState(null);
    const [checked, setChecked] = useState(null);
    const [lesson_start_timestamp, setLessonStartTimestamp] = useState(0);
    const [lesson_end_timestamp, setLessonEndTimestamp] = useState(0);
    const [total_correct_answer, setTotalCorrectAnswer] = useState(0);
    const [total_incorrect_answer, setTotalIncorrectAnswer] = useState(0);
    const [total_question_attempt, setTotalQuestionAttempt] = useState(0);
    const [question_activity, setQuestionActivity] = useState([]);

    const [question_start_timestamp, setQuestionStartTimestamp] = useState(0);
    const [question_end_timestamp, setQuestionEndTimestamp] = useState(0);
    const [total_question, setTotalQuestion] = useState(0);
    let [module_states, setModuleStates] = useState(null);
    let [all_module_states, setAllModuleStates] = useState([]);

    return (
        <QuestionContext.Provider
            value={{
                all_module_states,
                setAllModuleStates,
                module_states,
                setModuleStates,
                selected_course,
                setSelectedCourse,
                module,
                setModule,
                level,
                setLevel,
                current_level_index,
                setCurrentLevelIndex,
                unit,
                setUnit,
                current_unit_index,
                setCurrentUnitIndex,
                setLesson,
                lesson,
                current_lesson_index,
                setCurrentLessonIndex,
                selected_answer,
                setSelectedAnswer,
                questions,
                setQuestions,
                question_ids,
                setQuestionIds,
                current_question_index,
                setCurrentQuestionIndex,
                answer_result,
                setAnswerResult,
                question,
                setQuestion,
                checked,
                setChecked,
                lesson_start_timestamp,
                setLessonStartTimestamp,
                lesson_end_timestamp,
                setLessonEndTimestamp,
                total_correct_answer,
                setTotalCorrectAnswer,
                total_incorrect_answer,
                setTotalIncorrectAnswer,
                total_question_attempt,
                setTotalQuestionAttempt,
                question_activity,
                setQuestionActivity,
                question_start_timestamp,
                setQuestionStartTimestamp,
                question_end_timestamp,
                setQuestionEndTimestamp,
                total_question,
                setTotalQuestion
            }}
        >
            {children}
        </QuestionContext.Provider>
    );
};

export default useQuestion;
