import Modal from "../../../../../components/modal";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";
import { View, TouchableOpacity, TextInput, StyleSheet, FlatList } from "react-native";
import useClassesFilter from "./context";
import { height, isWeb } from "../../../../../constants/constants";
import { AntDesign } from "@expo/vector-icons";
import moment from "moment";
import { TEACHERS } from "../../../../../api/instance";
import { useState, useEffect } from "react";
import { SelectList } from "react-native-dropdown-select-list";
import useUser from "../../../../../context/user";
import Alert from "../../../../../utils/alert";

const UpdateStatusModal = ({ modalOpen, setModalOpen, call, getCalls }) => {
    let { user } = useUser();

    let class_type = ""
    let topic = ""
    if (call.webinar_id) {
        class_type = "WEBINAR"
        topic = call.topic
    }

    const [users, setUsers] = useState((class_type == "WEBINAR") ?
        call.users.map((item) => ({ ...item, checked: false })) : []);

    if (call.session_id) {
        class_type = "SESSION"
        topic = call.topic_name
    }

    const [topics, setTopics] = useState([]);
    const [call_statuses, setCallStatuses] = useState([
        {
            key: "Complete",
            value: "complete"
        }
    ]);
    const [selected_status, setSelectedStatus] = useState(null);
    const [student_attended, setStudentAttended] = useState(0);
    const [selected_topic, setSelectedTopic] = useState(null);
    const [topic_name, setTopicName] = useState("");
    const [topic_change_reasons, setTopicChangeReasons] = useState(
        [
            {
                key: 1,
                value: "On student request"
            },
            {
                key: 2,
                value: "Last topic was not competed"
            }
            ,
            {
                key: 3,
                value: "Current topic was already completed in last class"
            },
            {
                key: 4,
                value: "Not able to open the assegned topic"
            },
            {
                key: 5,
                value: "Wrong notes were assigned"
            },
            {
                key: 6,
                value: "Others"
            }
        ]
    );
    const [selected_topic_change, setSelectedTopicChange] = useState(null);
    const [topic_change_reason_text, setTopicChangeReasonText] = useState("");
    const [remarks, setRemarks] = useState("");
    const [isChecked, setChecked] = useState(true);

    const [submit_loading, setSubmitLoading] = useState(false);

    useEffect(() => {
        if (call.slot > moment().subtract(24, "hours").format('x') && modalOpen == true) {
            getTopics()
        }
    }, [modalOpen]);

    async function getTopics() {
        let params = {
            class_type: class_type,
            class_id: (class_type == "WEBINAR") ? call.webinar_id : call.session_id
        }

        await TEACHERS.post(`/classes/topics`, params)
            .then((response) => {
                // console.log(response.data.data)
                let res = response.data.data

                if (class_type == "WEBINAR") {
                    res.push({
                        "topic": "Others"
                    })
                }

                if (class_type == "SESSION") {
                    res.push({
                        "topic_name": "Others"
                    })
                }

                if (res.length > 0) {
                    let dropdownData = res.map((item, index) => ({
                        key: index.toString(),
                        value: (class_type == "WEBINAR") ? item.topic : item.topic_name,
                    }));
                    setTopics(dropdownData)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleModalClose = async () => {
        setModalOpen(false)
    }

    const toggleCheckbox = (id) => {
        setUsers((prevList) =>
            prevList.map((item) =>
                item.id === id ? { ...item, checked: !item.checked } : item
            )
        );
    };

    const toggleSelectAll = () => {
        const allChecked = users.every((item) => item.checked);
        setUsers((prevList) =>
            prevList.map((item) => ({ ...item, checked: !allChecked }))
        );
    };

    const allChecked = users.every((item) => item.checked);

    const submit = async () => {
        let now = moment().format('x')
        let update_time;

        if (selected_status == "") {
            Alert.alert("Please select the status.");
            return false;
        }

        if (class_type == "WEBINAR") {
            update_time = +moment(call.slot, 'x').add('m', +call.webinar.duration).format('x')
        } else {
            update_time = +moment(call.slot, 'x').add('m', 30).format('x')
        }

        if (+update_time > +now) {
            Alert.alert("Cannot update class in advance.");
            return false;
        }

        if (!selected_topic || selected_topic == '') {
            Alert.alert("Select topic name.");
            return false;
        }

        if (selected_topic && selected_topic != '' && selected_topic != topic && !selected_topic_change) {
            Alert.alert("Select reason for change of topic option.");
            return false;
        }

        let params = {
            class_type: class_type,
            class_id: call.id,
            selected_status: selected_status,
            student_attended: student_attended,
            selected_topic: selected_topic,
            topic_covered_name: (topic_name) ? topic_name : "",
            selected_topic_change: (selected_topic_change) ? selected_topic_change : "",
            topic_change_reason_text: (topic_change_reason_text) ? topic_change_reason_text : "",
            remarks: (remarks) ? remarks : "",
            app_user_id: user._id,
            topic_name: topic
        }

        if (class_type == "WEBINAR") {
            let ids = []
            for (let index = 0; index < users.length; index++) {
                let item = users[index]
                if (item.checked) {
                    ids.push(item.id)
                }
            }
            params.attended_users = ids
        }

        console.log("params : ", params)
        setSubmitLoading(true)
        await TEACHERS.post(`/webapp/status/update`, params)
            .then((response) => {
                setSubmitLoading(false)
                getCalls()
                setModalOpen(false)
                Alert.success("Status Updated Successfully");
                return false
            })
            .catch((error) => {
                console.log(error);
                setSubmitLoading(false)
                Alert.alert("Something Went Wrong.");
                return false

            });

    };

    return (
        <Modal visible={modalOpen} setVisible={setModalOpen} maxHeight={420}>
            <View>
                <View style={tailwind`flex flex-row justify-between items-center`}>
                    {
                        <Text style={tailwind`font-bold text-[16px]`}>Update Status</Text>
                    }
                    <AntDesign
                        onPress={() => handleModalClose()}
                        name="close"
                        size={24}
                        color="black"
                    />
                </View>

                {
                    call.slot < moment().subtract(24, "hours").format('x') &&
                    < View style={tailwind`items-center`}>
                        <Text style={tailwind`p-2 text-justify`}>
                            Sorry, the window to update the class status has expired. Please write an email to support@multibhashi.com for updating class status with subject line "not able to update dashboard" with below details:
                            Trainee/webinar name, Time, Date, Status, In case of reschedule reason of reschedule, In case of complete , Topic covered, Number of student joined ( in case of webinar)
                        </Text>
                    </View>
                }

                {call.slot > moment().subtract(24, "hours").format('x') && <>

                    <View style={styles.mainContainer}>
                        <View style={styles.inputContainer}>
                            <Text style={styles.labels}>Select Status </Text>
                            <SelectList
                                setSelected={(val) => setSelectedStatus(val)}
                                data={call_statuses}
                                save="value"
                                boxStyles={tailwind`rounded py-2 px-2`}
                                value={selected_status}
                            />
                        </View>

                        {class_type == "WEBINAR" && selected_status == "complete" &&
                            <View style={styles.inputContainer}>
                                <Text style={styles.labels}>No. of student attanded </Text>
                                <TextInput
                                    style={styles.inputStyle}
                                    placeholder={"No. of student attanded"}
                                    value={student_attended}
                                    onChangeText={(item) => setStudentAttended(item)}
                                />
                            </View>
                        }

                        {
                            selected_status == "complete" && <View style={styles.inputContainer}>
                                <Text style={styles.labels}>Select Topic </Text>
                                <SelectList
                                    setSelected={(val) => setSelectedTopic(val)}
                                    data={topics}
                                    save="value"
                                    boxStyles={tailwind`rounded py-2 px-2`}
                                    value={selected_topic}
                                />
                            </View>
                        }


                        {
                            selected_status == "complete" && selected_topic == "Others" && <View style={styles.inputContainer}>
                                <Text style={styles.labels}>Enter Topic Name </Text>
                                <TextInput
                                    style={[styles.textarea]}
                                    multiline={true}
                                    numberOfLines={6}
                                    placeholder="Type Topic Name..."
                                    value={topic_name}
                                    onChangeText={setTopicName}
                                />
                            </View>
                        }

                        {
                            selected_status == "complete" && selected_topic && ((class_type == "WEBINAR" && selected_topic != call.topic) || (class_type == "SESSION" && selected_topic != call.topic_name)) && <View style={styles.inputContainer}>
                                <Text style={styles.labels}>Reason for change of topic </Text>
                                <SelectList
                                    setSelected={(val) => setSelectedTopicChange(val)}
                                    data={topic_change_reasons}
                                    save="value"
                                    boxStyles={tailwind`rounded py-2 px-2`}
                                    value={selected_topic_change}
                                />
                            </View>
                        }

                        {
                            selected_status == "complete" && selected_topic_change == "Others" && <View style={styles.inputContainer}>
                                <Text style={styles.labels}>Write reason to change topic</Text>
                                <TextInput
                                    style={[styles.textarea]}
                                    multiline={true}
                                    numberOfLines={6}
                                    placeholder="Write reason to change topic"
                                    value={topic_change_reason_text}
                                    onChangeText={setTopicChangeReasonText}
                                />
                            </View>
                        }

                        {
                            selected_status == "complete" && < View style={styles.inputContainer}>
                                <Text style={styles.labels}>Remarks</Text>
                                <TextInput
                                    style={[styles.textarea]}
                                    multiline={true}
                                    numberOfLines={6}
                                    placeholder="Write some remarks"
                                    value={remarks}
                                    onChangeText={setRemarks}
                                />
                            </View>
                        }

                        {class_type == "WEBINAR" && <View style={[tailwind` my-4 w-full `]}>
                            <View style={[tailwind`flex-row justify-between items-center`]}>
                                <Text style={{ fontSize: 16 }}>Mark Attendance</Text>
                                <TouchableOpacity
                                    style={tailwind`flex-row items-center`}
                                    onPress={toggleSelectAll}
                                >
                                    <View style={[tailwind`w-4 h-4 border rounded`, allChecked && tailwind`bg-gray-800`]} />
                                    <Text style={tailwind`ml-2`}>Select All</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={tailwind`mt-5`}>
                                <FlatList
                                    data={users}
                                    keyExtractor={(item) => item.id.toString()}
                                    numColumns={2}
                                    renderItem={({ item }) => (
                                        <View style={tailwind`flex-row items-center mb-2 w-1/2`}>
                                            <TouchableOpacity
                                                onPress={() => toggleCheckbox(item.id)}
                                                style={tailwind`flex-row items-center`}
                                            >
                                                <View style={[tailwind`w-4 h-4 border rounded`, item.checked && tailwind`bg-gray-800`]} />
                                                <Text style={tailwind`ml-2`}>{item.name}</Text>
                                            </TouchableOpacity>
                                        </View>
                                    )}
                                />
                            </View>
                        </View>
                        }

                        {!submit_loading && <TouchableOpacity
                            style={[
                                styles.buttonStyle,
                                { backgroundColor: selected_status ? "#22c55e" : "#6b7280" },
                            ]}
                            onPress={submit}
                        >
                            <Text style={styles.buttonText}> Update </Text>
                        </TouchableOpacity>}

                        {submit_loading && (
                            <TouchableOpacity
                                style={[styles.buttonStyle, { backgroundColor: "#22c55e" }]}
                                onPress={null}
                            >
                                <Text style={styles.buttonText}> Updating </Text>
                            </TouchableOpacity>
                        )}


                    </View>



                </>

                }

            </View>
        </Modal >
    );
};

export default UpdateStatusModal;

const styles = StyleSheet.create({
    inputContainer: {
        marginTop: 10,
    },
    labels: {
        fontWeight: "bold",
        // fontSize: 15,
        color: "#7d7d7d",
        paddingBottom: 5,
        lineHeight: 25,
    },
    inputStyle: {
        borderRadius: 4,
        borderWidth: 1.4,
        // borderBottomWidth: 1.4,
        borderColor: "rgba(0, 0, 0, 0.3)",
        paddingHorizontal: 10,
        paddingVertical: 6,
    },
    textarea: {
        width: '100%',
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 8,
        padding: 10,
        fontSize: 16,
        textAlignVertical: 'top', // Aligns text to the top of the input field
        backgroundColor: "#EFEFEF"
    },
    buttonStyle: {
        borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 18,
        // display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginVertical: 30,
        marginTop: 10
    },
    buttonText: {
        fontWeight: "bold",
        color: "#fff",
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
    },
    checkbox: {
        height: 12,
        width: 12,
        borderRadius: 4,
        borderWidth: 2,
        borderColor: 'gray',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },
    checked: {
        backgroundColor: 'green',
    },
});
