import React, { useState } from "react";
import Layout from "../../layout";
import Text from "../../../../components/Text";
import ClassesHeader from "../../headers/classes.header";
import OneToOne from "./1to1/1to1";
import { ClassesFilterProvider } from "./common/context";
import Favourites from "./favourites/favourites";
import Subcription from "./subcriptions/subcription";

import Completed from "./completed/completed";
import Scheduled from "./scheduled/scheduled";
import Cancelled from "./cancelled/cancelled";

const Classes = ({ route }) => {
  // const [tabSelected, setTab] = useState("1to1");
  const { params } = route;
  const [tabSelected, setTab] = useState((params && params.tab && params.tab == "scheduled") ? "scheduled" : "completed");

  return (
    <Layout
      HeaderContent={
        <ClassesHeader tabSelected={tabSelected} setTab={setTab} />
      }
      scrollView={false}
    >
      <ClassesFilterProvider>
        {/* {tabSelected === "1to1" && <OneToOne />} */}
        {tabSelected === "completed" && <Completed />}
        {/* {tabSelected === "favourites" && <Favourites />} */}
        {tabSelected === "scheduled" && <Scheduled />}

        {/* {tabSelected === "subscription" && <Subcription />} */}
        {tabSelected === "cancelled" && <Cancelled />}

      </ClassesFilterProvider>
    </Layout>
  );
};

export default Classes;
