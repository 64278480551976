import axios from "axios";

export const API = axios.create({
	baseURL: "https://self-app-api.multibhashi.com/api/v1",
	// baseURL: "http://0.0.0.0:8080/api/v1",

	headers: {
		"X-Access-Token":
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjViNzMzYzQwNGFiM2IwMDAxMGQwZjE1MCIsImlhdCI6MTUzNDI3ODk0M30.AFqOZ2QWW5Ga3frWv5mc49TWAfmgAoSr7AMQu8jHgks",
	},
});

export const APIV2 = axios.create({
	baseURL: "https://self-app-api.multibhashi.com/api/v2",
	// baseURL: "http://0.0.0.0:8080/api/v2",
	headers: {
		"X-Access-Token":
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjViNzMzYzQwNGFiM2IwMDAxMGQwZjE1MCIsImlhdCI6MTUzNDI3ODk0M30.AFqOZ2QWW5Ga3frWv5mc49TWAfmgAoSr7AMQu8jHgks",
	},
});

export const TEACHERS = axios.create({
	baseURL: "https://teachers.multibhashi.com/service/api/v1",
	// baseURL: "http://0.0.0.0:8080/api/v2",
	// baseURL: "http://localhost:3333/service/api/v1",
	headers: {
		"X-Access-Token":
			"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjViNzMzYzQwNGFiM2IwMDAxMGQwZjE1MCIsImlhdCI6MTUzNDI3ODk0M30.AFqOZ2QWW5Ga3frWv5mc49TWAfmgAoSr7AMQu8jHgks",
	},
});
