import { AntDesign, Feather } from "@expo/vector-icons";
import React from "react";
import { TouchableOpacity, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../components/Text";
import { useNavigation, useRoute } from "@react-navigation/native";

const Tabbar = ({ tabbarHeight }) => {
  const { name: route } = useRoute();
  const { navigate } = useNavigation();

  const getColor = (path) => (route === path ? "#2096F3" : "#7D7D7D");

  return (
    <View
      style={tailwind.style(
        `w-full h-[${tabbarHeight}px] flex justify-around pt-4 bg-white flex-row px-10`,
        {
          shadowColor: "#000000",
          shadowOffset: {
            width: 0,
            height: 0,
          },
          shadowRadius: 5,
          shadowOpacity: 0.2,
          elevation: 14,
          position: 'fixed',
          bottom: 0
        }
      )}
    >
      <TouchableOpacity
        onPress={() => navigate("Home")}
        style={tailwind.style("flex items-center")}
      >
        <Feather name="home" size={24} color={getColor("Home")} />
        <Text style={tailwind`text-[${getColor("Home")}] text-[12px] mt-1`}>
          Home
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => navigate("Classes")}
        style={tailwind.style("flex items-center")}
      >
        <AntDesign name="profile" size={24} color={getColor("Classes")} />
        <Text style={tailwind`text-[${getColor("Classes")}] text-[12px] mt-1`}>
          Classes
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => navigate("Profile")}
        style={tailwind.style("flex items-center")}
      >
        <AntDesign name="user" size={24} color={getColor("Profile")} />
        <Text style={tailwind`text-[${getColor("Profile")}] text-[12px] mt-1`}>
          Profile
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default Tabbar;
