import {
	View,
	Text,
	TouchableOpacity,
	StyleSheet,
	Image,
	Pressable,
	ScrollView,
	FlatList,
	Platform,
	ActivityIndicator,
	Button,
} from "react-native";
import React, { useState, useEffect } from "react";
import tailwind from "twrnc";
import { height, isWeb, width } from "../../../../../constants/constants";
import { useNavigation, useNavigationParam } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";

import { API } from "../../../../../api/instance";
import Layout from "../../../layout";
import PageHeader from "../../../headers/page.header";
import { RadioButton } from "react-native-paper";
let wd = width <= 500 ? width : 499.99;
import ReactPlayer from "react-player";
import { Video, ResizeMode, Audio } from "expo-av";
import useQuestion from "./common/context";
import moment from "moment";
import useUser from "../../../../../context/user";

const headerHeight = isWeb ? 60 : 100;
const buttonHeight = 400;
const contentHeight = height - headerHeight - buttonHeight;

export default function Questions(props) {
	const { navigate } = useNavigation();
	let { user } = useUser();
	const {
		unit,
		selected_course,
		setSelectedAnswer,
		questions,
		current_question_index,
		setCurrentQuestionIndex,
		setAnswerResult,
		setQuestion,
		checked,
		setChecked,
		setQuestionStartTimestamp,
		setQuestionEndTimestamp,
		setTotalCorrectAnswer,
		setTotalIncorrectAnswer,
		setTotalQuestionAttempt
	} = useQuestion();

	let back_route = props.route?.params?.from_page;
	const video = React.useRef(null);
	let [seq_selected_answers, setSeqSelectedAnswers] = useState([]);
	let [seq_options, setSeqOptions] = useState([]);

	let [url_index, setUrlIndex] = useState(0);

	let [url_items, setUrlItems] = useState([]);
	let [playing, setPlaying] = useState(true);

	let [isPlaying, setIsPlaying] = useState(false);
	const [sound, setSound] = useState(null);
	const [duration, setDuration] = useState(0);
	const [sound1, setSound1] = useState(null);
	const [currentAudio, setCurrentAudio] = useState(null);

	let [mtf_selected_answers, setMtfSelectedAnswers] = useState([]);
	let [mtf_options, setMtfOptions] = useState([]);
	let [start_timestamp, setStartTimestamp] = useState();

	let [glc_exmple, setGlcExmple] = useState(false);

	useEffect(() => {
		setQuestionStartTimestamp(moment().format('x'))
		if (questions[current_question_index]?.type_code == "CVC") {
			setUrlItems([questions[current_question_index].items[url_index].audio])
			setUrlIndex(0)
			if (Platform.OS == "web") {
				setPlaying(true);
			}
		} else if (questions[current_question_index]?.type_code == "LC") {
			setUrlItems([questions[current_question_index].dialogs[url_index].audio])
			setUrlIndex(0)
			if (Platform.OS == "web") {
				setPlaying(true);
			}

		} else setUrlItems([]);

		if (questions[current_question_index]?.type_code == "MCQ") {
			if (questions[current_question_index].content[0]?.audio) {
				if (Platform.OS != "web") {
					let duration_millis = null
					const loadSound = async () => {
						if (sound) {
							await sound.unloadAsync();
						}

						const { sound: newSound } = await Audio.Sound.createAsync(
							{ uri: questions[current_question_index].content[0]?.audio },
							{ shouldPlay: true }
						);
						setSound(newSound);
					};

					setCurrentAudio(questions[current_question_index].content[0]?.audio)
					loadSound();
				}

			}
		}

		if (questions[current_question_index].type_code == "SEQ") {
			setSeqSelectedAnswers([])
			let seq_arr = [];
			seq_arr = [...questions[current_question_index].options];
			setSeqOptions(seq_arr);
		}

		if (questions[current_question_index].type_code == "MTF") {
			setMtfSelectedAnswers([])
			let mtf_arr = [];
			mtf_arr = [...questions[current_question_index].options];
			setMtfOptions(mtf_arr);
		}
	}, [current_question_index]);

	useEffect(() => {
		if (questions[current_question_index].type_code == "CVC") {
			if (Platform.OS == "web") {
				setPlaying(true);
			}

			if (Platform.OS != "web") {
				const loadSound = async () => {
					if (sound) {
						await sound.unloadAsync();
					}

					const { sound: newSound } = await Audio.Sound.createAsync(
						{ uri: questions[current_question_index].items[url_index].audio },
						{ shouldPlay: true }
					);
					setSound(newSound);
					const { durationMillis } = await newSound.getStatusAsync();
					setDuration(durationMillis);
				};

				let single_click_audio = null;
				if (sound1) single_click_audio = sound1.getStatusAsync();

				if (!single_click_audio?.isPlaying) {
					if (url_index < questions[current_question_index].items.length) {
						setCurrentAudio(
							questions[current_question_index].items[url_index].audio
						);
						setTimeout(async () => {
							loadSound();
						}, duration);
					}
				}

				// Clean up
				return () => {
					if (sound) {
						sound.unloadAsync();
					}
				};
			}
		}

		if (questions[current_question_index].type_code == "LC") {
			if (Platform.OS == "web") {
				setPlaying(true);
			}

			if (Platform.OS != "web") {
				const loadSound = async () => {
					if (sound) {
						await sound.unloadAsync();
					}

					const { sound: newSound } = await Audio.Sound.createAsync(
						{ uri: questions[current_question_index].dialogs[url_index].audio },
						{ shouldPlay: true }
					);
					setSound(newSound);
					const { durationMillis } = await newSound.getStatusAsync();
					setDuration(durationMillis);
				};

				let single_click_audio = null;
				if (sound1) single_click_audio = sound1.getStatusAsync();

				if (!single_click_audio?.isPlaying) {
					if (url_index < questions[current_question_index].dialogs.length) {
						setCurrentAudio(
							questions[current_question_index].dialogs[url_index].audio
						);
						// setTimeout(async () => {
						loadSound();
						// }, duration);
					}
				}

				// Clean up
				return () => {
					if (sound) {
						sound.unloadAsync();
					}
				};
			}
		}
	}, [url_index]);

	useEffect(() => {
		if (questions[current_question_index].type_code == "CVC") {
			const playbackStatusSubscription = sound
				? sound.setOnPlaybackStatusUpdate((playbackStatus) => {
					if (playbackStatus.didJustFinish) {
						if (
							url_index <
							questions[current_question_index].items.length - 1
						) {
							let indx = +url_index + 1;
							let arr = [];
							arr = url_items;
							arr.push(questions[current_question_index].items[indx].audio);
							setUrlItems([...arr]);
							setUrlIndex(+url_index + 1);
						} else {
							setCurrentAudio(null);
						}
					}
				})
				: null;

			return () => {
				if (playbackStatusSubscription) {
					playbackStatusSubscription.remove();
				}
			};
		}
		if (questions[current_question_index].type_code == "LC") {
			const playbackStatusSubscription = sound
				? sound.setOnPlaybackStatusUpdate((playbackStatus) => {
					if (playbackStatus.didJustFinish) {
						if (
							url_index <
							questions[current_question_index].dialogs.length - 1
						) {
							let indx = +url_index + 1;
							let arr = [];
							arr = url_items;
							arr.push(questions[current_question_index].dialogs[indx].audio);
							setUrlItems([...arr]);
							setUrlIndex(+url_index + 1);
						} else {
							setCurrentAudio(null);
						}
					}
				})
				: null;

			return () => {
				if (playbackStatusSubscription) {
					playbackStatusSubscription.remove();
				}
			};
		}

		if (questions[current_question_index].type_code == "MCQ") {
			if (sound) {
				sound.setOnPlaybackStatusUpdate((playbackStatus) => {
					if (playbackStatus.didJustFinish) {
						setCurrentAudio(null);
					}
				})
			}
		}
	}, [sound]);

	let checkAnswer = () => {
		if (
			questions[current_question_index].type_code == "MCQ" ||
			questions[current_question_index].type_code == "TF"
		) {
			let answer = "incorrect";
			if (
				questions[current_question_index].content[0].correct_options[0] ==
				checked.id
			) {
				answer = "correct";
				setTotalCorrectAnswer((prev) => prev + 1);
				setTotalQuestionAttempt((prev) => prev + 1);
			}
			else {
				answer = "incorrect";
				setTotalIncorrectAnswer((prev) => prev + 1);
				setTotalQuestionAttempt((prev) => prev + 1);
			}

			setAnswerResult(answer);
			setSelectedAnswer(checked);
			setQuestion(questions[current_question_index]);
			// setQuestionStartTimestamp(start_timestamp)
			setQuestionEndTimestamp(moment().format('x'))

			let obj = {
				back_route: "Questions",
			};
			navigate("Feedback", obj);
		}

		if (questions[current_question_index].type_code == "SEQ") {
			let answer = "incorrect";

			let correct_options =
				questions[current_question_index].content[0].correct_options;
			let ans_options = [];

			for (let index = 0; index < seq_selected_answers.length; index++) {
				let item = seq_selected_answers[index];
				ans_options.push(item.id);
			}

			let options_str = correct_options.toString();
			let ans_str = ans_options.toString();

			if (options_str == ans_str) {
				answer = "correct";
				setTotalCorrectAnswer((prev) => prev + 1);
				setTotalQuestionAttempt((prev) => prev + 1);
			}
			else {
				answer = "incorrect";
				setTotalIncorrectAnswer((prev) => prev + 1);
				setTotalQuestionAttempt((prev) => prev + 1);
			}

			setAnswerResult(answer);
			setSelectedAnswer(seq_selected_answers);
			setQuestion(questions[current_question_index]);
			setQuestionEndTimestamp(moment().format('x'))

			setUrlIndex(0)
			setUrlItems([])

			let obj = {
				back_route: "Questions",
			};
			navigate("Feedback", obj);
		}

		if (questions[current_question_index].type_code == "MTF") {
			let ans = mtf_selected_answers.map(item => {
				return item.id
			})
			const isAscending = !ans.some((v, i) => v > ans[i + 1]);
			let answer = "incorrect";

			if (isAscending) {
				answer = "correct";
				setTotalCorrectAnswer((prev) => prev + 1);
				setTotalQuestionAttempt((prev) => prev + 1);
			}
			else {
				answer = "incorrect";
				setTotalIncorrectAnswer((prev) => prev + 1);
				setTotalQuestionAttempt((prev) => prev + 1);
			}

			setAnswerResult(answer);
			setSelectedAnswer(mtf_selected_answers);
			setQuestion(questions[current_question_index]);
			setQuestionEndTimestamp(moment().format('x'))

			setUrlIndex(0)
			setUrlItems([])

			let obj = {
				back_route: "Questions",
			};
			navigate("Feedback", obj);
		}

		if (
			questions[current_question_index].type_code == "GLC" && glc_exmple
		) {
			let answer = "incorrect";
			if (
				questions[current_question_index].examples[0].content[0].correct_options[0] ==
				checked.id
			) {
				answer = "correct";
				setTotalCorrectAnswer((prev) => prev + 1);
				setTotalQuestionAttempt((prev) => prev + 1);
			}
			else {
				answer = "incorrect";
				setTotalIncorrectAnswer((prev) => prev + 1);
				setTotalQuestionAttempt((prev) => prev + 1);
			}

			setAnswerResult(answer);
			setSelectedAnswer(checked);
			setQuestion(questions[current_question_index]);
			// setQuestionStartTimestamp(start_timestamp)
			setQuestionEndTimestamp(moment().format('x'))
			setGlcExmple(false)

			let obj = {
				back_route: "Questions",
			};
			navigate("Feedback", obj);
		}

	};

	let handleSEQAns = async (option) => {
		let res = seq_selected_answers.some((o) => o._id == option._id);
		if (!res) {
			let arr = seq_selected_answers;
			arr.push(option);
			setSeqSelectedAnswers([...arr]);

			let arr2 = seq_options;
			arr2.splice(
				arr2.findIndex((a) => a._id == option._id),
				1
			);
			setSeqOptions([...arr2]);
		}
	};

	let handleSEQAnsRemoved = (option) => {
		let res = seq_options.some((o) => o._id == option._id);
		if (!res) {
			let arr = seq_options;
			arr.push(option);
			setSeqOptions([...arr]);

			let arr2 = seq_selected_answers;
			arr2.splice(
				arr2.findIndex((a) => a._id == option._id),
				1
			);
			setSeqSelectedAnswers([...arr2]);
		}
	};

	let onEnded = () => {
		if (Platform.OS == "web") {
			if (isPlaying) {
				setIsPlaying(false);
			}

			if (questions[current_question_index].items.length != url_index + 1) {
				let res = url_items.some(
					(o) =>
						o == questions[current_question_index].items[url_index + 1].audio
				);
				if (!res) {
					let indx = url_index + 1;
					let arr = [];
					arr = url_items;
					arr.push(questions[current_question_index].items[indx].audio);
					setUrlItems([...arr]);
					playing = url_items.length - 1 == indx;
					setUrlIndex(indx);
					setCurrentAudio(questions[current_question_index].items[indx].audio);
				}
			} else {
				setPlaying(false);
				setCurrentAudio(null);
			}
		}
	};

	let playAndroidAudio = async (audioUrl) => {
		if (Platform.OS != "web") {
			try {
				if (sound) {
					const result = await sound.getStatusAsync();
					if (result.isPlaying) await sound.pauseAsync();
				}

				if (sound1) {
					await sound1.unloadAsync();
				}
				const { sound: newSound } = await Audio.Sound.createAsync({
					uri: audioUrl,
				});
				setSound1(newSound);
				setCurrentAudio(audioUrl);

				await newSound.playAsync();

				if (sound1) {
					await newSound.setOnPlaybackStatusUpdate((status) => {
						if ("didJustFinish" in status && status.didJustFinish) {
							if (sound1) {
								setCurrentAudio(null);
								sound1.unloadAsync();
							}
						}
					});
				}
			} catch (error) {
				console.log("Play audio error : ", error);
			}
		}
	};

	let onPlay = (audio) => {
		if (Platform.OS == "web") {
			setCurrentAudio(audio);
		}
	};

	let onEndedLC = () => {
		if (Platform.OS == "web") {
			if (isPlaying) {
				setIsPlaying(false);
			}

			if (questions[current_question_index].dialogs.length != url_index + 1) {
				let res = url_items.some(
					(o) =>
						o == questions[current_question_index].dialogs[url_index + 1].audio
				);
				if (!res) {
					let indx = url_index + 1;
					let arr = [];
					arr = url_items;
					arr.push(questions[current_question_index].dialogs[indx].audio);
					setUrlItems([...arr]);
					playing = url_items.length - 1 == indx;
					setUrlIndex(indx);
					setCurrentAudio(
						questions[current_question_index].dialogs[indx].audio
					);
				}
			} else {
				setPlaying(false);
				setCurrentAudio(null);
			}
		}
	};

	let handleMtfAns = async (option) => {
		let res = mtf_selected_answers.some((o) => o._id == option._id);
		if (!res) {
			let arr = mtf_selected_answers;
			arr.push(option);
			setMtfSelectedAnswers([...arr]);

			let arr2 = mtf_options;
			arr2.splice(
				arr2.findIndex((a) => a._id == option._id),
				1
			);
			setMtfOptions([...arr2]);
		}
	};

	let handleMtfAnsRemoved = (option) => {
		if (option) {
			let res = mtf_options.some((o) => o._id == option._id);
			if (!res) {
				let arr = mtf_options;
				arr.push(option);
				setMtfOptions([...arr]);

				let arr2 = mtf_selected_answers;
				arr2.splice(
					arr2.findIndex((a) => a._id == option._id),
					1
				);
				setMtfSelectedAnswers([...arr2]);
			}
		}
	};

	let answerCVC = (action) => {
		setCurrentQuestionIndex(current_question_index + 1)
		setUrlIndex(0)
	};

	let answerLC = () => {
		setCurrentQuestionIndex(current_question_index + 1)
		setUrlIndex(0)
		setUrlItems([])
	};

	let LCCNext = () => {
		setCurrentQuestionIndex(current_question_index + 1)
		setUrlIndex(0)
		setUrlItems([])
	};

	let GLCNext = () => {
		if (!glc_exmple) {
			setGlcExmple(true)
		}
	}
	return (
		<Layout
			scrollView={false}
			HeaderContent={
				<PageHeader
					backRoute={"Home"}
					pageTitle={unit.name}
					bgColor={"#17AAF5"}
					textColor={"#f7faf8"}
				/>
			}
			tabbarVisible={false}
		>
			{
				<ScrollView
					style={[tailwind`w-full mb-9`]}
					contentContainerStyle={tailwind``}
				>
					<View style={[tailwind``]}>
						<View
							style={[
								tailwind`w-full`,
								{ flexDirection: "row", alignItems: "center" },
							]}
						>
							<View
								style={[{ justifyContent: "space-evenly", marginVertical: 10 }]}
							>
								<Text style={tailwind`text-[12px] text-center pr-1`}>
									{current_question_index + 1} of {questions.length}
								</Text>
							</View>
							<View
								style={[
									tailwind`mt-1 bg-slate-300 rounded-full h-2 bg-blue-200`,
									{ flex: 1, flexDirection: "row" },
								]}
							>
								<View
									style={[
										tailwind`bg-[#17AAF5] h-2 rounded-full`,
										{
											width:
												(((current_question_index + 1) * questions.length) /
													100) *
												100,
										},
									]}
								></View>
							</View>
						</View>

						{/* <Text>
							Qid : {questions[current_question_index]?._id} and cid :{" "}
							{selected_course._id} and QType : {questions[current_question_index].type_code}
							User_id : {user._id}
						</Text> */}
					</View>
					{/* Question Type MCQ */}
					{questions.length > 0 &&
						questions[current_question_index].type_code == "MCQ" && (
							<View style={[tailwind`w-full`]}>
								{questions[current_question_index].instruction && (
									<View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
										<Text style={[tailwind`font-semibold`]}>
											{questions[current_question_index].instruction}
										</Text>
									</View>
								)}
								{(questions[current_question_index].content[0].audio ||
									questions[current_question_index].content[0].video) &&
									(Platform.OS === "web" ? (
										<View style={[tailwind``, styles.player]}>
											<ReactPlayer
												url={
													questions[current_question_index].content[0].audio
														? questions[current_question_index].content[0].audio
														: questions[current_question_index].content[0].video
												}
												playing={true}
												controls
												ref={video}
												width={wd - 40}
												height="200"
												style={styles.plr}
												config={{
													file: {
														attributes: {
															preload: "auto",
															controlsList: "nodownload",
														},
													},
												}}
											/>
										</View>
									) : (
										<>
											{
												questions[current_question_index].content[0].video && <Video
													ref={video}
													style={tailwind`w-[${width > 500 ? "500" : width - 50
														}px] h-[200px]`}
													source={{
														uri: "https://storage.googleapis.com/multibhashi-cdn/videos/korean/l2_d6_kor.mp4",
													}}
													useNativeControls
													resizeMode={ResizeMode.CONTAIN}
												/>
											}
											{questions[current_question_index].content[0].audio &&
												<View style={[tailwind``]}>
													<Pressable
														style={[
															tailwind`text-center items-center justify-center`,
														]}
														onPress={() => playAndroidAudio(questions[current_question_index].content[0].audio)}
													>
														{
															currentAudio &&
															<Ionicons name="pause-circle-outline" size={45} color="#14b8a6" />
														}

														{
															!currentAudio && <Ionicons name="play-circle-outline" size={45} color="#14b8a6" />
														}
													</Pressable>
												</View>
											}
										</>
									))}

								{questions[current_question_index].content[0].image && (
									<View
										style={[tailwind`w-full items-center justify-center mt-3`]}
									>
										<Image
											source={
												questions[current_question_index].content[0].image
											}
											style={[tailwind``, styles.question_img]}
										/>
									</View>
								)}

								{(!questions[current_question_index].content[0].audio &&
									!questions[current_question_index].content[0].video) && < View style={[tailwind`py-2 mt-2`]}>
										<Text style={[tailwind``]}>
											{questions[current_question_index].content[0].text}
										</Text>
									</View>
								}

								{questions[current_question_index].options.map((option, i) => (

									<Pressable
										key={i}
										style={[
											tailwind`bg-slate-100 p-1 border border-slate-300  rounded-md my-2 w-full`,
										]}
									>
										<View
											style={[
												tailwind``,
												{ flexDirection: "row", alignItems: "center" },
											]}
										>
											<RadioButton
												value={option}
												status={
													checked && checked.id === option.id
														? "checked"
														: "unchecked"
												}
												onPress={() => setChecked(option)}
											/>
											<Text style={[tailwind`p-1`]}>{option.text}</Text>
										</View>
									</Pressable>

								))}
							</View>
						)}
					{/* Question Type TF */}
					{
						questions.length > 0 &&
						questions[current_question_index].type_code == "TF" && (
							<View style={[tailwind`w-full`]}>
								{questions[current_question_index].instruction && (
									<View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
										<Text style={[tailwind`font-semibold`]}>
											{questions[current_question_index].instruction}
										</Text>
									</View>
								)}
								{(questions[current_question_index].content[0].audio ||
									questions[current_question_index].content[0].video) &&
									(Platform.OS === "web" ? (
										<View style={[tailwind``, styles.player]}>
											<ReactPlayer
												url={
													questions[current_question_index].content[0].audio
														? questions[current_question_index].content[0].audio
														: questions[current_question_index].content[0].video
												}
												controls
												ref={video}
												width={wd - 40}
												height="200"
												style={styles.plr}
											/>
										</View>
									) : (
										<Video
											ref={video}
											style={tailwind`w-[${width > 500 ? "500" : width - 50
												}px] h-[200px]`}
											source={{
												uri: questions[current_question_index].content[0].audio
													? questions[current_question_index].content[0].audio
													: questions[current_question_index].content[0].video,
											}}
											useNativeControls
											resizeMode={ResizeMode.CONTAIN}
											isLooping
										/>
									))}

								{questions[current_question_index].content[0].image && (
									<View
										style={[tailwind`w-full items-center justify-center mt-3`]}
									>
										<Image
											source={
												questions[current_question_index].content[0].image
											}
											style={[tailwind``, styles.question_img]}
										/>
									</View>
								)}

								<View style={[tailwind`py-2 mt-2`]}>
									<Text style={[tailwind``]}>
										{questions[current_question_index].content[0].text}
									</Text>
								</View>

								{questions[current_question_index].options.map((option, i) => (

									<Pressable
										key={i}
										style={[
											tailwind`bg-slate-100 p-1 border border-slate-300  rounded-md my-2 w-full`,
										]}
									>
										<View
											style={[
												tailwind``,
												{ flexDirection: "row", alignItems: "center" },
											]}
										>
											<RadioButton
												value={option}
												status={
													checked && checked.id === option.id
														? "checked"
														: "unchecked"
												}
												onPress={() => setChecked(option)}
											/>
											<Text style={[tailwind`p-1`]}>{option.text}</Text>
										</View>
									</Pressable>

								))}
							</View>
						)}

					{/* Question Type SEQ */}
					{questions.length > 0 &&
						questions[current_question_index].type_code == "SEQ" && (
							<View style={[tailwind`w-full`]}>
								{questions[current_question_index].instruction && (
									<View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
										<Text style={[tailwind`font-semibold`]}>
											{questions[current_question_index].instruction}
										</Text>
									</View>
								)}
								{(questions[current_question_index].content[0].audio ||
									questions[current_question_index].content[0].video) &&
									(Platform.OS === "web" ? (
										<View style={[tailwind``, styles.player]}>
											<ReactPlayer
												url={
													questions[current_question_index].content[0].audio
														? questions[current_question_index].content[0].audio
														: questions[current_question_index].content[0].video
												}
												controls
												ref={video}
												width={wd - 40}
												height="200"
												style={styles.plr}
											/>
										</View>
									) : (
										<Video
											ref={video}
											style={tailwind`w-[${width > 500 ? "500" : width - 50
												}px] h-[200px]`}
											source={{
												uri: questions[current_question_index].content[0].audio
													? questions[current_question_index].content[0].audio
													: questions[current_question_index].content[0].video,
											}}
											useNativeControls
											resizeMode={ResizeMode.CONTAIN}
											isLooping
										/>
									))}

								{questions[current_question_index].content[0].image && (
									<View
										style={[tailwind`w-full items-center justify-center mt-3`]}
									>
										<Image
											source={
												questions[current_question_index].content[0].image
											}
											style={[tailwind``, styles.question_img]}
										/>
									</View>
								)}

								<View style={[tailwind`py-2 mt-2`]}>
									<Text style={[tailwind``]}>
										{questions[current_question_index].content[0].text}
									</Text>
								</View>

								<View
									style={[
										tailwind`w-full`,
										{
											flexDirection: "row",
											alignItems: "center",
											flexWrap: "wrap",
										},
									]}
								>
									{seq_selected_answers.map((optn, i) => (

										<Pressable
											key={optn.text}
											style={[
												tailwind`p-1 border border-slate-300  rounded-md m-2`,
											]}
											onPress={() => handleSEQAnsRemoved(optn)}
										>
											<View
												style={[
													tailwind``,
													{ flexDirection: "row", alignItems: "center" },
												]}
											>
												<Text style={[tailwind`p-1`]}>{optn.text}</Text>
											</View>
										</Pressable>

									))}
								</View>

								<View
									style={[
										tailwind`w-full my-5`,
										{
											height: 1,
											borderWidth: 1,
											borderColor: "#17AAF5",
											borderStyle: "dashed",
										},
									]}
								/>

								<View
									style={[
										tailwind`w-full`,
										{
											flexDirection: "row",
											alignItems: "center",
											flexWrap: "wrap",
										},
									]}
								>
									{seq_options.map((option, i) => (

										<Pressable
											key={i}
											style={[
												tailwind`p-1 border border-slate-300  rounded-md m-2`,
											]}
											onPress={() => handleSEQAns(option)}
										>
											<View
												style={[
													tailwind``,
													{ flexDirection: "row", alignItems: "center" },
												]}
											>
												<Text style={[tailwind`p-1`]}>{option.text}</Text>
											</View>
										</Pressable>

									))}
								</View>
							</View>
						)}

					{/* Question Type CVC */}
					{questions.length > 0 &&
						questions[current_question_index].type_code == "CVC" && (
							<View style={[tailwind`w-full`]}>
								{questions[current_question_index].instruction && (
									<View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
										<Text style={[tailwind`font-semibold`]}>
											{questions[current_question_index].instruction}
										</Text>
									</View>
								)}
								<View
									style={[
										tailwind`w-full`,
										{
											flexDirection: "row",
											alignItems: "center",
											flexWrap: "wrap",
										},
									]}
								>
									{/* <Text>
										Total Items :{" "}
										{questions[current_question_index].items.length} total items
										: {url_items.length}
									</Text> */}
									{questions[current_question_index].items.map(
										(option, i) =>
											option.audio &&
											url_items.some((o) => o == option.audio) && (
												<View
													style={[
														tailwind`bg-slate-100 py-1 border border-slate-300  rounded-md my-2 w-full text-center`,
														{
															backgroundColor:
																option.audio !== currentAudio
																	? "#ffff"
																	: "#ccffee",
														},
													]}
													key={option.audio}
												>
													{Platform.OS === "web" ? (
														<View style={[tailwind``, styles.player]}>
															<View
																style={[
																	tailwind`py-1 text-center items-center justify-center w-full`,
																]}
															>
																<Text>{option.text_target}</Text>
																<Text>{option.text_source}</Text>
															</View>

															<View
																style={[
																	tailwind`w-full`,
																	{
																		height: 1,
																		borderWidth: 1,
																		borderColor: "#9b9ea0",
																	},
																]}
															/>

															<View style={[tailwind`w-full py-2`]}>
																<ReactPlayer
																	url={option.audio}
																	controls
																	ref={video}
																	width={wd - 40}
																	height="200"
																	style={styles.plr}
																	playing={playing}
																	onEnded={onEnded}
																	onPlay={() => onPlay(option.audio)}
																	config={{
																		file: {
																			attributes: {
																				preload: "auto",
																				controlsList: "nodownload",
																			},
																			forceAudio: true,
																		},
																	}}
																/>
															</View>
														</View>
													) : (
														<View style={[tailwind``]}>
															<Pressable
																style={[
																	tailwind`py-1 text-center items-center justify-center w-full`,
																]}
																onPress={() => playAndroidAudio(option.audio)}
															>
																<Text>{option.text_target}</Text>
																<Text>{option.text_source}</Text>
															</Pressable>
														</View>
													)}
												</View>
											)
									)}
								</View>
							</View>
						)}

					{/* Question Type LC */}

					{questions.length > 0 &&
						questions[current_question_index].type_code == "LC" && (
							<View style={[tailwind`w-full`]}>
								{questions[current_question_index].instruction && (
									<View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
										<Text style={[tailwind`font-semibold`]}>
											{questions[current_question_index].instruction}
										</Text>
									</View>
								)}
								<View
									style={[
										tailwind`w-full`,
										{
											flexDirection: "row",
											alignItems: "center",
											flexWrap: "wrap",
										},
									]}
								>
									{questions[current_question_index].dialogs.map(
										(option, i) =>
											option.audio &&
											url_items.some((o) => o == option.audio) && (
												<View
													style={[
														tailwind`w-full`,
														styles.container,
														{
															justifyContent:
																questions[current_question_index].dialogs[0]
																	.speaker_name == option.speaker_name
																	? "flex-start"
																	: "flex-end",
														},
													]}
													key={i}
												>
													<View
														style={[
															tailwind`bg-slate-100 my-2 border border-slate-100 rounded-xl`,
															{
																maxWidth: Platform.OS === "web" ? "75%" : "82%",
															},
														]}
														key={option.audio}
													>
														{Platform.OS === "web" ? (
															<View style={[tailwind`rounded-xl`, styles.player, {
																backgroundColor:
																	option.audio !== currentAudio
																		? "rgb(226 232 240)"
																		: "#ccffee",
															}]}>

																{questions[current_question_index].dialogs[0]
																	.speaker_name == option.speaker_name && (
																		<View
																			style={[tailwind`py-1 flex flex-row w-full`]}
																		>

																			<View
																				style={[
																					tailwind`text-center`,
																					styles.card_sec1,
																				]}
																			>
																				<View
																					style={tailwind`text-center items-center justify-center`}
																				>
																					<Image
																						source={{
																							uri: option.speaker_image,
																						}}
																						style={tailwind`w-[30px] h-[30px] rounded `}
																					/>
																				</View>
																				<Text
																					style={tailwind`text-center py-1 text-[10px] text-red-400`}
																				>
																					{option.speaker_name}
																				</Text>
																			</View>

																			<View
																				style={[tailwind`pl-2`, styles.card_sec2]}
																			>
																				<Text style={tailwind`text-[12px]`}>
																					{option.text_target}
																				</Text>
																				<Text style={tailwind`text-[12px]`}>
																					{option.text_source}
																				</Text>
																			</View>
																		</View>
																	)}

																{questions[current_question_index].dialogs[0]
																	.speaker_name != option.speaker_name && (
																		<View
																			style={[tailwind`py-1 flex flex-row w-full`]}
																		>

																			<View
																				style={[tailwind`pl-2 items-center justify-center`, styles.card_sec2]}
																			>
																				<Text style={tailwind`text-[12px]`}>
																					{option.text_target}
																				</Text>
																				<Text style={tailwind`text-[12px]`}>
																					{option.text_source}
																				</Text>
																			</View>
																			<View
																				style={[
																					tailwind`text-center`,
																					styles.card_sec1,
																				]}
																			>
																				<View
																					style={tailwind`text-center items-center justify-center`}
																				>
																					<Image
																						source={{
																							uri: option.speaker_image,
																						}}
																						style={tailwind`w-[30px] h-[30px] rounded `}
																					/>
																				</View>
																				<Text
																					style={tailwind`text-center py-1 text-[10px] text-red-400`}
																				>
																					{option.speaker_name}
																				</Text>
																			</View>
																		</View>
																	)}

																<View
																	style={[
																		tailwind``,
																		{
																			height: 1,
																			borderWidth: 1,
																			borderColor: "#9b9ea0",
																		},
																	]}
																/>

																<View style={[tailwind` py-2`]}>
																	<ReactPlayer
																		url={option.audio}
																		controls
																		ref={video}
																		width={wd - 200}
																		height="200"
																		style={styles.plr}
																		playing={playing}
																		onEnded={onEndedLC}
																		onPlay={() => onPlay(option.audio)}
																		config={{
																			file: {
																				attributes: {
																					preload: "auto",
																					controlsList: "nodownload",
																				},
																				forceAudio: true,
																			},
																		}}
																	/>
																</View>
															</View>
														) : (
															<View style={[tailwind``]}>
																<Pressable
																	style={[
																		tailwind` text-center items-center justify-center w-full `,
																	]}
																	onPress={() => playAndroidAudio(option.audio)}
																>
																	{questions[current_question_index].dialogs[0]
																		.speaker_name == option.speaker_name && (
																			<View
																				style={[
																					tailwind`flex-row w-full rounded-xl`,
																					{
																						backgroundColor:
																							option.audio !== currentAudio
																								? "rgb(226 232 240)"
																								: "#ccffee",
																					},
																				]}
																			>
																				<View
																					style={[tailwind`text-center py-1`]}
																				>
																					<View
																						style={tailwind`text-center items-center justify-center px-1`}
																					>
																						<Image
																							source={{
																								uri: option.speaker_image,
																							}}
																							style={tailwind`w-[40px] h-[40px] `}
																						/>
																					</View>
																					<Text
																						style={tailwind`text-center pt-1 text-[12px] text-red-500 font-semibold`}
																					>
																						{option.speaker_name}
																					</Text>
																				</View>

																				<View
																					style={[tailwind`px-2 justify-center`]}
																				>
																					<Text style={tailwind`text-[12px]`}>
																						{option.text_target}
																					</Text>
																					<Text style={tailwind`text-[12px]`}>
																						{option.text_source}
																					</Text>
																				</View>
																			</View>
																		)}

																	{questions[current_question_index].dialogs[0]
																		.speaker_name != option.speaker_name && (
																			<View
																				style={[
																					tailwind`flex flex-row w-full  rounded-xl`,
																					{
																						backgroundColor:
																							option.audio !== currentAudio
																								? "rgb(226 232 240)"
																								: "#ccffee",
																					},
																				]}
																			>
																				<View
																					style={[tailwind`px-2  justify-center`]}
																				>
																					<Text style={tailwind`text-[12px]`}>
																						{option.text_target}
																					</Text>
																					<Text style={tailwind`text-[12px]`}>
																						{option.text_source}
																					</Text>
																				</View>

																				<View
																					style={[tailwind`text-center py-1`]}
																				>
																					<View
																						style={tailwind`text-center items-center justify-center px-1`}
																					>
																						<Image
																							source={{
																								uri: option.speaker_image,
																							}}
																							style={tailwind`w-[40px] h-[40px] `}
																						/>
																					</View>
																					<Text
																						style={tailwind`text-center pt-1 text-[12px] text-blue-500 font-semibold pt-1`}
																					>
																						{option.speaker_name}
																					</Text>
																				</View>
																			</View>
																		)}

																	<View
																		style={[
																			tailwind``,
																			{
																				height: 1,
																				borderWidth: 1,
																				borderColor: "#9b9ea0",
																			},
																		]}
																	/>
																</Pressable>
															</View>
														)}
													</View>
												</View>
											)
									)}
								</View>
							</View>
						)}

					{/* Question Type MTF */}
					{questions.length > 0 &&
						questions[current_question_index].type_code == "MTF" && (
							<View style={[tailwind`w-full`]}>
								{questions[current_question_index].instruction && (
									<View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
										<Text style={[tailwind`font-semibold`]}>
											{questions[current_question_index].instruction}
										</Text>
									</View>
								)}

								{questions[current_question_index].content[0].image && (
									<View
										style={[tailwind`w-full items-center justify-center mt-3`]}
									>
										<Image
											source={
												questions[current_question_index].content[0].image
											}
											style={[tailwind``, styles.question_img]}
										/>
									</View>
								)}

								<View
									style={[
										tailwind`w-full bg-slate-200`,
										{
											flexDirection: "row",
											alignItems: "center",
											flexWrap: "wrap",
										},
									]}
								>
									{questions[current_question_index].content.map((optn, i) => (
										<View key={i} style={[tailwind`flex flex-row w-full`]}>
											<View style={[tailwind`w-1/2`]} key={i}>
												<View
													key={optn.text}
													style={[
														tailwind`p-1 bg-white border border-white  rounded-md m-2`,
													]}
												>
													<View
														style={[
															tailwind``,
															{ flexDirection: "row", alignItems: "center" },
														]}
													>
														<Text style={[tailwind`p-1`]}>{optn.text}</Text>
													</View>
												</View>
											</View>

											<View style={[tailwind`w-1/2`]}>
												<Pressable
													style={[
														tailwind`p-1   rounded-md m-2`,
														{
															flexDirection: "row",
															alignItems: "center",
															backgroundColor: mtf_selected_answers[i]
																? "rgb(96 165 250)"
																: "#ffff",
														},
													]}
													onPress={() =>
														handleMtfAnsRemoved(mtf_selected_answers[i])
													}
												>
													{mtf_selected_answers[i] && (
														<Text style={[tailwind`p-1 text-white`]}>
															{mtf_selected_answers[i]?.text}
														</Text>
													)}
													{!mtf_selected_answers[i] && (
														<Text style={[tailwind`px-1 py-3`]}></Text>
													)}
												</Pressable>
											</View>
										</View>
									))}
								</View>

								<View
									style={[
										tailwind`w-full bg-slate-200 mt-2`,
										{
											flexDirection: "row",
											alignItems: "center",
											flexWrap: "wrap",
										},
									]}
								>
									<View style={[tailwind``]}>
										{mtf_options.map((optn, i) => (

											<Pressable
												key={optn.text}
												style={[
													tailwind`p-1 bg-white border border-white  rounded-md m-2`,
												]}
												onPress={() => handleMtfAns(optn)}
											>
												<View
													style={[
														tailwind``,
														{ flexDirection: "row", alignItems: "center" },
													]}
												>
													<Text style={[tailwind`p-1`]}>{optn.text}</Text>
												</View>
											</Pressable>

										))}
									</View>
								</View>
							</View>
						)}

					{/* Question Type LCC */}
					{questions.length > 0 &&
						questions[current_question_index].type_code == "LCC" && (
							<View style={[tailwind`w-full`]}>
								{questions[current_question_index].instruction && (
									<View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
										<Text style={[tailwind`font-semibold`]}>
											{questions[current_question_index].instruction}
										</Text>
									</View>
								)}
								{questions[current_question_index].content[0].image && (
									<View
										style={[tailwind`w-full items-center justify-center mt-3 my-2`]}
									>
										<Image
											source={
												questions[current_question_index].content[0].image
											}
											style={[tailwind``, styles.question_img]}
										/>
									</View>
								)}
								{(questions[current_question_index].content[0].audio ||
									questions[current_question_index].content[0].video) &&
									(Platform.OS === "web" ? (
										<View style={[tailwind``, styles.player]}>
											<ReactPlayer
												url={
													questions[current_question_index].content[0].audio
														? questions[current_question_index].content[0].audio
														: questions[current_question_index].content[0].video
												}
												// playing={true}
												controls
												ref={video}
												width={wd - 40}
												height="200"
												style={styles.plr}
												config={{
													file: {
														attributes: {
															preload: "auto",
															controlsList: "nodownload",
														},
													},
												}}
											/>
										</View>
									) : (
										<>
											{
												questions[current_question_index].content[0].video && <Video
													ref={video}
													style={tailwind`w-[${width > 500 ? "500" : width - 50
														}px] h-[200px]`}
													source={{
														uri: "https://storage.googleapis.com/multibhashi-cdn/videos/korean/l2_d6_kor.mp4",
													}}
													useNativeControls
													resizeMode={ResizeMode.CONTAIN}
												/>
											}
											{questions[current_question_index].content[0].audio &&
												<View style={[tailwind``]}>
													<Pressable
														style={[
															tailwind`text-center items-center justify-center`,
														]}
														onPress={() => playAndroidAudio(questions[current_question_index].content[0].audio)}
													>
														{
															currentAudio &&
															<Ionicons name="pause-circle-outline" size={45} color="#14b8a6" />
														}

														{
															!currentAudio && <Ionicons name="play-circle-outline" size={45} color="#14b8a6" />
														}
													</Pressable>
												</View>
											}
										</>
									))}

								{(!questions[current_question_index].content[0].audio &&
									!questions[current_question_index].content[0].video) && < View style={[tailwind`py-2 mt-2`]}>
										<Text style={[tailwind``]}>
											{questions[current_question_index].content[0].text}
										</Text>
									</View>
								}


								<View style={[tailwind`text-center items-center mt-3 mb-4`]}>
									<Text style={[tailwind`text-black font-bold text-[20px] py-2`]}>{questions[current_question_index].content[0].target_text}</Text>
									<Text style={[tailwind`text-slate-500 font-semibold text-[20px] py-2`]}>{questions[current_question_index].content[0].source_text}</Text>
								</View>
							</View>
						)}


					{/* Question Type GLC */}
					{questions.length > 0 &&
						questions[current_question_index].type_code == "GLC" &&
						<>
							{
								!glc_exmple && (
									<View style={[tailwind`w-full`]}>
										{questions[current_question_index].instruction && (
											<View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
												<Text style={[tailwind`font-semibold`]}>
													{questions[current_question_index].instruction}
												</Text>
											</View>
										)}
										{questions[current_question_index].content[0].image && (
											<View
												style={[tailwind`w-full items-center justify-center mt-3 my-2`]}
											>
												<Image
													source={
														questions[current_question_index].content[0].image
													}
													style={[tailwind``, styles.question_img]}
												/>
											</View>
										)}
										{(questions[current_question_index].content[0].audio ||
											questions[current_question_index].content[0].video) &&
											(Platform.OS === "web" ? (
												<View style={[tailwind``, styles.player]}>
													<ReactPlayer
														url={
															questions[current_question_index].content[0].audio
																? questions[current_question_index].content[0].audio
																: questions[current_question_index].content[0].video
														}
														// playing={true}
														controls
														ref={video}
														width={wd - 40}
														height="200"
														style={styles.plr}
														config={{
															file: {
																attributes: {
																	preload: "auto",
																	controlsList: "nodownload",
																},
															},
														}}
													/>
												</View>
											) : (
												<>
													{
														questions[current_question_index].content[0].video && <Video
															ref={video}
															style={tailwind`w-[${width > 500 ? "500" : width - 50
																}px] h-[200px]`}
															source={{
																uri: "https://storage.googleapis.com/multibhashi-cdn/videos/korean/l2_d6_kor.mp4",
															}}
															useNativeControls
															resizeMode={ResizeMode.CONTAIN}
														/>
													}
													{questions[current_question_index].content[0].audio &&
														<View style={[tailwind``]}>
															<Pressable
																style={[
																	tailwind`text-center items-center justify-center`,
																]}
																onPress={() => playAndroidAudio(questions[current_question_index].content[0].audio)}
															>
																{
																	currentAudio &&
																	<Ionicons name="pause-circle-outline" size={45} color="#14b8a6" />
																}

																{
																	!currentAudio && <Ionicons name="play-circle-outline" size={45} color="#14b8a6" />
																}
															</Pressable>
														</View>
													}
												</>
											))}

										< View style={[tailwind`py-2 mt-2`]}>
											<Text style={[tailwind``]}>
												{questions[current_question_index].content[0].text}
											</Text>
										</View>

									</View>

								)
							}

							{
								glc_exmple && <View style={[tailwind`w-full`]} key={moment().format('x')}>
									{
										questions[current_question_index].examples[0].type_code == "MCQ" &&
										<>
											{questions[current_question_index].examples[0].instruction && (
												<View style={[tailwind`w-full bg-slate-200 p-3 mt-2 mb-3`]}>
													<Text style={[tailwind`font-semibold`]}>
														{questions[current_question_index].examples[0].instruction}
													</Text>
												</View>
											)
											}
											{(questions[current_question_index].examples[0].content[0].audio ||
												questions[current_question_index].examples[0].content[0].video) &&
												(Platform.OS === "web" ? (
													<View style={[tailwind``, styles.player]}>
														<ReactPlayer
															url={
																questions[current_question_index].examples[0].content[0].audio
																	? questions[current_question_index].examples[0].content[0].audio
																	: questions[current_question_index].examples[0].content[0].video
															}
															playing={true}
															controls
															ref={video}
															width={wd - 40}
															height="200"
															style={styles.plr}
															config={{
																file: {
																	attributes: {
																		preload: "auto",
																		controlsList: "nodownload",
																	},
																},
															}}
														/>
													</View>
												) : (
													<>
														{
															questions[current_question_index].examples[0].content[0].video && <Video
																ref={video}
																style={tailwind`w-[${width > 500 ? "500" : width - 50
																	}px] h-[200px]`}
																source={{
																	uri: "https://storage.googleapis.com/multibhashi-cdn/videos/korean/l2_d6_kor.mp4",
																}}
																useNativeControls
																resizeMode={ResizeMode.CONTAIN}
															/>
														}
														{questions[current_question_index].examples[0].content[0].audio &&
															<View style={[tailwind``]}>
																<Pressable
																	style={[
																		tailwind`text-center items-center justify-center`,
																	]}
																	onPress={() => playAndroidAudio(questions[current_question_index].examples[0].content[0].audio)}
																>
																	{
																		currentAudio &&
																		<Ionicons name="pause-circle-outline" size={45} color="#14b8a6" />
																	}

																	{
																		!currentAudio && <Ionicons name="play-circle-outline" size={45} color="#14b8a6" />
																	}
																</Pressable>
															</View>
														}
													</>
												))}

											{questions[current_question_index].examples[0].content[0].image && (
												<View
													style={[tailwind`w-full items-center justify-center mt-3`]}
												>
													<Image
														source={
															questions[current_question_index].examples[0].content[0].image
														}
														style={[tailwind``, styles.question_img]}
													/>
												</View>
											)}

											< View style={[tailwind`py-2 mt-2`]}>
												<Text style={[tailwind``]}>
													{questions[current_question_index].examples[0].content[0].text}
												</Text>
											</View>

											{questions[current_question_index].examples[0].options.map((option1, indi) =>
											(
												<Pressable
													key={indi * moment().milliseconds()}
													style={[
														tailwind`bg-slate-100 p-1 border border-slate-300  rounded-md my-2 w-full ${(indi + 1 == questions[current_question_index].examples[0].options.length) ? "mb-8" : ""}`,
													]}
												>
													<View
														style={[
															tailwind``,
															{ flexDirection: "row", alignItems: "center" },
														]}
													>
														<RadioButton
															value={option1}
															status={
																checked && checked.id === option1.id
																	? "checked"
																	: "unchecked"
															}
															onPress={() => setChecked(option1)}
														/>
														<Text style={[tailwind`p-1`]}>{option1.text}</Text>
													</View>
												</Pressable>
											)
											)}


										</>
									}
								</View>
							}
						</>
					}
				</ScrollView>
			}

			<View
				style={[
					tailwind`text-center items-center justify-center`,
					{
						position: "fixed",
						bottom: 0,
						width: wd - 40,
					},
				]}
			>
				{questions[current_question_index].type_code == "SEQ" && (
					<>
						{/* <View>
							<Text>
								questions[current_question_index].options.length :{" "}
								{questions[current_question_index].options.length}
							</Text>

							<Text>
								seq_selected_answers.length : {seq_selected_answers.length}
							</Text>
						</View> */}
						{questions[current_question_index].options.length !=
							seq_selected_answers.length && (
								<Pressable
									style={[
										tailwind`absolute bottom-[20px] w-full`,
										styles.buttonStyle,
										{
											backgroundColor: "#EFEFEF",
										},
									]}
									onPress={() => checkAnswer()}
								>
									<Text
										style={[
											styles.buttonText,
											{
												color: "#4F4F4F",
											},
										]}
									>
										{" "}
										Next
									</Text>
								</Pressable>
							)}

						{questions[current_question_index].options.length ==
							seq_selected_answers.length && (
								<Pressable
									style={[
										tailwind`absolute bottom-[20px] w-full`,
										styles.buttonStyle,
										{
											backgroundColor: "#3EBD3E",
										},
									]}
									onPress={() => checkAnswer()}
								>
									<Text
										style={[
											styles.buttonText,
											{
												color: "#FFFFFF",
											},
										]}
									>
										{" "}
										Next
									</Text>
								</Pressable>
							)}
					</>
				)}

				{(questions[current_question_index].type_code == "MCQ" ||
					questions[current_question_index].type_code == "TF") && (
						<>
							{checked == null && (
								<Pressable
									style={[
										tailwind`absolute bottom-[20px] w-full`,
										styles.buttonStyle,
										{
											backgroundColor: "#EFEFEF",
										},
									]}
									onPress={() => checkAnswer()}
								>
									<Text
										style={[
											styles.buttonText,
											{
												color: "#4F4F4F",
											},
										]}
									>
										Next
									</Text>
								</Pressable>
							)}

							{checked != null && (
								<Pressable
									style={[
										tailwind`absolute bottom-[20px] w-full`,
										styles.buttonStyle,
										{
											backgroundColor: "#3EBD3E",
										},
									]}
									onPress={() => checkAnswer()}
								>
									<Text
										style={[
											styles.buttonText,
											{
												color: "#FFFFFF",
											},
										]}
									>
										Next
									</Text>
								</Pressable>
							)}
						</>
					)}

				{questions[current_question_index].type_code == "CVC" && (
					<>
						{questions[current_question_index].items.length == url_items.length && (

							<Pressable
								style={[
									tailwind`absolute bottom-[20px] bg-stone-900 rounded-xl px-2 py-1`
								]}
								onPress={() => answerCVC("continue")}
							>
								<Text
									style={[
										tailwind`text-white`
									]}
								>
									Continue
								</Text>
							</Pressable>
						)}

						{questions[current_question_index].items.length != url_items.length && (

							<Pressable
								style={[
									tailwind`absolute bottom-[20px] bg-stone-900 rounded-xl px-2 py-1`
								]}
								onPress={() => answerCVC("skip")}
							>
								<Text
									style={[
										tailwind`text-white`
									]}
								>
									Skip
								</Text>
							</Pressable>
						)}


					</>
				)}

				{questions[current_question_index].type_code == "LC" && (
					<>
						{questions[current_question_index].dialogs.length == url_items.length && (
							<Pressable
								style={[
									tailwind`absolute bottom-[20px] bg-stone-900 rounded-xl px-2 py-1`
								]}
								onPress={() => answerLC()}
							>
								<Text
									style={[
										tailwind`text-white`
									]}
								>
									Continue
								</Text>
							</Pressable>
						)}


					</>
				)}

				{questions[current_question_index].type_code == "MTF" && (
					<>

						{mtf_options.length == 0 && (
							<Pressable
								style={[
									tailwind`absolute bottom-[20px] w-full`,
									styles.buttonStyle,
									{
										backgroundColor: "#3EBD3E",
									},
								]}
								onPress={() => checkAnswer()}
							>
								<Text
									style={[
										styles.buttonText,
										{
											color: "#FFFFFF",
										},
									]}
								>
									Next
								</Text>
							</Pressable>
						)}
					</>
				)}

				{questions[current_question_index].type_code == "LCC" && (
					<>
						<Pressable
							style={[
								tailwind`absolute bottom-[20px] w-full`,
								styles.buttonStyle,
								{
									backgroundColor: "#3EBD3E",
								},
							]}
							onPress={() => LCCNext()}
						>
							<Text
								style={[
									styles.buttonText,
									{
										color: "#FFFFFF",
									},
								]}
							>
								Next
							</Text>
						</Pressable>

					</>
				)}

				{questions[current_question_index].type_code == "GLC" && (
					<>
						{!glc_exmple && <Pressable
							style={[
								tailwind`absolute bottom-[20px] w-full mt-2`,
								styles.buttonStyle,
								{
									backgroundColor: "#3EBD3E",
								},
							]}
							onPress={() => GLCNext()}
						>
							<Text
								style={[
									styles.buttonText,
									{
										color: "#FFFFFF",
									},
								]}
							>
								Next
							</Text>
						</Pressable>
						}

						{glc_exmple && <>
							{checked == null && (
								<Pressable
									style={[
										tailwind`absolute bottom-[20px] w-full`,
										styles.buttonStyle,
										{
											backgroundColor: "#EFEFEF",
										},
									]}
									onPress={() => checkAnswer()}
								>
									<Text
										style={[
											styles.buttonText,
											{
												color: "#4F4F4F",
											},
										]}
									>
										Next
									</Text>
								</Pressable>
							)}

							{checked != null && (
								<Pressable
									style={[
										tailwind`absolute bottom-[20px] w-full`,
										styles.buttonStyle,
										{
											backgroundColor: "#3EBD3E",
										},
									]}
									onPress={() => checkAnswer()}
								>
									<Text
										style={[
											styles.buttonText,
											{
												color: "#FFFFFF",
											},
										]}
									>
										Next
									</Text>
								</Pressable>
							)}
						</>
						}

					</>
				)}
			</View>
		</Layout>
	);
}

const styles = StyleSheet.create({
	question_img: {
		resizeMode: "contain",
		width: wd - 40,
		height: 200,
		// objectFit: "contain",
	},
	player: {
		// width: wd - 40,
		height: 200,
		flex: 1,
		flexDirection: "row",
		flexWrap: "wrap",
		backgroundColor: "transparent"
	},
	plr: {
		width: wd - 40,
		height: "300",
		backgroundColor: "transparent"
	},
	loadercontainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		verticalAlign: "middle",
		marginTop: 50,
		marginBottom: "50%",
	},
	activityIndicator: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		verticalAlign: "middle",
	},
	buttonStyle: {
		borderRadius: 5,
		paddingVertical: 10,
		paddingHorizontal: 18,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		// marginVertical: 30,
	},
	buttonText: {
		fontWeight: "bold",
		// color: "#fff",
	},
	card_sec1: {
		width: "20%",
	},
	card_sec2: {
		width: "80%",
	},

	container: {
		flexDirection: "row",
		// justifyContent: 'flex-end',
		// justifyContent: 'flex-center',
		flexWrap: "wrap",
		// alignContent: 'space-around',
		alignItems: "flex-start",
	},
});
