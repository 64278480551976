import React from "react";
import { Pressable, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../components/Text";
import { AntDesign, Ionicons } from "@expo/vector-icons";

const ClassesHeader = ({ tabSelected, setTab }) => {
  return (
    <View style={tailwind`flex flex-row h-full items-center justify-between`}>
      <Pressable
        onPress={() => setTab("completed")}
        style={tailwind`w-1/3 h-[100%] flex flex-row justify-center border-b-2 items-center gap-x-2 ${tabSelected === "completed" ? " border-[#2096F3]" : "border-white"
          }`}
      >
        {/* <Ionicons name="people-outline" size={24} color="black" /> */}
        <Text>Completed</Text>
      </Pressable>

      <Pressable
        onPress={() => setTab("scheduled")}
        style={tailwind`w-1/3 flex flex-row justify-center items-center h-full gap-x-2 border-b-2 ${tabSelected === "scheduled" ? "border-[#2096F3]" : "border-white"
          }`}
      >
        {/* <AntDesign name="staro" size={24} color="black" /> */}
        {/* <Text>Favourites</Text> */}
        <Text>Scheduled</Text>
      </Pressable>

      <Pressable
        onPress={() => setTab("cancelled")}
        style={tailwind`w-1/3 h-full flex flex-row justify-center items-center gap-x-2 border-b-2 ${tabSelected === "cancelled" ? "border-[#2096F3]" : "border-white"
          }`}
      >
        {/* <AntDesign name="calendar" size={24} color="black" /> */}
        {/* <Text>Subscription</Text> */}
        <Text>Rescheduled/Lapsed</Text>
      </Pressable>
    </View>
  );
};

export default ClassesHeader;
