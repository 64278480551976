import { Ionicons } from "@expo/vector-icons";
import { useState } from "react";
import { Pressable, TextInput, View } from "react-native";
import tailwind from "twrnc";

export const PasswordInput = ({ password, setPassword }) => {
  const [passwordHidden, setPasswordHidden] = useState(true);

  return (
    <View
      style={tailwind`w-full border border-[#F2F2F2] rounded py-2 px-3 text-[12px] flex justify-between flex-row`}
    >
      <TextInput
        secureTextEntry={passwordHidden}
        placeholder="Enter password"
        style={tailwind`w-[90%]`}
        value={password}
        onChangeText={(text) => setPassword(text)}
      />

      <Pressable onPress={() => setPasswordHidden(!passwordHidden)}>
        {passwordHidden ? (
          <Ionicons name="eye-off" size={20} color="#8C8C8C" />
        ) : (
          <Ionicons name="eye" size={20} color="#8C8C8C" />
        )}
      </Pressable>
    </View>
  );
};
