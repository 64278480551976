import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import Text from "../../../../components/Text";
import moment from "moment";
import tailwind from "twrnc";

const NotificationItem = ({ notification }) => {
  const [readMore, setReadMore] = useState(false);

  const body =
    notification?.body?.split("\n")?.filter((line) => line.trim() !== "") || [];

  return (
    <View style={tailwind`mb-5 border-b-2 pb-5 border-[#ECEBEB] `}>
      <View style={tailwind`flex items-center justify-between flex-row`}>
        <Text
          style={tailwind`text-[#4F4F4F] font-bold text-[16px] max-w-[250px]`}
        >
          {notification.title}
        </Text>
        <Text style={tailwind`text-[12px]`}>
          {moment.unix(notification.timestamp / 1000).format("hh:mm a MMM D")}
        </Text>
      </View>

      <Text style={tailwind`text-[#4F4F4F] mt-1 text-[12px]`}>{body[0]}</Text>

      {readMore &&
        body.slice(1, body.length).map((x, i) => (
          <Text style={tailwind`text-[#4F4F4F] mt-1 text-[12px]`} key={i}>
            {x}
          </Text>
        ))}

      <TouchableOpacity onPress={() => setReadMore(!readMore)}>
        <Text style={tailwind`text-[#4F4F4F] text-[12px] font-semibold mt-4`}>
          (read {readMore ? "less" : "more"})
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default NotificationItem;
