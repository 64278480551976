import { TouchableOpacity, View } from "react-native";
import Modal from "../../../../../../../components/modal";
import Text from "../../../../../../../components/Text";
import tailwind from "twrnc";
import NotesFileItem from "./notes_file_item";
import { useState } from "react";
import { TEACHERS } from "../../../../../../../api/instance";
import moment from "moment";
import { AntDesign } from "@expo/vector-icons";
import NotesLinkItem from "./notes_link_item";
import Alert from "../../../../../../../utils/alert";

const NotesDialog = ({ open, setOpen, session }) => {
  const [loading, setLoading] = useState(false);
  const [allNotes, setAllNotes] = useState(null);

  const getAllNotes = async () => {
    setLoading(true);

    // const isWebinar = session.type === "WEBINAR";
    let isWebinar = ""
    if (session.webinar_id)
      isWebinar = "WEBINAR"

    try {
      const id = isWebinar ? session.webinar_id : session.session_id;

      const { data } = await TEACHERS.get(
        `/${isWebinar ? "webinars" : "session"}/detail/${id}`
      );
      // console.log(JSON.stringify(data.data[0].calls));

      setAllNotes(isWebinar ? data.data[0].classes : data.data[0].calls);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Alert.alert("An error occured");
    }
  };

  return (
    <Modal visible={open} setVisible={setOpen} maxHeight={500}>
      <View>
        <View style={tailwind`flex flex-row justify-between items-center`}>
          <Text style={tailwind`font-bold text-[16px]`}>Notes</Text>

          <AntDesign
            onPress={() => setOpen(false)}
            name="close"
            size={24}
            color="black"
          />
        </View>

        <TouchableOpacity disabled={loading} onPress={getAllNotes}>
          <Text
            style={tailwind`my-2 text-[#2196F2] font-bold text-[12px] mb-5`}
          >
            View all notes
          </Text>
        </TouchableOpacity>

        {allNotes ? (
          <>
            {allNotes
              .filter((x) => x.noteFiles[0] || x.noteLinks[0])
              .map((call, i) => {
                const date = moment.unix(call.slot / 1000).format("Do MMMM");
                return (
                  <View key={i} style={tailwind``}>
                    <Text style={tailwind`font-bold mb-3`}>{date}</Text>

                    {call.noteFiles.map((note, i) => (
                      <NotesFileItem key={i} note={note} setOpen={setOpen} />
                    ))}

                    {call.noteLinks.map((note, i) => (
                      <NotesLinkItem key={i} note={note} setOpen={setOpen} />
                    ))}
                  </View>
                );
              })}
          </>
        ) : (
          <View>
            {session.noteFiles.map((note, i) => (
              <NotesFileItem key={i} note={note} setOpen={setOpen} />
            ))}

            {session.noteLinks.map((note, i) => (
              <NotesLinkItem key={i} note={note} setOpen={setOpen} />
            ))}
          </View>
        )}

        {loading && <Text>Loading All Notes...</Text>}
      </View>
    </Modal>
  );
};

export default NotesDialog;
