import React from "react";
import { ScrollView, View } from "react-native";
import Text from "../../../../../components/Text";
import useClasses from "../../../../../context/classes";
import tailwind from "twrnc";
import CompeltedClassItem from "./completed_classes_item";
import { useNavigation } from "@react-navigation/native";

const CompletedClasses = () => {
  const { completed } = useClasses();

  const { navigate } = useNavigation();

  return (
    <View style={tailwind`w-full`}>
      {completed && (
        <>
          <View
            style={tailwind`flex flex-row justify-between w-full items-center`}
          >
            <Text style={tailwind`font-bold mt-5 mb-5 text-[18px]`}>
              Completed Classes
            </Text>

            <Text
              onPress={() => navigate("Classes")}
              style={tailwind`text-[#2096F3] font-semibold`}
            >
              View All
            </Text>
          </View>

          <ScrollView
            horizontal
            style={{ maxWidth: "100%", overflow: "scroll" }}
          >
            {completed.map((item, i) => (
              <CompeltedClassItem item={item} key={i} i={i} />
            ))}
          </ScrollView>
        </>
      )}
    </View>
  );
};

export default CompletedClasses;
