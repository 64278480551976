import React from "react";
import Modal from "../../../../../components/modal";
import { Image, TouchableOpacity, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";

const Expired = ({ open, setOpen, goBack }) => {
  const handleClick = () => {
    goBack();
    setOpen(false);
  };

  return (
    <Modal maxHeight={450} visible={open} setVisible={setOpen}>
      <View style={tailwind`flex flex-col items-center`}>
        <Image
          source={require("../../../../../../assets/img_girl_hands_waist.png")}
          style={tailwind`w-[165px] h-[235px]`}
        />

        <Text style={tailwind`mt-3 text-[14px] text-[#898989]`}>Note:</Text>

        <Text style={tailwind`mt-1 text-center text-[12px]`}>
          The chat feature has been disabled for you as you don't have any
          active classes with this Trainer.
        </Text>

        <Text style={tailwind`text-center text-[12px]`}>
          Please contact us at support@multibhashi.com to renew
        </Text>
        <TouchableOpacity
          onPress={handleClick}
          style={tailwind`bg-[#2196F3] px-5 py-2 mt-5 rounded-lg`}
        >
          <Text style={tailwind`text-white font-bold`}>Okay</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

export default Expired;
