import { TouchableOpacity, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";
import { SelectList } from "react-native-dropdown-select-list";
import { timeSlotOptions } from "../../../../../constants/constants";
import useClassesFilter from "./context";

const TimeSlotInput = () => {
  const { slot, setSlot } = useClassesFilter();

  return (
    <View style={tailwind`mt-3`}>
      <View style={tailwind`flex items-center flex-row justify-between`}>
        <Text style={tailwind`text-[#878787] font-semibold text-[16px]`}>
          Time Slot
        </Text>
        <TouchableOpacity
          onPress={() => setSlot("")}
          style={tailwind`text-[#878787] font-semibold text-[12px]`}
        >
          <Text>Clear</Text>
        </TouchableOpacity>
      </View>
      <SelectList
        setSelected={(val) => setSlot(val)}
        data={timeSlotOptions}
        save="value"
        boxStyles={tailwind`rounded py-2 px-2`}
        value={slot}
      />
    </View>
  );
};

export default TimeSlotInput;
