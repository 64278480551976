import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList
} from "react-native";
import React, { useState, useEffect } from "react";
import tailwind from "twrnc";
import { isWeb } from "../../../../constants/constants";
import { useNavigation } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";

import Indian from "./indian/indian";
import Foreign from "./foreign/foreign";

import { API } from "../../../../api/instance";
import Layout from "../../layout";
import ClassesHeader from "../../headers/classes.header";
import PageHeader from "../../headers/page.header";
// import PageHeader from "../../headers/home.header";

export default function Courses() {
    const { navigate } = useNavigation();
    const headerHeight = isWeb ? 60 : 100;
    const [tabSelected, setTab] = useState("indian");

    const headerHeight1 = isWeb ? 50 : 50;
    return (
        <Layout
            HeaderContent={
                <PageHeader backRoute="Home" pageTitle="Learn On Your Own" />
            }
        // scrollView={false}
        >
            <View style={tailwind`flex flex-row w-full h-[${headerHeight1}px] items-center justify-between`}>
                <Pressable
                    onPress={() => setTab("indian")}
                    style={tailwind`w-1/2 h-[100%] flex flex-row justify-center border-b-2 items-center gap-x-2 ${tabSelected === "indian" ? " border-[#2096F3]" : "border-white"
                        }`}
                >
                    <Image
                        source={require("../../../../../assets/indian_flag.png")}
                        style={tailwind`w-[20px] h-[20px]`}
                    />
                    <Text>Indian Languages</Text>
                </Pressable>

                <Pressable
                    onPress={() => setTab("foreign")}
                    style={tailwind`w-1/2 flex flex-row justify-center items-center h-full gap-x-2 border-b-2 ${tabSelected === "foreign" ? "border-[#2096F3]" : "border-white"
                        }`}
                >
                    <Image
                        source={require("../../../../../assets/globe.png")}
                        style={tailwind`w-[20px] h-[20px]`}
                    />
                    <Text>Foreign Languages</Text>
                </Pressable>
            </View>

            <View style={tailwind`w-full`}>

                {
                    tabSelected === "indian" && <Indian />
                }

                {tabSelected === "foreign" && <Foreign />}
            </View>
        </Layout>
    );
}

const styles = StyleSheet.create({
});
