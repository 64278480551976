import React from "react";
import Modal from "../../../../../../components/modal";
import { TouchableOpacity, View } from "react-native";
import Text from "../../../../../../components/Text";
import tailwind from "twrnc";

const CallDialog = ({ open, setOpen, loading }) => {
  return (
    <Modal visible={open} setVisible={setOpen}>
      <View>
        <Text style={tailwind`font-bold text-[16px]`}>
          {loading ? "Proccessing Request" : "Request Successful"}
        </Text>
        {!loading && (
          <>
            <Text style={tailwind`mt-2 text-[12px]`}>
              Your callback request has been placed. You should receive the call
              shortly on your registered mobile number. We appreciate your
              patience.
            </Text>

            <View style={tailwind`flex flex-row justify-end mt-5`}>
              <TouchableOpacity onPress={() => setOpen(false)}>
                <Text style={tailwind`text-[#2196F2] font-bold`}>Okay</Text>
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
    </Modal>
  );
};

export default CallDialog;
