import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	StyleSheet,
	Image,
	ActivityIndicator,
} from "react-native";
import React from "react";
import tailwind from "twrnc";
import { isWeb } from "../../../../../constants/constants";
import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { API } from "../../../../../api/instance";
import useUser from "../../../../../context/user";
import { useState, useEffect } from "react";
import Alert from "../../../../../utils/alert";

export default function UserProfile() {
	const { navigate } = useNavigation();
	const headerHeight = isWeb ? 60 : 100;

	const [loading, setLoading] = useState(true);
	const [submmiting, setSubmmiting] = useState(false);
	let { user } = useUser();
	let [name, setName] = useState("");
	let [email, setEmail] = useState(user.email);
	let [phone, setPhone] = useState(user.phone);
	let [is_name_changed, setIsNameChanged] = useState(false);
	let [user_data, setUserData] = useState(null);

	useEffect(() => {
		setLoading(true);
		if (user) {
			getUser();
		}
	}, []);

	async function getUser() {
		let res = await API.get(`/user/${user._id}`);
		let dt = res.data.data;
		// console.log("dt : ", dt)
		setUserData(res.data.data);
		setName(dt.name);
		setLoading(false);
	}

	const handleNameChange = (e) => {
		setName(e);
		if (user_data.name == e || e == "") {
			setIsNameChanged(false);
		} else {
			setIsNameChanged(true);
		}
	};

	const submit = async () => {
		if (is_name_changed) {
			let payload = {
				name: name,
			};
			try {
				setSubmmiting(true);
				// let data = await API.put(`/user/${user._id}`, payload);
				// if (data && data.status && data.status == 200) {
				// 	setSubmmiting(false);
				// 	setIsNameChanged(false);
				// 	getUser();
				// 	Alert.success("Profile Updated Successfully");

				// }

				await API.put(`/user/${user._id}`, payload)
					.then(response => {
						setSubmmiting(false);
						setIsNameChanged(false);
						getUser();
						Alert.success("Profile Updated Successfully");
					})
					.catch(error => {
						console.log("update profile error : ", error.response.data)
						Alert.success("Something went wrong");
					})

			} catch (error) {
				setSubmmiting(false);
				console.log(error)
			}
		}
	};

	return (
		<View>
			<View
				style={tailwind.style(
					`h-[${headerHeight}px] flex-row w-full bg-white ${isWeb ? "" : "pt-10"
					}`,
					{
						shadowColor: "#000000",
						shadowOffset: {
							width: 0,
							height: 3,
						},
						shadowRadius: 5,
						shadowOpacity: 0.2,
						elevation: 3,
					}
				)}
			>
				<View
					style={tailwind` px-5 my-0 py-0 flex flex-row h-full items-center`}
				>
					<TouchableOpacity onPress={() => navigate("Profile")}>
						<AntDesign name="arrowleft" size={26} style={tailwind`mr-5`} />
					</TouchableOpacity>
					<Text style={tailwind`text-[18px] font-semibold`}>Profile</Text>
				</View>
			</View>

			{!loading && user_data && (
				<View>
					<View style={tailwind`pt-3 w-full flex flex-row justify-center`}>
						<Image
							source={require("../../../../../../assets/img_default_user.png")}
							style={tailwind`w-[100px] h-[100px]`}
						/>
					</View>

					<View style={styles.mainContainer}>
						<View style={styles.inputContainer}>
							<Text style={styles.labels}>Name </Text>
							<TextInput
								style={styles.inputStyle}
								placeholder={"Name"}
								value={name}
								onChangeText={handleNameChange}
							/>
						</View>

						<View style={styles.inputContainer}>
							<Text style={styles.labels}>Phone </Text>
							<TextInput
								style={[styles.inputStyle, { color: "black" }]}
								placeholder={"Phone Number"}
								value={phone}
								editable={false}
								onChangeText={(phone) => setPhone(phone)}
							/>
						</View>

						<View style={styles.inputContainer}>
							<Text style={styles.labels}>Email </Text>
							<TextInput
								style={[styles.inputStyle, { color: "black" }]}
								placeholder={"Email address"}
								value={email}
								readOnly={true}
								onChangeText={(email) => setEmail(email)}
							/>
						</View>

						{!submmiting && (
							<TouchableOpacity
								style={[
									styles.buttonStyle,
									{ backgroundColor: is_name_changed ? "#22c55e" : "#6b7280" },
								]}
								onPress={submit}
							>
								<Text style={styles.buttonText}> Update </Text>
							</TouchableOpacity>
						)}

						{submmiting && (
							<TouchableOpacity
								style={[styles.buttonStyle, { backgroundColor: "#22c55e" }]}
								onPress={null}
							>
								<Text style={styles.buttonText}> Updating </Text>
							</TouchableOpacity>
						)}
					</View>
				</View>
			)}

			{loading && (
				<View style={[tailwind` my-4 h-full w-full `, styles.loadercontainer]}>
					<ActivityIndicator
						size="large"
						color="##60a5fa"
						style={styles.activityIndicator}
					/>
				</View>
			)}
		</View>
	);
}

const styles = StyleSheet.create({
	lineStyle: {
		borderWidth: 0.2,
		borderColor: "#9ca3af",
		padding: 0,
		marginTop: 10,
		marginBottom: 10,
	},
	mainContainer: {
		// height: "100%",
		paddingHorizontal: 20,
		backgroundColor: "#fff",
	},

	inputContainer: {
		marginTop: 10,
	},
	labels: {
		fontWeight: "bold",
		// fontSize: 15,
		color: "#7d7d7d",
		paddingBottom: 5,
		lineHeight: 25,
	},
	inputStyle: {
		// borderWidth: 1,
		borderBottomWidth: 1.4,
		borderColor: "rgba(0, 0, 0, 0.3)",
		paddingHorizontal: 10,
		paddingVertical: 6,
		borderRadius: 2,
	},
	buttonStyle: {
		borderRadius: 5,
		paddingVertical: 10,
		paddingHorizontal: 18,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginVertical: 30,
	},
	buttonText: {
		fontWeight: "bold",
		color: "#fff",
	},
	loadercontainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginTop: "50%",
		marginBottom: "50%",
	},
	activityIndicator: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
});
