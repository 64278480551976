import { MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";
import { Actions } from "react-native-gifted-chat";
import * as DocumentPicker from "expo-document-picker";
import tailwind from "twrnc";
import useChat from "../context";
import Alert from "../../../../../../utils/alert";

export const RenderActions = ({ props }) => {
  const { setFileSelected, report } = useChat();

  const handleAttachment = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        copyToCacheDirectory: false,
      });

      if (!result.canceled) {
        setFileSelected(result);
      }
    } catch (error) {
      Alert.alert("An error occured");
    }
  };

  if (report) return <></>;
  return (
    <Actions
      {...props}
      containerStyle={tailwind`mb-2`}
      icon={() => (
        <MaterialCommunityIcons name="paperclip" size={24} color="#a1a1a1" />
      )}
      options={{
        "Choose From Library": handleAttachment,
      }}
      optionTintColor="#222B45"
    />
  );
};
