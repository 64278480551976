import { createContext, useContext, useState } from "react";

const QuizContext = createContext();

const useQuiz = () => useContext(QuizContext);

export const QuizProvider = ({ children }) => {
    const [questions, setQuestions] = useState([]);
    const [question_ids, setQuestionIds] = useState([]);
    const [current_question_index, setCurrentQuestionIndex] = useState("");
    const [selected_answer, setSelectedAnswer] = useState(null);
    const [answer_result, setAnswerResult] = useState("");
    const [question, setQuestion] = useState(null);
    const [checked, setChecked] = useState(null);
    const [total_correct_answer, setTotalCorrectAnswer] = useState(0);
    const [total_incorrect_answer, setTotalIncorrectAnswer] = useState(0);
    const [total_question_attempt, setTotalQuestionAttempt] = useState(0);
    const [question_activity, setQuestionActivity] = useState([]);
    const [question_start_timestamp, setQuestionStartTimestamp] = useState(0);
    const [question_end_timestamp, setQuestionEndTimestamp] = useState(0);
    const [total_question, setTotalQuestion] = useState(0);
    const [quiz, setQuiz] = useState(null);
    const [user_quiz_log_id, setUserQuizLogId] = useState("");
    const [class_id, setClassId] = useState("");
    const [class_type, setClassType] = useState("");
    const [wq_text_answer, setWqTextAnswer] = useState("");
    const [text_eval_ans, setTextEvalAns] = useState(null);
    const [userEmail1, setUserEmail1] = useState("");
    const [userEmail2, setUserEmail2] = useState("");
    const [quizTimers, setQuizTimers] = useState([]);
    const [question_time_duration, setQuestionTimeDuration] = useState(0);

    const [question_time_out, setQuestionTimeOut] = useState("no");
    const [total_timeout, setTotalTimeout] = useState(0);

    return (
        <QuizContext.Provider
            value={{
                class_id,
                setClassId,
                class_type,
                setClassType,
                user_quiz_log_id,
                setUserQuizLogId,
                quiz,
                setQuiz,
                selected_answer,
                setSelectedAnswer,
                questions,
                setQuestions,
                question_ids,
                setQuestionIds,
                current_question_index,
                setCurrentQuestionIndex,
                answer_result,
                setAnswerResult,
                question,
                setQuestion,
                checked,
                setChecked,
                total_correct_answer,
                setTotalCorrectAnswer,
                total_incorrect_answer,
                setTotalIncorrectAnswer,
                total_question_attempt,
                setTotalQuestionAttempt,
                question_activity,
                setQuestionActivity,
                question_start_timestamp,
                setQuestionStartTimestamp,
                question_end_timestamp,
                setQuestionEndTimestamp,
                total_question,
                setTotalQuestion,
                wq_text_answer,
                setWqTextAnswer,
                text_eval_ans,
                setTextEvalAns,
                userEmail1,
                setUserEmail1,
                userEmail2,
                setUserEmail2,
                quizTimers,
                setQuizTimers,
                question_time_duration,
                setQuestionTimeDuration,
                question_time_out,
                setQuestionTimeOut,
                total_timeout,
                setTotalTimeout
            }}
        >
            {children}
        </QuizContext.Provider>
    );
};

export default useQuiz;
