import { useCallback, useState } from "react";
import { View } from "react-native";
import tailwind from "twrnc";
import { height, isWeb } from "../../../../../constants/constants";
import useUser from "../../../../../context/user";
import { GiftedChat, Bubble, Composer } from "react-native-gifted-chat";
import { TEACHERS } from "../../../../../api/instance";
import FlaggedMessage from "./message/flagged";
import DeletedMessage from "./message/deleted";
import FileMessage from "./message/file";
import { RenderActions } from "./message/render_actions";
import useChat from "./context";
import UploadFileModal from "./message/upload_file_modal";
import ReportMessage from "./message/report_message";
import SubmitReport from "./message/submit_report";
import ChatBubbleWeb from "./message/chat_bubble_web";

const ChatItem = ({ chats, chat }) => {
  const { user, isTeacher } = useUser();
  const [loading, setLoading] = useState(false);
  const { fileSelected } = useChat();

  const { report } = useChat();

  const onSend = async (message) => {
    setLoading(true);
    await TEACHERS.post(`/conversation/${chat.id}/add`, {
      message_body: message[0].text,
      message_type: "text",
      sender: isTeacher ? chat.teacher_name : chat.user_name,
      sender_id: user._id,
      sender_type: isTeacher ? "TEACHER" : "STUDENT",
    });
    setLoading(false);
  };

  const renderBubble = useCallback(
    (props) => {
      const { currentMessage: message } = props;

      if (message?.file) {
        return <FileMessage {...props} />;
      }

      if (message.type === "text") {
        if (message.is_deleted) {
          return <DeletedMessage {...props} type="text" />;
        }
        if (message.is_flagged) {
          return <FlaggedMessage {...props} type="text" />;
        }

        if (report) {
          return <ReportMessage {...props} />;
        }

        return !isWeb ? <Bubble {...props} /> : <ChatBubbleWeb {...props} />;
      }
    },
    [report]
  );

  const ComposerCustom = (props) => {
    if (report) {
      return <SubmitReport chat={chat} />;
    }

    <Composer {...props} />;
  };

  return (
    <View
      style={tailwind.style("", {
        height: isWeb ? height - 100 : height - 150,
      })}
    >
      <UploadFileModal chat={chat} />

      <GiftedChat
        disableComposer={loading || (fileSelected ? true : false)}
        scrollToBottom
        messages={chats.sort((a, b) => b.time_seconds - a.time_seconds)}
        onSend={(messages) => onSend(messages)}
        user={{
          _id: user._id,
        }}
        renderBubble={renderBubble}
        renderActions={RenderActions}
        renderComposer={(props) => ComposerCustom(props)}
        shouldUpdateMessage={() => []}
      />
    </View>
  );
};

export default ChatItem;
