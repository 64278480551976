import React from "react";
import Modal from "../../../../../components/modal";
import { Image, TouchableOpacity, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";

const Guidelines = ({ open, setOpen }) => {
  return (
    <Modal maxHeight={550} visible={open} setVisible={setOpen}>
      <View style={tailwind`flex flex-col items-center`}>
        <Text style={tailwind`text-[#4F4F4F] font-bold`}>Attention!</Text>

        <Text style={tailwind`mt-3 text-[12px] text-[#898989]`}>
          Dear Student, the chat feature helps you and your trainer communicate
          safely with each other.
        </Text>
        <Text style={tailwind`mt-1 text-[12px] text-[#898989]`}>
          Please use it minimally and only for important messages and assignment
          submission
        </Text>

        <Text style={tailwind`mt-4 font-bold text-[12px] text-[#898989]`}>
          Note: You won't be able to access this chat 48 hours after the batch
          is over
        </Text>

        <View style={tailwind`px-5 mt-5 w-full bg-[#F0FAFF] py-4 rounded`}>
          <View style={tailwind`flex flex-row items-center pr-10`}>
            <Image
              source={require("../../../../../../assets/ic_folder.png")}
              style={tailwind`w-[50px] h-[40px]`}
            />
            <Text style={tailwind`text-[#4F4F4F] text-[12px] ml-2 font-light`}>
              NEVER share or ask for any Mobile Number or Email Address or other
              personal information
            </Text>
          </View>
          <View style={tailwind`flex flex-row items-center mt-5 pr-10`}>
            <Image
              source={require("../../../../../../assets/ic_eye.png")}
              style={tailwind`w-[50px] h-[50px]`}
            />
            <Text style={tailwind`text-[#4F4F4F] text-[12px] ml-2 font-light`}>
              NEVER use any abusive or explicit language
            </Text>
          </View>
          <View style={tailwind`flex flex-row items-center mt-5 pr-10`}>
            <Image
              source={require("../../../../../../assets/ic_advt_not.png")}
              style={tailwind`w-[50px] h-[43px]`}
            />
            <Text style={tailwind`text-[#4F4F4F] text-[12px] ml-2 font-light`}>
              NEVER advertise or send spam messages
            </Text>
          </View>
          <View style={tailwind`flex flex-row items-center mt-5 pr-10`}>
            <Image
              source={require("../../../../../../assets/ic_blocuser.png")}
              style={tailwind`w-[50px] h-[47px]`}
            />
            <Text style={tailwind`text-[#4F4F4F] text-[12px] ml-2 font-light`}>
              Please note that you can be BLACKLISTED if you are found doing any
              of the above.
            </Text>
          </View>
        </View>

        <TouchableOpacity
          onPress={() => setOpen(false)}
          style={tailwind`bg-[#2196F3] px-5 py-2 mt-5 rounded-lg`}
        >
          <Text style={tailwind`text-white font-bold`}>Okay</Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

export default Guidelines;
