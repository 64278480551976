import { Image, Linking, Pressable, View } from "react-native";
import Text from "../../../../components/Text";
import Layout from "../../layout";
import HomeHeader from "../../headers/home.header";
import tailwind from "twrnc";
import CompletedClasses from "./classes/completed_classes";
import UpcomingSession from "./classes/upcoming_section/upcoming_section";
import SubscriptionExpiryCard from "./classes/subscription_expiry_card";
import LearnOnYourOwn from "./partials/learn_on_your_own";
import { AppCoursesContextProvider } from "../../../../context/app_courses";
import useUser from "../../../../context/user";
import { TEACHERS } from "../../../../api/instance";
import React, { useState, useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
const Dashboard = () => {
	let { user } = useUser();
	const { navigate } = useNavigation();
	let [status_updated_pending_count, setstatusUpdatedPendingCount] = useState(0);

	useEffect(() => {
		if (user.is_live_teacher) {
			getTeacherStatusNotUpdatedClass()
		}
	}, []);

	async function getTeacherStatusNotUpdatedClass() {
		let params = {
			app_user_id: user._id
		};

		await TEACHERS.post(`/updatedpending`, params)
			.then((response) => {
				setstatusUpdatedPendingCount(response?.data?.data.count)
			})
			.catch((error) => {
				console.log(error);
			});
	}
	return (
		<Layout HeaderContent={<HomeHeader />}>
			<View style={tailwind`mt-5 w-full`}>

				{status_updated_pending_count > 0 && user.is_live_teacher && <View

					style={tailwind`bg-red-500  items-center justify-between py-2 px-2 rounded-lg mb-2`}
				>
					<View style={tailwind`flex flex-wrap`}>
						<Text style={tailwind`text-white text-[12px]`}>
							Urjent: You have not updated status for {status_updated_pending_count} classes. Please <Pressable
								onPress={() => navigate("Classes", {
									tab: "scheduled",
								})}
								style={tailwind``}
							>
								<Text style={tailwind`text-blue-500 text-[13px] underline`}>
									Update
								</Text>

							</Pressable> ASAP for timely payment
						</Text>
					</View>
				</View>}

				<Pressable
					onPress={() => Linking.openURL("https://refer-multibhashi.web.app/")}
					style={tailwind`bg-[#F9DBBD] flex flex-row items-center justify-between py-2 px-5 rounded-lg`}
				>
					<View>
						<Text style={tailwind`text-[#4F4F4F] font-bold text-[18px]`}>
							Refer & Earn
						</Text>
						<Text style={tailwind.style("text-[#FE6E40]")}>
							Refer your friends & Earn
						</Text>
						<Text style={tailwind`text-[#FE5C80] font-bold text-[18px]`}>
							CASH
						</Text>
					</View>

					<Image
						source={require("../../../../../assets/img_refer.png")}
						style={tailwind`w-[100px] h-[80px]`}
					/>
				</Pressable>
			</View>

			<SubscriptionExpiryCard />
			<UpcomingSession />
			<AppCoursesContextProvider>
				<LearnOnYourOwn />
			</AppCoursesContextProvider>

			<CompletedClasses />
		</Layout>
	);
};

export default Dashboard;
