import React from "react";
import { View } from "react-native";
import tailwind from "twrnc";
import { isWeb } from "../../../constants/constants";

const Header = ({ headerHeight, children }) => {
  return (
    <View
      style={tailwind.style(
        `h-[${headerHeight}px] w-full bg-white ${isWeb ? "" : "pt-8"}`,
        {
          shadowColor: "#000000",
          shadowOffset: {
            width: 0,
            height: 3,
          },
          shadowRadius: 5,
          shadowOpacity: 0.2,
          elevation: 3,
        }
      )}
    >
      {children}
    </View>
  );
};

export default Header;
