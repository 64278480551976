import React, { useEffect, useState } from "react";
import AuthLayout from "../layout";
import { Image, Pressable, TextInput, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../components/Text";
import { API } from "../../../api/instance";
import { useNavigation } from "@react-navigation/native";
import Alert from "../../../utils/alert";
import LocalStorage from "../../../utils/localStorage";
import useUser from "../../../context/user";

const Otp = ({ route }) => {
	const { checkIfLoggedIn } = useUser();

	const { goBack, navigate } = useNavigation();
	const { id: otp_id, type: action_type } = route.params.params;

	const [otp, setOtp] = useState("");
	const [resend, setResend] = useState(false);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setResend(true);
		}, 5000);
	}, []);

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const { data } = await API.post("/auth/otp/verify", {
				otp,
				otp_id,
			});

			let user_data = data.data

			console.log("action_type : ", action_type)

			if (action_type == "otp_login") {
				if (user_data.password) {
					setLoading(false);
					await LocalStorage.setItem("user", JSON.stringify(data));
					checkIfLoggedIn();
				} else {
					setLoading(false);
					return navigate("CreatePass", {
						params: {
							user: data.data,
							otp,
							otp_id,
						},
					});
				}
			}

			// if (user_data.password && action_type != "forgot_password") {
			// 	setLoading(false);
			// 	await LocalStorage.setItem("user", JSON.stringify(data));
			// 	checkIfLoggedIn();
			// }

			if (action_type === "set_password") {
				setLoading(false);
				return navigate("CreatePass", {
					params: {
						user: data.data,
						otp,
						otp_id,
					},
				});
			}

			if (action_type === "forgot_password") {
				setLoading(false);
				return navigate("CreatePass", {
					params: {
						user: data.data,
						otp,
						otp_id,
					},
				});
			}
		} catch (error) {
			setLoading(false);
			Alert.alert("An error occured, please request again");
			goBack();
		}
	};

	return (
		<AuthLayout showHeader={false}>
			<Image
				source={require("../../../../assets/fifi_enter_otp.png")}
				style={tailwind`w-[200px] h-[240px]`}
			/>

			<Text style={tailwind`mt-5 text-[18px]`}>Enter the 5 digit OTP</Text>

			<View
				style={tailwind`w-full border border-[#F2F2F2] rounded mt-5 py-2 px-3 text-[12px]`}
			>
				<TextInput
					value={otp}
					onChangeText={(txt) => setOtp(txt)}
					placeholder="Enter OTP"
					style={tailwind``}
				/>
			</View>

			{resend && (
				<Text
					onPress={() => goBack()}
					style={tailwind`text-[#878787] font-medium mt-3 text-[14px]`}
				>
					Did not received the code?{" "}
					<Text style={tailwind`text-[#58ABF6]`}>Request Again</Text>
				</Text>
			)}

			<Pressable
				onPress={handleSubmit}
				disabled={loading}
				style={tailwind`mt-5 flex absolute bottom-[100px] items-center justify-center py-3 w-[200px] rounded-lg
        ${loading ? "bg-[#c1c1c1]" : "bg-[#14C761]"}
        `}
			>
				<Text style={tailwind`text-[#fff] font-bold text-[18px]`}>
					{loading ? "Loading..." : "Submit"}
				</Text>
			</Pressable>
		</AuthLayout>
	);
};

export default Otp;
