import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList,
    Platform,
    ActivityIndicator,
    Button
} from "react-native";
import React, { useState, useEffect } from "react";
import tailwind from "twrnc";
import { height, isWeb, width } from "../../../../constants/constants";
import { useNavigation, useNavigationParam } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { API, TEACHERS } from "../../../../api/instance";
import Layout from "../../layout";
import PageHeader from "../../headers/page.header";
let wd = width <= 500 ? width : 499.99
import useQuestion from "./common/context";
import moment from "moment";
import useUser from "../../../../context/user";
import _ from "lodash";
import useQuiz from "./common/context";

export default function QuizCompleted(props) {
    const { navigate } = useNavigation();
    let { user } = useUser();

    const {
        user_quiz_log_id,
        quiz,
        setQuiz,
        selected_answer,
        setSelectedAnswer,
        questions,
        setQuestions,
        question_ids,
        setQuestionIds,
        current_question_index,
        setCurrentQuestionIndex,
        answer_result,
        setAnswerResult,
        question,
        setQuestion,
        checked,
        setChecked,
        total_correct_answer,
        setTotalCorrectAnswer,
        total_incorrect_answer,
        setTotalIncorrectAnswer,
        total_question_attempt,
        setTotalQuestionAttempt,
        question_activity,
        setQuestionActivity,
        question_start_timestamp,
        setQuestionStartTimestamp,
        question_end_timestamp,
        setQuestionEndTimestamp,
        total_question,
        setTotalQuestion,
        class_type,
        class_id,
        setUserQuizLogId,
        setTotalTimeout
    } = useQuiz();

    let is_course_finished = props.route?.params?.is_course_finished;

    let percentage = (total_correct_answer / total_question) * 100
    percentage = Math.round(percentage)

    useEffect(() => {
    }, []);

    let [card_colors, setCardColors] = useState([
        "EBFFFF",
        "FFF5E9",
        "FFE8E7",
        "FFF5E9",
        "FFE8DF",
        "E7F0FF"
    ]);

    let [lesson_passed_images, setLessonPassedImages] = useState([
        "HIN_BOY_kisses_half.png",
        "HIN_GIRL_kisses_half.png"
    ]);

    let [lesson_failed_images, setLessonFailedImages] = useState([
        "HIN_BOY_straight_half.png",
        "HIN_GIRL_straight_half.png"
    ]);


    let tryThisQuizAgain = async () => {
        try {
            let log_payload = {
                "class_type": class_type,
                "class_id": class_id,
                "phone": user.phone,
                "quiz_id": quiz.id,
                "quiz_start_timestamp": moment().format("x"),
                "total_questions": total_question
            }

            let result = await TEACHERS.post(
                `quiz/logs`, log_payload
            );

            let response = result.data.data
            if (response) {
                setCurrentQuestionIndex(0)
                setChecked(null)
                setQuestion(null)
                setAnswerResult("")
                setSelectedAnswer(null)
                setQuestionStartTimestamp(0)
                setQuestionEndTimestamp(0)
                setTotalCorrectAnswer(0)
                setTotalIncorrectAnswer(0)
                setTotalQuestionAttempt(0)
                setTotalTimeout(0)
                setUserQuizLogId(response.id)
                navigate("Quizzes")
            }
        } catch (error) {
            console.log(error)
            if (error.response?.data?.data?.code == "TOTAL_ATTEMPT_EXCEED") {
                Alert.alert("You cannot start this quiz as you have exceeded attempt limit.");
            } else {
                Alert.alert("Could not open Quiz. Please try again");
            }
        }
    }
    return (
        <Layout
            HeaderContent={
                <PageHeader backRoute={"Home"} pageTitle={quiz.name} bgColor={"#17AAF5"} textColor={"#f7faf8"} />
            }
            tabbarVisible={false}
        // scrollView={false}
        >
            <View style={[tailwind`w-full h-full`]}>

                <View style={tailwind` flex flex-row w-full text-center items-center`}>
                    <View style={[tailwind`w-full px-0 text-center items-center`]}>
                        {
                            percentage >= 50 &&

                            <Image
                                source={require(`../../../../../assets/datacharacter_illustrations/${lesson_passed_images[Math.floor(Math.random() * lesson_passed_images.length)]}`)}
                                style={[tailwind``, styles.img]}
                            />
                        }

                        {
                            percentage < 50 &&
                            <Image
                                source={require(`../../../../../assets/datacharacter_illustrations/${lesson_failed_images[Math.floor(Math.random() * lesson_failed_images.length)]}`)}
                                style={[tailwind``, styles.img]}
                            />
                        }
                    </View>

                </View>

                <View style={[tailwind`px-2 shadow-lg mx-4 rounded py-4 text-center items-center`]}>
                    {
                        percentage >= 50 &&
                        <Text style={[tailwind`text-black font-bold text-[20px]`]}>
                            Awesome...
                        </Text>
                    }

                    {
                        percentage < 50 &&
                        <Text style={[tailwind`text-black font-bold text-[20px]`]}>
                            You Can Do Much Better!
                        </Text>
                    }

                    <View style={tailwind`mt-5 flex flex-row w-full text-center items-center justify-center`}>

                        <View style={tailwind`flex flex-row text-center items-center`}>
                            <Ionicons name="checkmark-circle-outline" size={23} color="#14b8a6" />
                            <Text style={[tailwind`pl-2 text-slate-500 font-semibold text-[16px] items-center`]}>
                                {total_correct_answer}/{total_question}
                            </Text>
                        </View>

                        <View style={[tailwind`mx-2`, styles.verticleLine]}></View>


                        <View style={tailwind`flex flex-row text-center items-center justify-center`}>
                            <Ionicons name="close-circle-outline" size={23} color="#eb0712" />
                            <Text style={[tailwind`pl-2 text-slate-500 font-semibold text-[16px] items-center`]}>
                                {total_incorrect_answer}/{total_question}
                            </Text>
                        </View>
                    </View>
                </View>

                {
                    percentage < 50 && <View style={[tailwind`px-4 mt-4`, {
                        width: (wd - 40)
                    }]}>
                        <Pressable
                            style={[tailwind``, styles.buttonStyle, {
                                backgroundColor: "#3EBD3E"
                            }]}
                            onPress={() => tryThisQuizAgain()}
                        >
                            <Text style={[styles.buttonText, {
                                color: "#FFFFFF"
                            }]}> Try This Quiz Again </Text>
                        </Pressable>
                    </View>
                }
            </View>
        </Layout >
    );
}

const styles = StyleSheet.create({
    img: {
        resizeMode: "contain",
        width: 200,
        height: 200,
        // objectFit: "contain"
    },
    loadercontainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
        marginTop: 50,
        marginBottom: "50%",
    },
    activityIndicator: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
    },
    buttonStyle: {
        // borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 18,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginVertical: 30,
    },
    buttonText: {
        fontWeight: "bold",
        // color: "#fff",
    },

    card_sec1: {
        width: "50%",
        // backgroundColor: "red"
        justifyContent: "center",
        alignItems: "center",
    },
    card_sec2: {
        width: "50%",
        justifyContent: "center",
        // alignItems: "center",
        // backgroundColor: "blue"
    },
    verticleLine: {
        height: '100%',
        width: 1,
        backgroundColor: '#909090',
    }
});
