import { TouchableOpacity, View, ActivityIndicator, Pressable, StyleSheet, TextInput } from "react-native";
import Modal from "../../../../components/modal";
import Text from "../../../../components/Text";
import tailwind from "twrnc";
import { useState, useEffect } from "react";
import { TEACHERS, API } from "../../../../api/instance";
import moment from "moment";
import { AntDesign } from "@expo/vector-icons";
import Alert from "../../../../utils/alert";
import { useNavigation } from "@react-navigation/native";
import useQuiz from "./common/context";
import useUser from "../../../../context/user";

const QuizDialog = ({ open, setOpen, quizzes, call }) => {
    const { navigate } = useNavigation();
    let { user } = useUser();

    const [loading, setLoading] = useState(false);
    const [score_loading, setSecoreLoading] = useState(false);

    const [all_quizzes, setAllQuizzes] = useState([]);
    const [all_quiz_loading, setAllQuizLoading] = useState(false);
    const [user2, setUser2] = useState(null);
    const [email, setEmail] = useState("");
    const [submmiting, setSubmmiting] = useState(false);

    const {
        questions,
        setQuestions,
        setCurrentQuestionIndex,
        setQuiz,
        setUserQuizLogId,
        setTotalQuestion,
        class_id,
        setClassId,
        class_type,
        setClassType,
        setTotalCorrectAnswer,
        setTotalIncorrectAnswer,
        setTotalQuestionAttempt,
        setWqTextAnswer,
        userEmail1,
        setUserEmail1,
        userEmail2,
        setUserEmail2,
        quizTimers,
        setQuizTimers,
        question_time_duration,
        setQuestionTimeDuration
    } = useQuiz();

    const [test_report_view, setTestReportView] = useState("no");

    useEffect(() => {
        setAllQuizzes([])
        if ((call && call.id) && (quizzes && quizzes.length > 0) && open == true) {
            getBestScores()
            getTimers()
        }
    }, [open]);

    async function getBestScores() {
        let c_type = ""
        let c_id = ""
        if (call.webinar_id) {
            c_type = "WEBINAR"
            c_id = call.id
        } else if (call.session_id) {
            c_type = "SESSION"
            c_id = call.id
        } else {
            c_type = ""
            c_id = ""
            Alert.alert("Something went Wrong.Please try again");
            return false
        }

        const quiz_ids = quizzes.map(item => item.quiz_id);

        let payload = {
            "phone": user.phone,
            "quiz_ids": quiz_ids,
            "class_id": c_id,
            "class_type": c_type
        }

        setSecoreLoading(true)

        const { data } = await TEACHERS.post(
            `/quiz/bestscore`, payload
        );

        if (data && data.data && data.data.length > 0) {
            let res = data.data
            for (let index = 0; index < res.length; index++) {
                let item = res[index]

                let inc = quizzes.findIndex((a) => a.quiz_id == item.quiz_id)

                if (inc != -1) {
                    let total_correct_answer = (item.total_correct_answer) ? item.total_correct_answer : 0

                    let score = `${total_correct_answer}/${item.total_questions}`
                    quizzes[inc]["score"] = score
                    quizzes[inc]["total_attempt"] = (item.total_attempt) ? item.total_attempt : 0
                }
            }
        }
        setSecoreLoading(false)
    }

    async function getTimers() {
        const { data } = await TEACHERS.get(
            `/quiztimers`
        );
        setQuizTimers(data.data)
    }

    // currently not using
    const handleClick = async (item, quiz) => {
        try {
            setLoading(true);
            setUserQuizLogId("")
            let c_type = ""
            let c_id = ""
            if (quiz.webinar_class_id) {
                setClassId(quiz.webinar_class_id)
                setClassType("WEBINAR")
                c_type = "WEBINAR"
                c_id = quiz.webinar_class_id
            } else if (quiz.session_call_id) {
                setClassId(quiz.session_call_id)
                setClassType("SESSION")
                c_type = "SESSION"
                c_id = quiz.session_call_id
            } else {
                setClassId("")
                setClassType("")
                c_type = ""
                c_id = ""
                Alert.alert("Something went Wrong.Please try again");
                return false
            }

            const { data } = await TEACHERS.get(
                `/quiz/${item.uuid}`
            );
            // const { data } = await TEACHERS.get(
            //     `/quizquestions/${item.uuid}`
            // );

            let res = (data?.data && data?.data.length > 0) ? data.data : []
            if (res.length > 0) {

                let log_payload = {
                    "class_type": c_type,
                    "class_id": c_id,
                    "phone": user.phone,
                    "quiz_id": item.id,
                    "quiz_start_timestamp": moment().format("x"),
                    "total_questions": res.length
                }

                let result = await TEACHERS.post(
                    `quiz/logs`, log_payload
                );

                let response = result.data.data

                if (response) {
                    setOpen(false);
                    setLoading(false)
                    const q_arr = res.filter(x =>
                        x.type_code != "LC" &&
                        x.type_code != "CVC" &&
                        x.type_code != "LCC"
                    );
                    setTotalCorrectAnswer(0)
                    setTotalIncorrectAnswer(0)
                    setTotalQuestionAttempt(0)
                    setTotalQuestion(q_arr.length)
                    setUserQuizLogId(response.id)
                    setQuestions(res)
                    setQuiz(item)
                    setWqTextAnswer("")
                    setCurrentQuestionIndex(0)

                    return navigate("Quizzes");
                }
            }
            else Alert.alert("Could not open Quiz. Please try again");
        } catch (error) {
            setLoading(false);
            setOpen(false);
            console.log(error)
            if (error.response?.data?.data?.code == "TOTAL_ATTEMPT_EXCEED") {
                Alert.alert1("Sorry, you have already attempted this quiz 3 times so you cannot take it further");
            } else {
                Alert.alert("Could not open Quiz. Please try again");
            }
        }
    };

    const handleModalClose = async () => {
        setOpen(false)
        setAllQuizzes([])
        setTestReportView("no")
    }

    const getAllQuizzes = async () => {
        let c_type = ""
        let entity_id = ""
        if (call.webinar_id) {
            c_type = "WEBINAR"
            entity_id = call.webinar_id
        } else if (call.session_id) {
            c_type = "SESSION"
            entity_id = call.session_id
        } else {
            c_type = ""
            entity_id = ""
            Alert.alert("Something went Wrong.Please try again");
            return false
        }

        let payload = {
            "phone": user.phone,
            "entity_id": entity_id,
            "entity_type": c_type
        }

        setAllQuizLoading(true)

        const { data } = await TEACHERS.post(
            `/quiz/classes/bestscore`, payload
        );

        if (data && data.data && data.data.length > 0) {
            let res = data.data
            if (res) {
                setAllQuizzes(res)
            } else setAllQuizzes([])

        } else setAllQuizzes([])
        setAllQuizLoading(false)
    }

    const handleBack = async () => {
        setAllQuizzes([])
        setTestReportView("no")
    }

    const handleQuizRedirect = async (item, quiz) => {
        try {
            setLoading(true);
            setUserQuizLogId("")
            let c_type = ""
            let c_id = ""
            if (quiz.webinar_class_id) {
                setClassId(quiz.webinar_class_id)
                setClassType("WEBINAR")
                c_type = "WEBINAR"
                c_id = quiz.webinar_class_id
            } else if (quiz.session_call_id) {
                setClassId(quiz.session_call_id)
                setClassType("SESSION")
                c_type = "SESSION"
                c_id = quiz.session_call_id
            } else {
                setClassId("")
                setClassType("")
                c_type = ""
                c_id = ""
                Alert.alert("Something went Wrong.Please try again");
                return false
            }

            const { data } = await TEACHERS.get(
                `/quiz/${item.uuid}`
            );
            // const { data } = await TEACHERS.get(
            //     `/quizquestions/${item.uuid}`
            // );

            let res = (data?.data && data?.data.length > 0) ? data.data : []
            if (res.length > 0) {
                let log_payload = {
                    "class_type": c_type,
                    "class_id": c_id,
                    "phone": user.phone,
                    "quiz_id": item.id,
                    "quiz_start_timestamp": moment().format("x"),
                    "total_questions": res.length
                }

                let result = await TEACHERS.post(
                    `quiz/logs`, log_payload
                );

                let response = result.data.data

                if (response) {
                    setUser2(response.user)
                    setUserQuizLogId(response.id)
                    setQuestions(res)
                    setQuiz(item)

                    const q_arr = res.filter(x =>
                        x.type_code != "LC" &&
                        x.type_code != "CVC" &&
                        x.type_code != "LCC"
                    );
                    setTotalQuestion(q_arr.length)
                    setUserEmail1(user.email)
                    setUserEmail2(response.user?.email)

                    if (item?.test_report == "yes") {
                        setLoading(false);
                        setTestReportView(item.test_report)
                    } else {
                        startQuiz(res)
                    }
                }
            }
            else Alert.alert("Could not open Quiz. Please try again");
        } catch (error) {
            setLoading(false);
            setOpen(false);
            console.log(error)
            if (error.response?.data?.data?.code == "TOTAL_ATTEMPT_EXCEED") {
                Alert.alert1("Sorry, you have already attempted this quiz 3 times so you cannot take it further");
            } else {
                Alert.alert("Could not open Quiz. Please try again");
            }
        }
    };

    // currently not using
    const handleStartQuiz = async (call, quiz) => {
        try {
            setLoading(true);
            setUserQuizLogId("")
            let c_type = ""
            let c_id = ""
            if (call.webinar_id) {
                setClassId(call.id)
                setClassType("WEBINAR")
                c_type = "WEBINAR"
                c_id = call.id
            } else if (call.session_id) {
                setClassId(call.id)
                setClassType("SESSION")
                c_type = "SESSION"
                c_id = call.id
            } else {
                setClassId("")
                setClassType("")
                c_type = ""
                c_id = ""
                Alert.alert("Something went Wrong.Please try again");
                return false
            }

            const { data } = await TEACHERS.get(
                `/quiz/${quiz.quizzes.uuid}`
            );
            setOpen(false);
            setLoading(false)
            let res = (data?.data && data?.data.length > 0) ? data.data : []
            if (res.length > 0) {

                let log_payload = {
                    "class_type": c_type,
                    "class_id": c_id,
                    "phone": user.phone,
                    "quiz_id": quiz.quiz_id,
                    "quiz_start_timestamp": moment().format("x"),
                    "total_questions": res.length
                }

                let result = await TEACHERS.post(
                    `quiz/logs`, log_payload
                );

                let response = result.data.data
                if (response) {
                    const q_arr = res.filter(x =>
                        x.type_code != "LC" &&
                        x.type_code != "CVC" &&
                        x.type_code != "LCC"
                    );
                    setTotalCorrectAnswer(0)
                    setTotalIncorrectAnswer(0)
                    setTotalQuestionAttempt(0)
                    setTotalQuestion(q_arr.length)
                    setUserQuizLogId(response.id)
                    setQuestions(res)
                    setQuiz(quiz.quizzes)
                    setWqTextAnswer("")
                    setCurrentQuestionIndex(0)

                    return navigate("Quizzes");
                }
            }
            else Alert.alert("Could not open Quiz. Please try again");
        } catch (error) {
            setLoading(false);
            console.log(error)
            if (error.response?.data?.data?.code == "TOTAL_ATTEMPT_EXCEED") {
                Alert.alert("Sorry, you have already attempted this quiz 3 times so you cannot take it further");
            } else {
                Alert.alert("Could not open Quiz. Please try again");
            }
        }
    };

    const startQuiz = async (qns = null) => {
        setLoading(false);
        setOpen(false);
        setTotalCorrectAnswer(0)
        setTotalIncorrectAnswer(0)
        setTotalQuestionAttempt(0)
        setWqTextAnswer("")
        let q_index = 0

        let curr_qns = null
        if (questions.length > 0) {
            curr_qns = questions[q_index]
        } else if (qns) {
            curr_qns = qns[q_index]
        } else {
            Alert.alert("Could not open Quiz. Please try again");
            setLoading(false);
            return
        }

        let obj = quizTimers.find(item => item.question_type == curr_qns.type_code)
        if (!obj) {
            Alert.alert("Could not open Quiz. Please try again");
            setLoading(false);
        }

        setCurrentQuestionIndex(q_index)
        setQuestionTimeDuration(obj.timer)
        return navigate("Quizzes");
    };

    const [emailError, setEmailError] = useState("");

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (email) => {
        setEmail(email);

        // Validate email and set an error message if invalid
        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address");
        } else {
            setEmailError(""); // Clear the error message if valid
        }
    };

    const submit = async () => {
        let payload = {
            email: email,
        };
        try {
            setSubmmiting(true);

            setUserEmail1(email)
            setUserEmail2(email)

            startQuiz()

            await API.put(`/user/${user._id}`, payload)
                .then(response => {
                    setSubmmiting(false);
                })
                .catch(error => {
                    console.log("update profile error : ", error.response.data)
                    Alert.success("Something went wrong");
                })

        } catch (error) {
            setSubmmiting(false);
            console.log(error)
        }
    }

    const handleQuizRedirectFromAll = async (call, quiz) => {
        try {
            setLoading(true);
            setUserQuizLogId("")
            let c_type = ""
            let c_id = ""
            if (call.webinar_id) {
                setClassId(call.id)
                setClassType("WEBINAR")
                c_type = "WEBINAR"
                c_id = call.id
            } else if (call.session_id) {
                setClassId(call.id)
                setClassType("SESSION")
                c_type = "SESSION"
                c_id = call.id
            } else {
                setClassId("")
                setClassType("")
                c_type = ""
                c_id = ""
                Alert.alert("Something went Wrong.Please try again");
                return false
            }

            const { data } = await TEACHERS.get(
                `/quiz/${quiz.quizzes.uuid}`
            );
            let res = (data?.data && data?.data.length > 0) ? data.data : []
            if (res.length > 0) {

                let log_payload = {
                    "class_type": c_type,
                    "class_id": c_id,
                    "phone": user.phone,
                    "quiz_id": quiz.quiz_id,
                    "quiz_start_timestamp": moment().format("x"),
                    "total_questions": res.length
                }

                let result = await TEACHERS.post(
                    `quiz/logs`, log_payload
                );

                let response = result.data.data
                if (response) {
                    setUser2(response.user)
                    const q_arr = res.filter(x =>
                        x.type_code != "LC" &&
                        x.type_code != "CVC" &&
                        x.type_code != "LCC"
                    );
                    setTotalQuestion(q_arr.length)
                    setUserQuizLogId(response.id)
                    setQuestions(res)
                    setQuiz(quiz.quizzes)
                    setUserEmail1(user.email)
                    setUserEmail2(response.user?.email)

                    if (quiz?.quizzes?.test_report == "yes") {
                        setLoading(false);
                        setTestReportView(quiz.quizzes.test_report)
                    } else {
                        startQuiz()
                    }
                }
            }
            else Alert.alert("Could not open Quiz. Please try again");
        } catch (error) {
            setLoading(false);
            console.log(error)
            if (error.response?.data?.data?.code == "TOTAL_ATTEMPT_EXCEED") {
                Alert.alert("Sorry, you have already attempted this quiz 3 times so you cannot take it further");
            } else {
                Alert.alert("Could not open Quiz. Please try again");
            }
        }
    };

    return (
        <Modal visible={open} setVisible={setOpen} maxHeight={500}>
            <View>
                <View style={tailwind`flex flex-row justify-between items-center`}>
                    {
                        test_report_view == "no" && <Text style={tailwind`font-bold text-[16px]`}>Quizzes</Text>
                    }

                    {
                        test_report_view == "yes" && <>
                            {(user.email || user2.email) &&
                                <Text style={tailwind`font-bold text-[16px]`}>Email Address For Test Report</Text>
                            }

                            {(!user.email && !user2.email) &&
                                <Text style={tailwind`font-bold text-[16px]`}>Share Your Email Address For Test Report</Text>
                            }
                        </>
                    }

                    <AntDesign
                        onPress={() => handleModalClose()}
                        name="close"
                        size={24}
                        color="black"
                    />
                </View>
                {test_report_view == "no" && all_quizzes.length == 0 && <TouchableOpacity disabled={loading} onPress={getAllQuizzes}>
                    <Text
                        style={tailwind`my-2 text-[#2196F2] font-bold text-[12px] mb-5`}
                    >
                        View all Quizzes
                    </Text>
                </TouchableOpacity>
                }

                {test_report_view == "no" && all_quizzes.length > 0 && <TouchableOpacity disabled={loading} onPress={() => handleBack()}>
                    <Text
                        style={tailwind`my-2 text-[#2196F2] font-bold text-[12px] mb-5`}
                    >
                        Back
                    </Text>
                </TouchableOpacity>
                }

                {
                    test_report_view == "no" &&
                    <>
                        {
                            all_quizzes && all_quizzes.length > 0 ? (
                                <>
                                    {all_quizzes
                                        .map((call, i) => {
                                            const date = moment.unix(call.slot / 1000).format("Do MMMM");
                                            return (
                                                <View key={i} style={tailwind``}>
                                                    <Text style={tailwind`font-bold mb-3`}>{date}</Text>

                                                    {call.attached_quizzes.map((qz, q_iindex) => (
                                                        <View style={tailwind`flex flex-row justify-between mb-5 items-center`} key={qz.id}>
                                                            <View style={tailwind`flex flex-row items-center`}>

                                                                <Text style={tailwind`w-[180px] text-[12px] ml-3`}>
                                                                    {qz.quizzes?.name}
                                                                </Text>
                                                            </View>

                                                            {qz.highest_score && < View >
                                                                <Text style={tailwind`text-[12px] font-bold text-[#2196F2]`}>Score : {(qz.highest_score.total_correct_answer) ? qz.highest_score.total_correct_answer : 0}/{qz.highest_score.total_questions}</Text>
                                                            </View>}

                                                            {/* <TouchableOpacity disabled={loading} onPress={() => handleStartQuiz(call, qz)}>
                                                                <Text style={tailwind`text-[12px] font-bold text-[#2196F2]`}>Start</Text>
                                                            </TouchableOpacity> */}

                                                            <TouchableOpacity disabled={loading} onPress={() => handleQuizRedirectFromAll(call, qz)}>
                                                                <Text style={tailwind`text-[12px] font-bold text-[#2196F2]`}>Start</Text>
                                                            </TouchableOpacity>


                                                        </View>
                                                    ))}
                                                </View>
                                            );
                                        })}
                                </>
                            ) : (
                                <>
                                    {
                                        quizzes && quizzes.length > 0 &&
                                        <>
                                            {
                                                quizzes.map((quiz, i) => {
                                                    return (
                                                        <View style={tailwind`flex flex-row justify-between mb-3 items-center`} key={i}>
                                                            <View style={tailwind`flex flex-row items-center`}>
                                                                <Text style={tailwind`w-[180px] text-[12px]`}>
                                                                    {quiz.quizzes?.name}
                                                                </Text>
                                                            </View>
                                                            {!score_loading && quiz.score && < View >
                                                                <Text style={tailwind`text-[12px] font-bold text-[#2196F2]`}>Score : {quiz.score}
                                                                    {quiz.total_attempt && <Text style={tailwind`pl-2`}>;Attempts : {quiz.total_attempt}</Text>}
                                                                </Text>
                                                            </View>}

                                                            {score_loading && < View >
                                                                <Text><ActivityIndicator size="small" color="#51aadd" /></Text>
                                                            </View>}

                                                            {/* <TouchableOpacity disabled={loading} onPress={() => handleClick(quiz.quizzes, quiz)}>
                                                        <Text style={tailwind`text-[12px] font-bold text-[#2196F2]`}>Start</Text>
                                                    </TouchableOpacity> */}

                                                            <TouchableOpacity disabled={loading} onPress={() => handleQuizRedirect(quiz.quizzes, quiz)}>
                                                                <Text style={tailwind`text-[12px] font-bold text-[#2196F2]`}>Start</Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                    );
                                                })

                                            }
                                        </>
                                    }
                                </>
                            )
                        }
                    </>
                }

                {
                    test_report_view == "yes" &&
                    <>
                        <View>
                            {(user.email || user2.email) &&
                                <View>
                                    {(user.email == user2.email) &&
                                        <Text style={tailwind`p-3  font-semibold text-center`}>
                                            {`We will send your test report on your email id ${user.email} at the end of this test. Please write to us at support@multibhashi.com in case you have any doubts.`}
                                        </Text>
                                    }

                                    {(user.email != user2.email && user.email && user2.email) &&
                                        <Text style={tailwind`p-3  font-semibold text-center`}>
                                            {`We will send your test report on your email id ${user.email} and ${user2.email} at the end of this test. Please write to us at support@multibhashi.com in case you have any doubts`}
                                        </Text>
                                    }

                                    {(user.email != user2.email && (user.email || user2.email)) &&
                                        <Text style={tailwind`p-3  font-semibold text-center`}>
                                            {`We will send your test report on your email id ${(user.email) ? user.email : user2.email} at the end of this test. Please write to us at support@multibhashi.com in case you have any doubts`}
                                        </Text>
                                    }

                                    <View style={tailwind``}>
                                        <TouchableOpacity
                                            style={[
                                                styles.buttonStyle,
                                                { backgroundColor: "#6b7280" },
                                            ]}
                                            onPress={() => startQuiz()}
                                        >
                                            <Text style={styles.buttonText}> Start </Text>
                                        </TouchableOpacity>
                                    </View>

                                </View>
                            }

                            {
                                (!user.email && !user2.email) &&
                                <View>
                                    <View style={styles.mainContainer}>

                                        <Text style={tailwind`p-3  font-semibold text-justify`}>
                                            We have to send your test report on your email at the end of this test so please provide your email address. Please write to us at support@multibhashi.com in case you have any doubts
                                        </Text>

                                        <View style={styles.inputContainer}>
                                            <Text style={styles.labels}>Email </Text>
                                            <TextInput
                                                style={[styles.inputStyle, { color: "black" }]}
                                                placeholder={"Email address"}
                                                value={email}
                                                onChangeText={handleEmailChange}
                                            />
                                        </View>

                                        {!submmiting && (
                                            <TouchableOpacity
                                                style={[
                                                    styles.buttonStyle,
                                                    { backgroundColor: "#6b7280" },
                                                ]}
                                                disabled={emailError !== "" || email === ""}
                                                onPress={submit}
                                            >
                                                <Text style={styles.buttonText}> Submit </Text>
                                            </TouchableOpacity>
                                        )}
                                        {emailError ? <Text style={styles.errorText}>{emailError}</Text> : null}


                                        {submmiting && (
                                            <TouchableOpacity
                                                style={[styles.buttonStyle, { backgroundColor: "#22c55e" }]}
                                                onPress={null}
                                            >
                                                <Text style={styles.buttonText}> Loading </Text>
                                            </TouchableOpacity>
                                        )}
                                    </View>

                                </View>
                            }
                        </View>
                    </>
                }

                {loading && <Text>Loading All Quizzes...</Text>}
            </View>
        </Modal >
    );
};

export default QuizDialog;


const styles = StyleSheet.create({
    mainContainer: {
        // height: "100%",
        paddingHorizontal: 20,
        backgroundColor: "#fff",
    },

    inputContainer: {
        marginTop: 10,
    },
    labels: {
        fontWeight: "bold",
        // fontSize: 15,
        color: "#7d7d7d",
        paddingBottom: 5,
        lineHeight: 25,
    },
    inputStyle: {
        // borderWidth: 1,
        borderBottomWidth: 1.4,
        borderColor: "rgba(0, 0, 0, 0.3)",
        paddingHorizontal: 10,
        paddingVertical: 6,
        borderRadius: 2,
    },
    buttonStyle: {
        borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 18,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginVertical: 30,
    },
    buttonText: {
        fontWeight: "bold",
        color: "#fff",
    },
    loadercontainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50%",
        marginBottom: "50%",
    },
    activityIndicator: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    errorText: {
        color: "red",
        fontSize: 12,
        marginBottom: 10,
    },
});
