import { View, TouchableOpacity } from "react-native";
import tailwind from "twrnc";
import Text from "../../../components/Text";
import { useNavigation } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";

const PageHeader = ({ backRoute, pageTitle, bgColor = null, textColor = null, others = null }) => {
    const { navigate } = useNavigation();
    let handleNavigation = () => {
        navigate(backRoute)
    };

    return (
        <View
            style={[tailwind` px-5 my-0 py-0 flex flex-row h-full items-center ${(bgColor ? `bg-[${bgColor}]` : '')}`]}
        >
            <TouchableOpacity onPress={handleNavigation}>
                <AntDesign name="arrowleft" size={26} style={tailwind`mr-5 ${(textColor ? `text-[${textColor}]` : '')}`} />
            </TouchableOpacity>
            <Text style={[tailwind`text-[18px] font-semibold ${(textColor ? `text-[${textColor}]` : '')}`]}>
                {pageTitle}
            </Text>
        </View >
    );
};

export default PageHeader;
