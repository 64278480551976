import {
	View,
	Text,
	TouchableOpacity,
	// Alert,
	TextInput,
	StyleSheet,
	Image,
	ActivityIndicator,
} from "react-native";
import React from "react";
import tailwind from "twrnc";
import { isWeb } from "../../../../../constants/constants";
import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { TEACHERS } from "../../../../../api/instance";
import { API } from "../../../../../api/instance";
import useUser from "../../../../../context/user";
import { useState, useEffect } from "react";
import Alert from "../../../../../utils/alert";

export default function LinkTeacher() {
	const { navigate } = useNavigation();
	const headerHeight = isWeb ? 60 : 100;
	let { user } = useUser();
	const [loading, setLoading] = useState(true);
	const [submmiting, setSubmmiting] = useState(false);
	let [is_code_entered, setIsCodeEntered] = useState(false);
	let [code, setCode] = useState("");
	let [user_data, setUserData] = useState(null);
	let [linked_teacher, setLinkedTeacher] = useState(null);
	let [is_link_diff, setIsLinkDiff] = useState(false);

	useEffect(() => {
		setLoading(true);
		if (user) {
			getLinkTeacher();
			getUser();
		}
	}, []);

	async function getUser() {
		await API.get(`/user/${user._id}`)
			.then(response => {
				setUserData(response.data.data);
			})
			.catch(error => {
				console.log("get user in link profile error")
				console.log(error.response)
			})
	}

	async function getLinkTeacher() {
		let url = `/teacher?app_user_id=${user._id}`;
		await TEACHERS.get(url)
			.then(response => {
				setLinkedTeacher(response.data.data);
				if (linked_teacher) setIsLinkDiff(false);
				setLoading(false);
			})
			.catch(error => {
				console.log("getLinkTeacher error")
				console.log(error.response.data)
				setLoading(false);
			})
	}

	const handleCodeChange = (e) => {
		setCode(e);
		if (e == "") {
			setIsCodeEntered(false);
		} else {
			setIsCodeEntered(true);
		}
	};

	const submit = async () => {
		if (is_code_entered) {
			let payload = {
				code: code,
				user_id: user._id,
			};
			try {
				setSubmmiting(true);
				let data = await TEACHERS.post(`/teacher/app/profile`, payload);
				if (data && data.status && data.status == 200) {
					let app_payload = {
						email: user_data.email,
						image_url: user_data.image_url,
						is_live_teacher: true,
						name: user_data.name,
						phone: user_data.phone,
						_id: user_data._id,
					};

					let prof = await API.put(`/user/${user._id} `, app_payload);

					if (prof && prof.status && prof.status == 200) {
						setSubmmiting(false);
						setCode("");
						setIsCodeEntered(false);
						getLinkTeacher();
						getUser();
						Alert.alert("Teacher Account Linked Successfully");
					}
				}
			} catch (error) {
				console.log("API ERROR");
				setSubmmiting(false);
			}
		}
	};

	const linkWithOther = () => {
		setIsLinkDiff(true);
	};

	return (
		<View>
			<View
				style={tailwind.style(
					`h-[${headerHeight}px] flex-row w-full bg-white ${isWeb ? "" : "pt-10"
					}`,
					{
						shadowColor: "#000000",
						shadowOffset: {
							width: 0,
							height: 3,
						},
						shadowRadius: 5,
						shadowOpacity: 0.2,
						elevation: 3,
					}
				)}
			>
				<View
					style={tailwind` px-5 my-0 py-0 flex flex-row h-full items-center`}
				>
					<TouchableOpacity onPress={() => navigate("Profile")}>
						<AntDesign name="arrowleft" size={26} style={tailwind`mr-5`} />
					</TouchableOpacity>
					<Text style={tailwind`text-[18px] font-semibold`}>
						Teacher Account
					</Text>
				</View>
			</View>

			{!loading && (
				<View>
					{(!linked_teacher || is_link_diff) && (
						<View>
							<View style={tailwind`pt-3 w-full flex flex-row justify-center`}>
								<Image
									source={require("../../../../../../assets/img_default_teacher.png")}
									style={tailwind`w-[100px] h-[100px]`}
								/>
							</View>
							<View>
								<View
									style={tailwind`pt-3 w-full flex flex-row justify-center`}
								>
									<Text
										style={tailwind`text-[18px] text-center text-gray-700 px-3`}
									>
										Please enter the code provided by Multibhashi
									</Text>
								</View>

								<View style={[styles.mainContainer, { paddingVertical: 10 }]}>
									<View style={styles.inputContainer}>
										<TextInput
											style={styles.inputStyle1}
											placeholder={"Enter Code"}
											value={code}
											onChangeText={handleCodeChange}
											elevation={1}
										/>
									</View>

									{!submmiting && (
										<TouchableOpacity
											style={[
												styles.buttonStyle,
												{
													backgroundColor: is_code_entered ? "#22c55e" : "#6b7280",
												},
											]}
											onPress={submit}
										>
											<Text style={styles.buttonText}> SUBMIT </Text>
										</TouchableOpacity>
									)}

									{submmiting && (
										<TouchableOpacity
											style={[
												styles.buttonStyle,
												{ backgroundColor: "#22c55e" },
											]}
											onPress={null}
										>
											<Text style={styles.buttonText}> Submitting... </Text>
										</TouchableOpacity>
									)}
								</View>
							</View>
						</View>
					)}

					{linked_teacher && !is_link_diff && (
						<View style={tailwind`pt-4 w-full px-5`}>
							<View>
								<Text style={tailwind`text-[14px]`}>Linked To : </Text>
							</View>
							<View style={tailwind`pt-2 px-6`}>
								<View style={tailwind`pt-2`}>
									<Text>{linked_teacher.name}</Text>
								</View>
								<View style={tailwind`pt-2`}>
									<Text>{linked_teacher.email}</Text>
								</View>
								<View style={tailwind`pt-2`}>
									<Text>{linked_teacher.phone}</Text>
								</View>
							</View>

							<TouchableOpacity
								onPress={linkWithOther}
								style={tailwind` my-3 w-full flex flex-row`}
							>
								<Text
									style={tailwind` underline text-blue-600 text-lg`}
								>
									Link Different Account
								</Text>
							</TouchableOpacity>
						</View>
					)}
				</View>
			)}

			{loading && (
				<View style={[tailwind` my-4 h-full w-full `, styles.loadercontainer]}>
					<ActivityIndicator
						size="large"
						color="##60a5fa"
						style={styles.activityIndicator}
					/>
				</View>
			)}
		</View>
	);
}

const styles = StyleSheet.create({
	lineStyle: {
		borderWidth: 0.2,
		borderColor: "#9ca3af",
		padding: 0,
		marginTop: 10,
		marginBottom: 10,
	},
	mainContainer: {
		paddingHorizontal: 20,
		backgroundColor: "#fff",
		alignItems: "center",
	},

	inputContainer: {
		marginTop: 10,
	},
	labels: {
		fontWeight: "bold",
		color: "#7d7d7d",
		paddingBottom: 5,
		lineHeight: 25,
	},
	inputStyle: {
		borderBottomWidth: 1.4,
		borderColor: "rgba(0, 0, 0, 0.3)",
		paddingHorizontal: 10,
		paddingVertical: 6,
		borderRadius: 2,
	},

	inputStyle1: {
		width: 100,
		borderColor: "rgba(0, 0, 0, 0.3)",
		paddingHorizontal: 10,
		paddingVertical: 6,
		borderRadius: 2,
		shadowColor: "#7d7d7d",
		shadowOpacity: 0.8,
		shadowRadius: 2,
		shadowOffset: {
			height: 1,
			width: 1,
		},
	},

	buttonStyle: {
		borderRadius: 5,
		paddingVertical: 10,
		paddingHorizontal: 18,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginVertical: 30,
	},
	buttonText: {
		fontWeight: "bold",
		color: "#fff",
	},
	loadercontainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginTop: "50%",
		marginBottom: "50%",
	},
	activityIndicator: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
});
