import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList
} from "react-native";
import React, { useState, useEffect } from "react";
import tailwind from "twrnc";
import { height, isWeb, width } from "../../../../../constants/constants";
import { useNavigation, useNavigationParam } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";

import { API } from "../../../../../api/instance";
import Layout from "../../../layout";
import PageHeader from "../../../headers/page.header";

import LessonDialog from "./lesson_dialog";
let wd = width <= 500 ? width : 499.99
import useQuestion from "./common/context";
import moment from "moment";
import useUser from "../../../../../context/user";
import _ from "lodash";

export default function Modules(props) {
    const { navigate } = useNavigation();
    let { user } = useUser();

    const headerHeight = isWeb ? 60 : 100;
    const headerHeight1 = isWeb ? 50 : 50;
    const {
        all_module_states,
        setAllModuleStates,
        setModuleStates,
        module,
        setModule,
        setLevel,
        unit,
        setUnit,
        setLesson,
        selected_course,
        setQuestions,
        setQuestionIds,
        setCurrentQuestionIndex,
        setLessonStartTimestamp,
        setTotalQuestion,
        setCurrentUnitIndex,
        setCurrentLessonIndex,
        current_level_index,
        setCurrentLevelIndex, } = useQuestion();

    let back_route = props.route?.params?.from_page
    let modules = selected_course.modules
    let single_module = modules.length == 1 ? modules[0] : null
    const [lessonModalOpen, setLessonModalOpen] = useState(false);
    let [redirect_loading, setRedirectLoading] = useState(false);

    let [card_colors, setCardColors] = useState([
        "EBFFFF",
        "FFF5E9",
        "FFE8E7",
        "FFE8DF",
        "E7F0FF"
    ]);

    useEffect(() => {
        getCourseModuleStates()
        setCurrentLevelIndex(0)
        setModule()
    }, []);

    async function getCourseModuleStates() {
        let params = {
            course_id: selected_course._id,
        };
        let user_id = user._id
        await API.get(`/course_module_state/${user_id}`, { params })
            .then((response) => {
                let res = response.data.data
                if (res.length > 0)
                    setAllModuleStates(res)
            })
            .catch((error) => {
                console.log(error.response.data);
                setLoading(false);
            });
    }

    let handleRedirectViewAll = (module) => {
        setModule(module)
        setModuleStateForType(module.type)
        navigate("Lessons", {
            from_page: "Courses"
        })
    }

    let handleRedirect1 = (module, unit) => {
        if (module.type == "GRAMMAR") {
            if (unit.lessons.length > 0) {
                setModule(module)
                setUnit(unit)
                setLessonModalOpen(true)
            }
        }
    }

    let handleRedirectToQuestions = async (unit, module, unit_index) => {
        let question_ids = []
        if (module.type == "CONVERSATION" || module.type == "CUSTOM") {
            if (unit.lessons.length == 1) {
                question_ids = unit.lessons[0].questions
                setCurrentLessonIndex(0)
            }
        }

        if (!redirect_loading && question_ids.length > 0) {
            try {
                let qns = await getQuestionByIds(question_ids)
                let res = qns.data.data

                const glc_question = res.filter(x =>
                    x.type_code == "GLC"
                );

                const no_glc_question = res.filter(x =>
                    x.type_code != "GLC"
                );

                let sorted_qns = [...glc_question, ...no_glc_question]

                setQuestions(sorted_qns)
                setCurrentQuestionIndex(0)

                setModule(module)
                setLevel(module.levels[current_level_index])
                setCurrentUnitIndex(unit_index)
                setUnit(unit)
                setLesson(unit.lessons[0])
                setQuestionIds(question_ids)
                setRedirectLoading(false)
                setLessonStartTimestamp(moment().format('x'))

                let obj = {
                    from_page: "Modules"
                }

                setModuleStateForType(module.type)

                let total_qns = 0
                const q_arr = res.filter(x =>
                    x.type_code != "LC" &&
                    x.type_code != "CVC" &&
                    x.type_code != "LCC"
                );
                setTotalQuestion(q_arr.length)

                navigate("Questions", obj)
            } catch (error) {
                setRedirectLoading(false)
                console.log(error.response);
            }
        }
    }

    async function getQuestionByIds(question_ids) {
        let params = {
            questions: question_ids,
        };
        return await API.post(`questions/ids`, params)
    }

    let selectLesson = async (props) => {
        let lesson_index = _.findIndex(unit.lessons, function (o) { return o._id == props._id; });
        let u_index = _.findIndex(module.levels[current_level_index].units, function (o) { return o._id == unit._id; });
        let question_ids = props.questions
        setCurrentLessonIndex(lesson_index)
        if (question_ids.length > 0) {
            try {
                let qns = await getQuestionByIds(question_ids)
                let res = qns.data.data

                const glc_question = res.filter(x =>
                    x.type_code == "GLC"
                );

                const no_glc_question = res.filter(x =>
                    x.type_code != "GLC"
                );

                let sorted_qns = [...glc_question, ...no_glc_question]

                setQuestions(sorted_qns)
                setCurrentQuestionIndex(0)
                setModule(module)
                setLevel(module.levels[current_level_index])
                setCurrentUnitIndex(u_index)
                setUnit(module.levels[current_level_index].units[u_index])
                setLesson(props)
                setQuestionIds(question_ids)
                setRedirectLoading(false)
                setLessonStartTimestamp(moment().format('x'))
                setModuleStateForType(module.type)
                let obj = {
                    from_page: "Modules",
                }

                let total_qns = 0
                const q_arr = res.filter(x =>
                    x.type_code != "LC" &&
                    x.type_code != "CVC" &&
                    x.type_code != "LCC"
                );
                setTotalQuestion(q_arr.length)

                navigate("Questions", obj)
            } catch (error) {
                setRedirectLoading(false)
                console.log(error.response);
            }
        }
    }

    async function setModuleStateForType(type) {
        if (all_module_states.length > 0) {
            let obj = all_module_states.find(o => o.module_type == type);
            if (obj) {
                setModuleStates(obj)
            }
        }
    }

    return (
        <Layout
            HeaderContent={
                <PageHeader backRoute={back_route} pageTitle={`Learn ${selected_course.target_language[0].toUpperCase() + selected_course.target_language.slice(1)} On Your Own`} />
            }
        // scrollView={false}
        >
            {modules.length > 1 && <View style={[tailwind`w-full`]}>
                {module && unit && < LessonDialog
                    open={lessonModalOpen}
                    setOpen={setLessonModalOpen}
                    module={module}
                    unit={unit}
                    selectLesson={selectLesson}
                />}
                {modules.map((module, i) => (
                    <View key={i + moment().format('x')}>
                        <View
                            style={tailwind`flex flex-row justify-between w-full items-center`}
                        >
                            <Text style={tailwind`font-bold mt-5 mb-2 text-[18px]`}>
                                {module.type} {module.type == `GRAMMAR` ? `UNITS` : `LESSONS`}
                            </Text>

                            <Text
                                onPress={() => handleRedirectViewAll(module)}

                                style={tailwind`text-[#2096F3] font-semibold`}
                            >
                                View All
                            </Text>
                        </View>

                        {module.type == "GRAMMAR" && <View>
                            <ScrollView
                                horizontal
                                style={{ maxWidth: "100%", }}
                            >
                                {module.levels[current_level_index].units.map((unit, index) => (
                                    <Pressable
                                        onPress={() => handleRedirect1(module, unit)}
                                        key={index}
                                    >
                                        <View key={i} style={[tailwind`m-2`, { alignItems: 'center', backgroundColor: (index + 1 > card_colors.length) ? "#" + card_colors[index % card_colors.length] : "#" + card_colors[index] }]}>
                                            {/* <View style={tailwind`mt-1 w-full bg-slate-300 rounded-full h-2 dark:bg-gray-700`}>
                                                <View style={[tailwind`bg-green-600 h-2 rounded-full`, { width: (item.progress) ? item.progress + "%" : 0 }]}>

                                                </View>
                                            </View>
                                            <Text style={tailwind`text-[10px] text-center`}>{(item.progress) ? item.progress : 0}%</Text> */}

                                            <Image
                                                source={{ uri: selected_course?.image }}
                                                style={[tailwind`rounded mt-2`, styles.img]}
                                            />

                                            <View style={[tailwind`py-2`, styles.unitname]}>
                                                <Text style={tailwind`text-[10px] text-slate-500 w-[100px] text-center mb-2`}> {unit.name_english}</Text>

                                                <Text style={tailwind`text-[10px] text-slate-500 w-[100px] text-center`}> {unit.name}</Text>
                                            </View>
                                        </View>
                                    </Pressable>

                                ))}
                            </ScrollView>
                        </View>

                        }

                        {module.type == "CONVERSATION" && <View>
                            <ScrollView
                                horizontal
                                style={{ maxWidth: "100%" }}
                            >
                                {module.levels[current_level_index].units.map((unit, index) => (
                                    <Pressable
                                        onPress={() => handleRedirectToQuestions(unit, module, index)}
                                        key={index}
                                    >

                                        <View key={i} style={[tailwind`m-2`, { alignItems: 'center', backgroundColor: (index + 1 > card_colors.length) ? "#" + card_colors[index % card_colors.length] : "#" + card_colors[index] }]}>
                                            {/* <View style={tailwind`mt-1 w-full bg-slate-300 rounded-full h-2 dark:bg-gray-700`}>
                                                <View style={[tailwind`bg-green-600 h-2 rounded-full`, { width: (item.progress) ? item.progress + "%" : 0 }]}>

                                                </View>
                                            </View>
                                            <Text style={tailwind`text-[10px] text-center`}>{(item.progress) ? item.progress : 0}%</Text> */}

                                            <Image
                                                source={{ uri: unit.lessons[0]?.lesson_icon }}
                                                style={[tailwind`rounded mt-2`, styles.img]}
                                            />

                                            <View style={[tailwind`py-2`, styles.unitname]}>
                                                <Text style={tailwind`text-[10px] text-slate-500 w-[100px] text-center mb-2`}> {unit.name_english}</Text>

                                                <Text style={tailwind`text-[10px] text-slate-500 w-[100px] text-center`}> {unit.name}</Text>
                                            </View>
                                        </View>
                                    </Pressable>

                                ))}
                            </ScrollView>
                        </View>
                        }

                        {module.type == "CUSTOM" && <View>
                            <ScrollView
                                horizontal
                                style={{ maxWidth: "100%", }}
                            >
                                {module.levels[current_level_index].units.map((unit, index) => (
                                    <Pressable
                                        onPress={() => handleRedirectToQuestions(unit, module, index)}
                                        key={index}
                                    >
                                        <View key={i} style={[tailwind`m-2`, { alignItems: 'center', backgroundColor: (index + 1 > card_colors.length) ? "#" + card_colors[index % card_colors.length] : "#" + card_colors[index] }]}>
                                            {/* <View style={tailwind`mt-1 w-full bg-slate-300 rounded-full h-2 dark:bg-gray-700`}>
                                                <View style={[tailwind`bg-green-600 h-2 rounded-full`, { width: (item.progress) ? item.progress + "%" : 0 }]}>

                                                </View>
                                            </View>
                                            <Text style={tailwind`text-[10px] text-center`}>{(item.progress) ? item.progress : 0}%</Text> */}

                                            <Image
                                                source={{ uri: unit.lessons[0]?.lesson_icon }}
                                                style={[tailwind`rounded mt-2`, styles.img]}
                                            />

                                            <View style={[tailwind`py-2`, styles.unitname_custom]}>
                                                {/* <Text style={tailwind`text-[10px] text-slate-500 w-[100px] text-center mb-2`}> {unit.name_english}</Text> */}

                                                <Text style={tailwind`text-[10px] text-slate-500 w-[100px] text-center`}> {unit.name}</Text>
                                            </View>
                                        </View>
                                    </Pressable>

                                ))}
                            </ScrollView>
                        </View>
                        }
                    </View>

                ))}
            </View>
            }
            {
                modules.length == 1 &&
                <View>
                    <Text style={tailwind`font-bold mt-5 mb-2 text-[18px]`}>
                        {single_module.type} {single_module.type == `GRAMMAR` ? `UNITS` : `LESSONS`}
                    </Text>

                    <FlatList
                        data={single_module.levels[current_level_index].units}
                        numColumns={3}
                        style={{
                            flex: 1,
                            flexDirection: "row",
                            flexWrap: "wrap"
                        }}
                        renderItem={({ item, index }) =>


                            <Pressable
                                onPress={() => handleRedirectToQuestions(item, single_module, index)}
                                style={[tailwind`shadow-md`, styles.item, { backgroundColor: (index + 1 > card_colors.length) ? "#" + card_colors[index % card_colors.length] : "#" + card_colors[index] }]}

                            >
                                {/* <View style={[tailwind`w-full px-5`, { flexDirection: 'row', alignItems: 'center' }]}>
                                    <View style={[tailwind`mt-1 bg-slate-300 rounded-full h-2 dark:bg-gray-700`, { flex: 1, flexDirection: 'row' }]}>
                                        <View style={[tailwind`bg-green-600 h-2 rounded-full`, { width: "0%" }]}>
                                        </View>
                                    </View>
                                    <View style={[{ justifyContent: 'space-evenly', marginVertical: 10 }]}>
                                        <Text style={tailwind`text-[10px] text-center pl-1`}>0%</Text>
                                    </View>
                                </View> */}

                                <View style={[tailwind`w-full my-2`, { alignItems: 'center' }]}>
                                    {single_module.type == `GRAMMAR` && <Image
                                        source={{ uri: item.lessons[0].lesson_background_image }}
                                        style={[tailwind`rounded p-2`, styles.img]}
                                    />}

                                    {(single_module.type == `CUSTOM` || single_module.type == `CONVERSATION`) && <Image
                                        source={{ uri: item.lessons[0]?.lesson_icon }}
                                        style={[tailwind`rounded p-2`, styles.img]}
                                    />}

                                    <Text
                                        style={tailwind`capitalize text-slate-500 w-full text-[10px] text-center mt-2 justify-center`}
                                    >
                                        {item.name}
                                    </Text>

                                </View>
                            </Pressable>
                        }
                        keyExtractor={(item, index) => `key-${index}`}
                    />
                </View>
            }
        </Layout >
    );
}

const styles = StyleSheet.create({
    unitname: {
        minHeight: 100
    },
    img: {
        resizeMode: "cover",
        width: 50,
        height: 50
    },
    unitname_custom: {
        minHeight: 50
    },
    item: {
        margin: 5,
        padding: 5,
        // height: 100,
        width: (wd - 70) / 3,
        textAlign: "center",
        alignItems: 'center',
        // justifyContent: 'center',
        // textAlignVertical: "center",

        // backgroundColor: "color: rgb(241 245 249)"
    }
});
