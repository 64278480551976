import React, { useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Login from "./screens/auth/login/login";
import Otp from "./screens/auth/otp/otp";
import CreatePassword from "./screens/auth/create-pass/create-pass";
import useUser from "./context/user";
import Dashboard from "./screens/dashboard/screens/home/home";
import { View } from "react-native";
import Classes from "./screens/dashboard/screens/classes/classes";
import Profile from "./screens/dashboard/screens/profile/profile";
import NoteView from "./screens/dashboard/screens/home/classes/upcoming_section/notes/note_view_container";
import UserProfile from "./screens/dashboard/screens/profile/partials/user_profile";
import LinkTeacher from "./screens/dashboard/screens/profile/partials/link_teacher";
import Notifications from "./screens/dashboard/screens/notifications/notifications";
import * as SplashScreen from "expo-splash-screen";
import Chats from "./screens/dashboard/screens/chats/chats";
import ChatContainer from "./screens/dashboard/screens/chats/chat/chat_container";
import Recording from "./screens/dashboard/screens/recording/recording";

import Courses from "./screens/dashboard/screens/courses/courses";
import MultipleSourceLanguages from "./screens/dashboard/screens/courses/partials/multiple_sources_courses";
import Modules from "./screens/dashboard/screens/courses/partials/modules";
import Lessons from "./screens/dashboard/screens/courses/partials/lessons";
import Questions from "./screens/dashboard/screens/courses/partials/questions";
import Feedback from "./screens/dashboard/screens/courses/partials/feedback";
import LessonFinished from "./screens/dashboard/screens/courses/partials/lesson_finished";
import CourseCompleted from "./screens/dashboard/screens/courses/partials/course_completed";
import Quiz from "./screens/dashboard/screens/quizzes/quiz";
import QuizFeedback from "./screens/dashboard/screens/quizzes/quiz_feedback";
import QuizCompleted from "./screens/dashboard/screens/quizzes/quiz_completed";


SplashScreen.preventAutoHideAsync();

const Multibhashi = () => {
  const Stack = createNativeStackNavigator();

  const { appState } = useUser();

  useEffect(() => {
    if (appState === "loading") {
      SplashScreen.preventAutoHideAsync();
    }
    if (appState !== "loading") {
      SplashScreen.hideAsync();
    }
  }, [appState]);

  const initialRouteName =
    appState === "loading"
      ? "Loading"
      : appState === "auth"
        ? "Login"
        : appState === "onboarding"
          ? "Onboarding"
          : "Home";

  return (
    <NavigationContainer
      documentTitle={{
        formatter: () => `Multibhashi`,
      }}
    >
      <Stack.Navigator
        initialRouteName={initialRouteName}
        screenOptions={{
          headerShown: false,
          contentStyle: { backgroundColor: "#fff" },
          animation: "fade",
        }}
      >
        {appState === "loading" && (
          <Stack.Screen name="Loading" component={Empty} />
        )}

        {appState === "auth" && (
          <>
            <Stack.Screen name="Login" component={Login} />
            <Stack.Screen name="OTP" component={Otp} />
            <Stack.Screen name="CreatePass" component={CreatePassword} />
          </>
        )}

        {appState === "dashboard" && (
          <>
            <Stack.Screen name="Home" component={Dashboard} />
            <Stack.Screen name="Classes" component={Classes} />
            <Stack.Screen name="Profile" component={Profile} />
            <Stack.Screen name="NoteView" component={NoteView} />
            <Stack.Screen name="UserProfile" component={UserProfile} />
            <Stack.Screen name="LinkTeacher" component={LinkTeacher} />
            <Stack.Screen name="Notifications" component={Notifications} />
            <Stack.Screen name="Chats" component={Chats} />
            <Stack.Screen name="Chat" component={ChatContainer} />
            <Stack.Screen name="Recording" component={Recording} />
            <Stack.Screen name="Courses" component={Courses} />
            <Stack.Screen name="MultipleSourceLanguages" component={MultipleSourceLanguages} />
            <Stack.Screen name="Modules" component={Modules} />
            <Stack.Screen name="Lessons" component={Lessons} />
            <Stack.Screen name="Questions" component={Questions} />
            <Stack.Screen name="Feedback" component={Feedback} />
            <Stack.Screen name="LessonFinished" component={LessonFinished} />
            <Stack.Screen name="CourseCompleted" component={CourseCompleted} />
            <Stack.Screen name="Quizzes" component={Quiz} />
            <Stack.Screen name="QuizFeedback" component={QuizFeedback} />
            <Stack.Screen name="QuizCompleted" component={QuizCompleted} />
          </>
        )}
      </Stack.Navigator>
      {/* <Login /> */}
    </NavigationContainer>
  );
};

export default Multibhashi;
export const Empty = () => <View></View>;
