import React from "react";
import { TouchableOpacity, View } from "react-native";
import tailwind from "twrnc";
import { AntDesign } from "@expo/vector-icons";
import Text from "../../../components/Text";
import { useNavigation } from "@react-navigation/native";
import useChat from "../screens/chats/chat/context";

const SecondaryHeader = ({ title = "", isChat = false }) => {
  const { goBack } = useNavigation();

  const { setReport } = useChat();

  return (
    <View
      style={tailwind` px-5 flex flex-row h-full items-center justify-between`}
    >
      {isChat ? (
        <View
          style={tailwind`flex items-center justify-between flex-row w-full`}
        >
          <View style={tailwind`flex items-center flex-row gap-x-2`}>
            <TouchableOpacity onPress={goBack}>
              <AntDesign name="left" size={24} color="black" />
            </TouchableOpacity>
            <Text style={tailwind`font-semibold text-[16px]`}>{title}</Text>
          </View>

          <TouchableOpacity onPress={() => setReport((prev) => !prev)}>
            <Text style={tailwind`text-blue-600 font-semibold`}>Report</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <>
          <TouchableOpacity onPress={goBack}>
            <AntDesign name="left" size={24} color="black" />
          </TouchableOpacity>
          <Text style={tailwind`font-semibold text-[18px]`}>{title}</Text>
        </>
      )}

      <View style={tailwind`w-[20px]`} />
    </View>
  );
};

export default SecondaryHeader;
