import React from "react";
import { View } from "react-native";
import { Bubble } from "react-native-gifted-chat";
import tailwind from "twrnc";
import Text from "../../../../../../components/Text";
import moment from "moment";
import { Ionicons } from "@expo/vector-icons";

const FlaggedMessage = (props) => {
  const { currentMessage: message } = props;

  return (
    <View
      style={tailwind`flex flex-col ${
        props.position === "left" ? "items-start" : "items-end"
      }`}
    >
      <Bubble
        {...props}
        wrapperStyle={{
          left: tailwind`bg-white border border-[#FD891E] px-3 py-1`,
          right: tailwind`bg-[#FFE7D0] px-5 py-2`,
        }}
        renderTime={() => {
          return (
            <Text style={tailwind`text-[10px] mt-1 text-[#8B8B8B]`}>
              {moment.unix(message.time_seconds).format("hh:mm a")}
            </Text>
          );
        }}
        renderMessageText={() => (
          <Text style={tailwind`text-[#8B8B8B]`}>{message.text}</Text>
        )}
      />

      <View
        style={tailwind`flex flex-row items-center gap-x-1 ${
          props.position === "left" ? "" : "flex-row-reverse"
        }`}
      >
        <Ionicons name="flag" size={20} color="#FD891E" />
        <Text
          style={tailwind`max-w-[180px] mb-1 text-[#FD891E] text-[12px] mt-1 ${
            props.position === "left" ? "" : "text-right"
          }`}
        >
          {message.flagged_by === "AUTO"
            ? "This message has been flagged by the system. Please wait for the Admin to review it"
            : "This message has been reported"}
        </Text>
      </View>
    </View>
  );
};

export default FlaggedMessage;
