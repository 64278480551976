import Toast from "react-native-toast-message";
const Alert = {
  alert: (text) => {
    console.log("alert toast")
    Toast.show({
      type: "error",
      text1: text,
      position: "bottom",
      bottomOffset: 50,
      visibilityTime: 5000
    });
  },

  alert1: (text) => {
    console.log("alert toast")
    Toast.show({
      type: "error",
      // text1: "Error",
      text2: text,
      position: "bottom",
      bottomOffset: 50,
      visibilityTime: 9000,
      text1NumberOfLines: 5,
      text2NumberOfLines: 10,
      text2Style: { fontSize: 10, color: "#101211" },
      style: {
        height: undefined,
        minHeight: 70,
        paddingVertical: 10,
        paddingHorizontal: 0,

      },
    });
  },

  success: (text) => {
    Toast.show({
      type: "success",
      text1: text,
      position: "bottom",
      bottomOffset: 100,
      visibilityTime: 5000
    });
  },
};

export default Alert;
