import { Ionicons } from "@expo/vector-icons";
import React, { useState } from "react";
import { ActivityIndicator, TouchableOpacity } from "react-native";
import tailwind from "twrnc";
import { TEACHERS } from "../../../../api/instance";
import useClasses from "../../../../context/classes";
import useUser from "../../../../context/user";
import { useNavigation } from "@react-navigation/native";
import Alert from "../../../../utils/alert";
import Text from "../../../../components/Text";

const ChatButton = ({ class_user, teacher }) => {
    const [loading, setLoading] = useState(false);

    const { navigate } = useNavigation();

    const { user } = useUser();

    const createChat = async () => {
        setLoading(true);
        let params = {
            teacher_id: teacher.id,
            user_phone: class_user.phone,
        };

        try {
            const { data } = await TEACHERS.post(`/conversation/create`, params);
            setLoading(false);

            navigate("Chat", data.data);
        } catch (error) {
            Alert.alert("An error occured");
            setLoading(false);
        }
    };

    return (
        <>
            <TouchableOpacity
                disabled={loading}
                onPress={createChat}
                style={tailwind`${`flex flex-row items-center gap-x-1`
                    }`}
            >
                <TouchableOpacity
                    disabled={loading}
                    onPress={createChat}
                    style={tailwind`${""
                        } `}
                >
                    {loading ? (
                        <ActivityIndicator />
                    ) : (
                        <Ionicons
                            name="chatbox-ellipses-outline"
                            size={18}
                            color="#2096F3"
                        />
                    )}
                </TouchableOpacity>
            </TouchableOpacity>
        </>
    );
};

export default ChatButton;
