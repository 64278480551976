import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList,
    ActivityIndicator
} from "react-native";
import React, { useState, useEffect } from "react";
import tailwind from "twrnc";
import { height, isWeb, width } from "../../../../../constants/constants";

import { useNavigation, useNavigationParam } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";

import { API } from "../../../../../api/instance";
import Layout from "../../../layout";
import PageHeader from "../../../headers/page.header";
let wd = width <= 500 ? width : 499.99
import useUser from "../../../../../context/user";
import useQuestion from "./common/context";

export default function MultipleSourceCourses(props) {
    const { navigate } = useNavigation();
    let { user } = useUser();
    const { setSelectedCourse, setModuleStates, setAllModuleStates } = useQuestion();

    const headerHeight = isWeb ? 60 : 100;
    const headerHeight1 = isWeb ? 50 : 50;

    let current_course = props.route?.params?.obj?.current_course
    let back_route = props.route?.params?.obj?.from_page
    let [courses, setCourses] = useState([]);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        getCoursesByTargetLanguage();
        setAllModuleStates([])
        setSelectedCourse(null)
        setModuleStates(null)
    }, []);

    async function getCoursesByTargetLanguage() {
        setLoading(true)
        await API.get(`/courses/targetlanguage/${current_course.target_language}`)
            .then((response) => {
                setCourses(response.data.data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error.response.data);
                setLoading(false);
            });
    }

    let handleRedirect = async (course) => {
        user.current_course_id = course._id
        user.current_course_state = {
            course_id: course._id,
            course_name: course.display_text,
            sourceLanguage: course.source_language,
            target_language: course.target_language
        }

        let course_states = (user.course_states && user.course_states.length > 0) ? user.course_states : []
        if (course_states.length == 0) {
            user.course_states = []
            user.course_states.push({
                course_id: course._id,
                course_name: course.display_text,
                sourceLanguage: course.source_language,
                target_language: course.target_language
            })
        } else {
            let obj = user.course_states.find(o => o.course_id === course._id);
            if (!obj) {
                user.course_states.push({
                    course_id: course._id,
                    course_name: course.display_text,
                    sourceLanguage: course.source_language,
                    target_language: course.target_language
                })
            }
        }

        updateUserCourse(user)

        let { data: results } = await getModuleState(course._id)

        if (results) {
            setSelectedCourse(course)

            navigate("Modules", {
                from_page: "Home"
            })
            if (results?.data?.length == 0) {
                for (let index = 0; index < course.modules.length; index++) {
                    let module = course.modules[index]

                    let unit_states = []
                    let lesson_states = []

                    for (let index1 = 0; index1 < module.levels[0].units.length; index1++) {
                        let unit = module.levels[0].units[index1]
                        let data = {
                            is_current: (index1 == 0) ? true : false,
                            is_finished: false,
                            is_skipped: false,
                            is_unlocked: true,
                            unit_id: unit._id
                        }
                        unit_states.push(data)
                        let lessons = unit.lessons
                        for (let index2 = 0; index2 < lessons.length; index2++) {
                            let lesson = lessons[index2]
                            let lsn_data = {
                                is_current: (index1 == 0 && index2 == 0) ? true : false,
                                is_finished: false,
                                is_skipped: false,
                                is_unlocked: true,
                                lesson_id: lesson._id
                            }
                            lesson_states.push(lsn_data)
                        }
                    }

                    let data = {
                        course_id: course._id,
                        module_type: module.type,
                        user_id: user._id,
                        module_state: {
                            current_lesson_id: module.levels[0].units[0].lessons[0]._id,
                            current_level_id: module.levels[0]._id,
                            current_unit_id: module.levels[0].units[0]._id,
                            module_type: module.type,
                            lesson_states: lesson_states,
                            level_states: [{
                                is_current: true,
                                is_finished: false,
                                is_skipped: false,
                                is_unlocked: true,
                                level_id: module.levels[0]._id
                            }],
                            unit_states: unit_states
                        }
                    }
                    updateModuleState(data)
                }
            }
        }
    }

    async function getModuleState(course_id) {
        let params = {
            course_id: course_id,
        };
        return await API.get(`/course_module_state/${user._id}`, { params })
    }

    async function updateModuleState(data) {
        await API.post(`/module_state`, data)
            .then((response) => {
                let res = response.data
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function updateUserCourse(data) {
        await API.put(`/user/${user._id}`, data)
            .then((response) => {
                let res = response.data
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Layout
            HeaderContent={
                <PageHeader backRoute={back_route} pageTitle={`Learn ${current_course.target_language[0].toUpperCase() + current_course.target_language.slice(1)} On Your Own`} />
            }
        // scrollView={false}
        >
            <View style={tailwind`flex flex-row w-full my-2 text-center`}>
                <View>
                    <Image
                        source={require("../../../../../../assets/indian_flag.png")}
                        style={tailwind`w-[22px] h-[22px]`}
                    />
                </View>
                <Text style={tailwind`ml-1 text-base`}>Select Your Mother Tongue To Learn {current_course.target_language[0].toUpperCase() + current_course.target_language.slice(1)}</Text>
            </View>

            {
                !loading && <FlatList
                    data={courses}
                    numColumns={3}
                    style={{
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap"
                    }}
                    renderItem={({ item, index }) =>
                        <Pressable
                            onPress={() => handleRedirect(item)}
                            style={styles.item}
                            key={index}
                        >
                            <View style={{
                                textAlign: "center",
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Image
                                    source={{ uri: item?.image }}
                                    style={[tailwind`rounded rounded-full`, styles.img]}
                                />

                                <Text
                                    style={tailwind`capitalize text-slate-500 w-full text-[16px] text-center mt-2 font-semibold justify-center`}
                                >
                                    {item.source_language}
                                </Text>
                            </View>
                        </Pressable>
                    }
                    keyExtractor={(item) => item._id}
                />
            }

            {loading &&
                <View
                    style={[tailwind`h-full w-full`, {
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }]}>
                    <View
                        style={[tailwind`h-full`, {
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }]}>
                        <ActivityIndicator size="large" color="#60a5fa" />
                    </View>
                </View >

            }

        </Layout >
    );
}

const styles = StyleSheet.create({
    img: {
        // resizeMode: "cover",
        width: 100,
        height: 100
    },
    item: {
        // fontSize: 25,
        // backgroundColor: "blue",
        // color: "#fff",
        margin: 5,
        padding: 5,
        // height: 120,
        width: (wd - 70) / 3,
        textAlignVertical: "center",
        textAlign: "center",
        alignItems: 'center',
        justifyContent: 'center',
    }
});
