import React from "react";
import { View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../../components/Text";
import moment from "moment";
import useUser from "../../../../../../context/user";

const ChatBubbleWeb = (props) => {
  const { user } = useUser();

  const { currentMessage: message } = props;
  const isSender = message.sender_id === user._id;
  return (
    <View
      style={tailwind` ${
        !isSender ? "bg-[#F0F0F0]" : "bg-[#0084FF]"
      } rounded-lg px-3 py-2 max-w-[350px]`}
    >
      <Text style={tailwind`${isSender ? "text-[#ffff]" : "text-[#000]"}`}>
        {message.message}
      </Text>

      <Text
        style={tailwind.style(
          `text-[10px] -ml-2 mt-1 ${
            !isSender ? "text-[#AAAAAA]" : "text-white"
          }`
        )}
      >
        {" "}
        {moment.unix(message.time_seconds).format("hh:mm a")}
      </Text>
    </View>
  );
};

export default ChatBubbleWeb;
