import { createContext, useContext, useEffect, useState } from "react";
import useUser from "./user";
import { TEACHERS } from "../api/instance";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../lib/firebase";
// import samplecompleted from "./sample_completed.json";
// import sampleupcoming from "./sample_upcoming.json";

const ClassesContext = createContext();

const useClasses = () => useContext(ClassesContext);

export const ClassesContextProvider = ({ children }) => {
  const { user, isTeacher } = useUser();

  const [completed, setCompleted] = useState(null);
  const [upcoming, setUpcoming] = useState(null);

  const [chatUnread, setChatUnread] = useState(0);
  const [notificationsUnread, setNotificationsUnread] = useState(0);

  const [expiredSubscriptions, setExpiredSubscriptions] = useState(null);

  const getNotificationsUnread = async () => {
    const path = isTeacher
      ? `/messages/status/teachers/${user._id}`
      : `/messages/status/users`;


    if (isTeacher) {
      const { data } = await TEACHERS.get(path);
      setNotificationsUnread(data.data.unread);
    } else {
      let params = {
        phone: user.phone
      }
      const { data } = await TEACHERS.post(path, params);
      setNotificationsUnread(data.data.unread);
    }
  };

  const getExpiredSubscriptions = async () => {
    try {
      const { data } = await TEACHERS.get(
        `/users/${user.phone}/subscriptions/expire`
      );
      setExpiredSubscriptions(data.data);
    } catch (error) {
      console.log("Error in getExpiredSubscriptions");
    }
  };

  const getChatUnread = async () => {
    const conversationsQuery = query(
      collection(db, "Conversations"),
      where(
        isTeacher ? "teacher_app_id" : "user_phone",
        "==",
        isTeacher ? user._id : user.phone
      )
    );

    onSnapshot(
      conversationsQuery,
      (querySnapshot) => {
        let totalUnreadCount = 0; // Initialize the total unread count
        const conversations = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          conversations.push({ id: doc.id, ...data });

          // Add to totalUnreadCount based on isTeacher flag
          if (isTeacher) {
            totalUnreadCount += data.teacher_unread_count || 0;
          } else {
            totalUnreadCount += data.student_unread_count || 0;
          }
        });

        setChatUnread(totalUnreadCount);
      },
      (error) => {
        console.error("Error listening to conversations: ", error);
      }
    );
  };

  const getCompeltedClasses = async () => {
    try {
      let params = {
        phone: user.phone
      }
      const { data } = await TEACHERS.post(`/users/classes/completed`, params);

      setCompleted(data.data);

      // setCompleted(samplecompleted);
    } catch (error) {
      console.warn(
        "Error occured while fetching completed classes",
        JSON.stringify(error)
      );
    }
  };

  const getUpcomingSession = async () => {
    const path = isTeacher
      ? `/teacher/session/upcoming?app_user_id=${user._id}`
      : `/session/upcoming`;

    try {
      if (isTeacher) {
        const { data } = await TEACHERS.get(path);
        setUpcoming(data.data);
      } else {
        let params = {
          phone: user.phone
        }
        const { data } = await TEACHERS.post(path, params);
        setUpcoming(data.data);
      }


      // setUpcoming(sampleupcoming);
    } catch (error) {
      console.warn(
        "Error occured while fetching completed classes",
        JSON.stringify(error)
      );
    }
  };

  useEffect(() => {
    if (user) {
      getCompeltedClasses();
      getUpcomingSession();
      getChatUnread();
      getNotificationsUnread();
      getExpiredSubscriptions();
    }
  }, [user]);

  return (
    <ClassesContext.Provider
      value={{
        completed,
        upcoming,
        getUpcomingSession,
        isTeacher,
        chatUnread,
        notificationsUnread,
        expiredSubscriptions,
        setExpiredSubscriptions,
      }}
    >
      {children}
    </ClassesContext.Provider>
  );
};

export default useClasses;
