import { View, Pressable } from "react-native";
import Modal from "../../../../../components/modal";
import Text from "../../../../../components/Text";
import tailwind from "twrnc";
import { useState } from "react";
import moment from "moment";
import { AntDesign } from "@expo/vector-icons";

const LessonDialog = ({ open, setOpen, module, unit, selectLesson }) => {
    const [loading, setLoading] = useState(false);
    let handleSelectLesson = (lesson, index) => {
        selectLesson(lesson, index);
        setOpen(false);
    };

    return (
        <Modal visible={open} setVisible={setOpen} maxHeight={500}>
            <View>
                <View style={tailwind`flex flex-row justify-between items-center`}>
                    <Text style={tailwind`font-bold text-[16px] text-blue-500`}>{unit.name_english}</Text>

                    <AntDesign
                        onPress={() => setOpen(false)}
                        name="close"
                        size={24}
                        color="black"
                    />
                </View>

                <View style={tailwind`mt-4`}>
                    {unit.lessons.map((lesson, index) => (
                        <Pressable
                            onPress={() => handleSelectLesson(lesson, index)}
                            style={[tailwind`py-3 border-b border-slate-500`, { flex: 1 }]}
                            key={lesson._id}
                        >
                            <Text key={index} style={tailwind`text-slate-500`}>{lesson.name_english}</Text>

                            {/* <View style={[{ flexDirection: 'row', alignItems: 'center' }]}>
                                <View style={[tailwind`mt-1 bg-slate-300 rounded-full h-2 dark:bg-gray-700`, { flex: 1, flexDirection: 'row' }]}>
                                    <View style={[tailwind`bg-green-600 h-2 rounded-full`, { width: "0%" }]}>

                                    </View>
                                </View>
                                <View style={[{ justifyContent: 'space-evenly', marginVertical: 10 }]}>
                                    <Text style={tailwind`text-[10px] text-center`}>0%</Text>
                                </View>
                            </View> */}
                        </Pressable>
                    ))}

                    {/* {loading && <Text>Loading All Notes...</Text>} */}
                </View>
            </View>
        </Modal >
    );
};

export default LessonDialog;
