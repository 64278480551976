import React, { useState, useEffect } from "react";
import { collection, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../lib/firebase";
import moment from "moment";
import ChatItem from "./chat_item";
import useUser from "../../../../../context/user";

const Chat = ({ chat }) => {
  const { isTeacher, user } = useUser();
  const [chats, setChats] = useState([]);

  useEffect(() => {
    // Subscribe to message updates
    const unsubscribe = onSnapshot(
      collection(db, `Conversations/${chat.id}/Messages`),
      (snapshot) => {
        const messages = snapshot.docs.map((doc) => ({
          ...doc.data(),
          message_id: doc.id,
        }));
        setChats(
          messages.map((message) => ({
            ...message,
            _id: message.message_id,
            text:
              message.sender_id === user._id
                ? message.message
                : message.is_deleted
                ? "Message Deleted"
                : message.is_flagged
                ? "Messsage Flagged"
                : message.message,

            // message.is_flagged
            //   ?
            //     ? message.message
            //     : "Messsage Flagged"
            //   : message.message,
            createdAt: moment.unix(message.send_time.seconds).toDate(),
            time_seconds: message.send_time.seconds,

            user: {
              _id: message.sender_id,
              name: message.sender,
            },
          }))
        );
      }
    );

    // Return the unsubscribe function to stop listening when the component unmounts
    return unsubscribe;
  }, [chat.id]);

  useEffect(() => {
    // Function to reset unread count
    const resetUnreadCount = async () => {
      const fieldToUpdate = isTeacher
        ? "teacher_unread_count"
        : "student_unread_count";
      const chatRef = doc(db, `Conversations/${chat.id}`);

      try {
        await updateDoc(chatRef, {
          [fieldToUpdate]: 0,
        });
      } catch (error) {
        console.error("Error updating unread count: ", error);
      }
    };

    resetUnreadCount();
  }, [chat.id]);

  return (
    <>
      <ChatItem chats={chats} chat={chat} />
    </>
  );
};

export default Chat;
