import { createContext, useContext, useEffect, useState } from "react";
import { API } from "../api/instance";
import LocalStorage from "../utils/localStorage";
import Alert from "../utils/alert";

const UserContext = createContext();

const useUser = () => useContext(UserContext);

export const UserContextProvider = ({ children }) => {
  const [appState, setAppState] = useState("loading");
  const [user, setUser] = useState(null);

  const isTeacher = user?.is_live_teacher || user?.is_teacher;

  const logout = async () => {
    await LocalStorage.deleteItem("user");
    checkIfLoggedIn();
  };

  const checkIfLoggedIn = async () => {
    setAppState("loading");

    try {
      const result = await LocalStorage.getItem("user");

      if (result) {
        const parsedResult = await JSON.parse(result).data;

        const { data } = await API.get(`/user/${parsedResult._id}`);
        await LocalStorage.setItem("user", JSON.stringify(data));
        setUser(data.data);
        return setAppState("dashboard");
      }

      return setAppState("auth");
    } catch (error) {
      console.log(error);
      Alert.alert("An error occured");
    }
  };

  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  // console.log(user);

  return (
    <UserContext.Provider
      value={{ appState, checkIfLoggedIn, logout, user, isTeacher }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default useUser;
