import { createContext, useContext, useState } from "react";

const ChatContext = createContext();

const useChat = () => useContext(ChatContext);

export const ChatContextProvider = ({ children }) => {
  const [fileSelected, setFileSelected] = useState(null);

  const [report, setReport] = useState(false);

  const [messageReportIds, setMessageReportIds] = useState([]);

  return (
    <ChatContext.Provider
      value={{
        fileSelected,
        setFileSelected,
        messageReportIds,
        setMessageReportIds,
        report,
        setReport,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default useChat;
