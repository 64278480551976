import {
    View,
    Text,
    TouchableOpacity,
    TextInput,
    StyleSheet,
    Image,
    ActivityIndicator,
    FlatList,
    ScrollView,
    Pressable
} from "react-native";
import React, { useState, useEffect } from "react";
import tailwind from "twrnc";
import { AntDesign } from "@expo/vector-icons";
import { TEACHERS } from "../../../../../api/instance";
import { API } from "../../../../../api/instance";
import { height, isWeb } from "../../../../../constants/constants";
import useUser from "../../../../../context/user";
import { useNavigation } from "@react-navigation/native";
import useQuestion from "../partials/common/context";

export default function Foreign() {
    const { navigate } = useNavigation();
    const { setSelectedCourse, setModuleStates, setAllModuleStates } = useQuestion();

    let [loading, setLoading] = useState(true);
    let [courses, setCourses] = useState([]);
    let [rawCourses, setRawCourses] = useState({});
    let { user } = useUser();

    useEffect(() => {
        getCourses();
        setAllModuleStates([])
        setSelectedCourse(null)
        setModuleStates(null)
    }, []);

    async function getCourses() {
        setLoading(true);
        let params = {
            language: "foreign",
        };
        let user_id = user._id

        await API.get(`/learnown/courses/${user_id}`, { params })
            .then((response) => {
                let res = response.data.data
                setRawCourses(res)
                let obj_keys = Object.keys(res)
                let courses = []
                for (let index = 0; index < obj_keys.length; index++) {
                    let ky = obj_keys[index]
                    let items = res[ky]
                    let progress = 0;
                    let total_lessons = 0
                    let crs = null

                    if (items.length > 1) {
                        crs = items[0]
                        for (let index1 = 0; index1 < items.length; index1++) {
                            let item = items[index1]
                            progress = progress + item.progress
                            total_lessons = total_lessons + item.total_lessons
                        }
                        crs.progress = progress / items.length
                        crs.total_lessons = total_lessons
                        courses.push(crs)
                    } else {
                        courses.push(items[0])
                    }
                }
                setCourses(courses)
                setLoading(false);
            })
            .catch((error) => {
                console.log(error.response.data);
                setLoading(false);
            });
    }

    let handleRedirect = async (item) => {
        let crs = rawCourses[item.target_language]
        if (crs.length > 1) {
            let obj = {
                current_course: item,
                courses: crs,
                from_page: "Courses"
            }
            navigate("MultipleSourceLanguages", { obj })
        } else {
            let course = item

            user.current_course_id = course._id
            user.current_course_state = {
                course_id: course._id,
                course_name: course.display_text,
                sourceLanguage: course.source_language,
                target_language: course.target_language
            }

            let course_states = (user.course_states && user.course_states.length > 0) ? user.course_states : []
            if (course_states.length == 0) {
                user.course_states = []
                user.course_states.push({
                    course_id: course._id,
                    course_name: course.display_text,
                    sourceLanguage: course.source_language,
                    target_language: course.target_language
                })
            } else {
                let obj = user.course_states.find(o => o.course_id === course._id);
                if (!obj) {
                    user.course_states.push({
                        course_id: course._id,
                        course_name: course.display_text,
                        sourceLanguage: course.source_language,
                        target_language: course.target_language
                    })
                }
            }

            updateUserCourse(user)

            let { data: results } = await getModuleState(course._id)
            if (results) {
                setSelectedCourse(course)

                navigate("Modules", {
                    from_page: "Home"
                })

                if (results?.data?.length == 0) {
                    for (let index = 0; index < course.modules.length; index++) {
                        let module = course.modules[index]

                        let unit_states = []
                        let lesson_states = []

                        for (let index1 = 0; index1 < module.levels[0].units.length; index1++) {
                            let unit = module.levels[0].units[index1]
                            let data = {
                                is_current: (index1 == 0) ? true : false,
                                is_finished: false,
                                is_skipped: false,
                                is_unlocked: true,
                                unit_id: unit._id
                            }
                            unit_states.push(data)
                            let lessons = unit.lessons
                            for (let index2 = 0; index2 < lessons.length; index2++) {
                                let lesson = lessons[index2]
                                let lsn_data = {
                                    is_current: (index1 == 0 && index2 == 0) ? true : false,
                                    is_finished: false,
                                    is_skipped: false,
                                    is_unlocked: true,
                                    lesson_id: lesson._id
                                }
                                lesson_states.push(lsn_data)
                            }
                        }

                        let data = {
                            course_id: course._id,
                            module_type: module.type,
                            user_id: user._id,
                            module_state: {
                                current_lesson_id: module.levels[0].units[0].lessons[0]._id,
                                current_level_id: module.levels[0]._id,
                                current_unit_id: module.levels[0].units[0]._id,
                                module_type: module.type,
                                lesson_states: lesson_states,
                                level_states: [{
                                    is_current: true,
                                    is_finished: false,
                                    is_skipped: false,
                                    is_unlocked: true,
                                    level_id: module.levels[0]._id
                                }],
                                unit_states: unit_states
                            }
                        }
                        updateModuleState(data)
                    }
                }
            }
        }
    }

    async function getModuleState(course_id) {
        let params = {
            course_id: course_id,
        };
        return await API.get(`/course_module_state/${user._id}`, { params })
    }

    async function updateModuleState(data) {
        await API.post(`/module_state`, data)
            .then((response) => {
                let res = response.data
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function updateUserCourse(data) {
        await API.put(`/user/${user._id}`, data)
            .then((response) => {
                let res = response.data
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <View >
            {!loading &&
                <FlatList
                    data={courses}
                    numColumns={3}
                    renderItem={({ item }) =>
                        <Pressable
                            onPress={() => handleRedirect(item)}
                            style={styles.item}
                        >
                            <View style={[tailwind`m-2 text-center`]}>
                                <Image
                                    source={(item.target_language == "english") ? require("../../../../../../assets/english.png") : { uri: item?.image }}
                                    style={[tailwind` rounded rounded-full`, styles.img]}
                                />
                                <View style={[tailwind`text-center justify-center`]}>
                                    <Text
                                        style={tailwind`capitalize text-slate-500 w-full text-[16px] text-center mt-2 font-semibold justify-center`}
                                    >
                                        {item.target_language}
                                    </Text>
                                </View>

                                <View style={tailwind`text-center justify-center mx-5`}>
                                    <View style={tailwind`w-full`}>
                                        <View style={tailwind`mt-1 bg-slate-300 rounded-full h-2 dark:bg-gray-700`}>
                                            <View style={[tailwind`bg-green-600 h-2 rounded-full`, { width: (item.progress) ? item.progress + "%" : 0 }]}>

                                            </View>
                                        </View>
                                        <Text style={tailwind`text-[10px] text-center`}>{(item.progress) ? item.progress : 0}%</Text>
                                    </View>
                                </View>
                            </View>
                        </Pressable>
                    }
                    keyExtractor={(item, i) => i}
                    style={[styles.container, { marginBottom: isWeb ? 90 : 50 }]}
                />
            }

            {loading &&
                <View
                    style={[tailwind`h-full`, {
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        top: "10vh"
                    }]}>
                    <View
                        style={[tailwind`h-full`, {
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }]}>
                        <ActivityIndicator size="large" color="#60a5fa" />
                    </View>
                </View >
            }

        </View >
    );
}


const styles = StyleSheet.create({
    img: {
        // resizeMode: "cover",
        width: 100,
        height: 100
    },

    item: {
        // backgroundColor: '#A1A1A1',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        margin: 3,
        height: 170,
        width: 100
    },
});
