import { Image, TouchableOpacity, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../../../components/Text";
import { useNavigation } from "@react-navigation/native";

const NotesLinkItem = ({ note, setOpen }) => {
  const { navigate } = useNavigation();

  const handleClick = async () => {
    navigate("NoteView", { url: note.noteLink.url });

    setOpen(false);
    // try {
    //   setLoading(true);
    //   const { data } = await TEACHERS.get(
    //     `/notes/files/${note.noteLink.id}/url`
    //   );
    //   setOpen(false);
    //   setLoading(false);
    //   Linking.openURL(data.data.url);
    // } catch (error) {
    //   setLoading(false);
    //   Alert.alert("Could not open note. Try again");
    // }

    // navigate("NoteView");
  };

  return (
    <View style={tailwind`flex flex-row justify-between mb-5 items-center`}>
      <View style={tailwind`flex flex-row items-center`}>
        <Image
          style={tailwind`w-[40px] h-[40px]`}
          source={require("../../../../../../../../assets/ic_note_link.png")}
        />
        <Text style={tailwind`w-[180px] text-[12px] ml-3`}>
          {note.noteLink.name}
        </Text>
      </View>

      <TouchableOpacity onPress={handleClick}>
        <Text style={tailwind`text-[12px] font-bold text-[#2196F2]`}>View</Text>
      </TouchableOpacity>
    </View>
  );
};

export default NotesLinkItem;
