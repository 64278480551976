import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList,
    Platform,
    ActivityIndicator,
    Button
} from "react-native";
import React, { useState, useEffect } from "react";
import tailwind from "twrnc";
import { height, isWeb, width } from "../../../../../constants/constants";
import { useNavigation, useNavigationParam } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";

import { API } from "../../../../../api/instance";
import Layout from "../../../layout";
import PageHeader from "../../../headers/page.header";
let wd = width <= 500 ? width : 499.99
import useQuestion from "./common/context";
import moment from "moment";
import useUser from "../../../../../context/user";
import _ from "lodash";

export default function Feedback(props) {
    const { navigate } = useNavigation();
    let { user } = useUser();

    let [correct_lesson_images, setCorrectLessonImages] = useState([
        "HIN_BOY_thumbs_up_.png",
        "HIN_BOY_clap_.png",
        "HIN_GIRL_clap_.png",
        "HIN_GIRL_thumbs_up_.png"
    ]);

    let [incorrect_lesson_images, setIncorrectLessonImages] = useState([
        "HIN_BOY_folded_hands_.png",
        "HIN_BOY_hands_on_waist_.png",
        "HIN_GIRL_folded_hands_.png",
        "HIN_GIRL_hands_on_waist_.png"
    ]);

    const {
        all_module_states,
        module_states,
        module,
        level,
        unit,
        lesson,
        selected_course,
        selected_answer,
        setSelectedAnswer,
        questions,
        setQuestions,
        question_ids,
        setQuestionIds,
        current_question_index,
        setCurrentQuestionIndex,
        answer_result,
        setAnswerResult,
        question,
        setQuestion,
        checked,
        setChecked,
        lesson_start_timestamp,
        setLessonStartTimestamp,
        lesson_end_timestamp,
        setLessonEndTimestamp,
        total_correct_answer,
        setTotalCorrectAnswer,
        total_incorrect_answer,
        setTotalIncorrectAnswer,
        total_question_attempt,
        setTotalQuestionAttempt,
        question_activity,
        setQuestionActivity,
        question_start_timestamp,
        setQuestionStartTimestamp,
        question_end_timestamp,
        setQuestionEndTimestamp,
        total_question,
        setTotalQuestion,
        current_lesson_index,
        current_level_index
    } = useQuestion();

    useEffect(() => {
        let activity = {
            question_id: questions[current_question_index]._id,
            is_correct: (answer_result == "correct") ? true : false,
            question_start_timestamp: +question_start_timestamp,
            question_end_timestamp: +question_end_timestamp
        }
        let arr = question_activity;
        arr.push(activity);
        setQuestionActivity([...arr]);

        if (current_question_index + 1 == questions.length) {
            let data = {
                user_id: user._id,
                course_id: selected_course._id,
                module_id: module._id,
                unit_id: unit._id,
                level_id: level._id,
                lesson_id: lesson._id,
                lesson_start_timestamp: +lesson_start_timestamp,
                lesson_end_timestamp: +moment().format('x'),
                total_questions: +total_question,
                total_correct_answer: +total_correct_answer,
                total_incorrect_answer: +total_incorrect_answer,
                total_question_attempt: +total_question_attempt,
                is_lesson_finised: true,
                questions: question_activity
            }

            storeLessonActivity(data)
            let moduel_state = all_module_states.find(o => o.module_type == module.type);

            if (moduel_state) {
                let lsn_states = moduel_state.module_state.lesson_states

                let find_lsn_index = _.findIndex(lsn_states, function (o) { return o.lesson_id == lesson._id; });

                if (find_lsn_index != -1) {
                    moduel_state.module_state.lesson_states[find_lsn_index].is_current = false
                    moduel_state.module_state.lesson_states[find_lsn_index].is_finished = true

                    if (find_lsn_index + 1 < moduel_state.module_state.lesson_states.length) {
                        let flag = false

                        for (let index = find_lsn_index + 2; index < moduel_state.module_state.lesson_states.length; index++) {
                            let item = moduel_state.module_state.lesson_states[index]
                            if (item.is_current) {
                                flag = true
                                break;
                            }
                        }
                        if (!flag) {
                            moduel_state.module_state.lesson_states[find_lsn_index + 1].is_current = true
                            moduel_state.module_state.current_lesson_id = moduel_state.module_state.lesson_states[find_lsn_index + 1].lesson_id
                        }
                    }
                }

                let find_unit_index = _.findIndex(moduel_state.module_state.unit_states, function (o) { return o.unit_id == unit._id; });

                if (find_unit_index != -1) {
                    if (unit.lessons.length == 1) {

                        moduel_state.module_state.unit_states[find_unit_index].is_current = false
                        moduel_state.module_state.unit_states[find_unit_index].is_finished = true

                        if (find_unit_index + 1 < moduel_state.module_state.unit_states.length) {
                            let flag1 = false

                            for (let index = find_unit_index + 2; index < moduel_state.module_state.unit_states.length; index++) {
                                let item = moduel_state.module_state.unit_states[index]
                                if (item.is_current) {
                                    flag1 = true
                                    break;
                                }
                            }
                            if (!flag1) {
                                moduel_state.module_state.unit_states[find_unit_index + 1].is_current = true
                                moduel_state.module_state.current_unit_id = moduel_state.module_state.unit_states[find_unit_index + 1].unit_id
                            }
                        }
                    }
                }
                updateModuleState(moduel_state)
            }
        }

    }, []);

    async function storeLessonActivity(data) {
        await API.post(`/user_lesson_activity`, data)
            .then((response) => {
                let res = response.data

            })
            .catch((error) => {
                console.log(error.response.data);
            });
    }

    async function updateModuleState(data) {
        await API.post(`/module_state`, data)
            .then((response) => {
                let res = response.data

            })
            .catch((error) => {
                console.log(error.response.data);
            });
    }

    let nextQuestion = () => {
        if (current_question_index + 1 == questions.length) {
            setChecked(null)
            setQuestion(null)
            setAnswerResult("")
            setSelectedAnswer(null)
            setQuestionStartTimestamp(0)
            setQuestionEndTimestamp(0)

            let u_index = _.findIndex(module.levels[current_level_index].units, function (o) { return o._id == unit._id; });

            let is_course_finished = false
            if (module.levels[current_level_index].units.length == u_index + 1) {
                is_course_finished = true
            }

            let obj = {
                is_course_finished: is_course_finished
            }

            navigate("LessonFinished", obj)
        } else {
            setCurrentQuestionIndex(current_question_index + 1)
            setChecked(null)
            setQuestion(null)
            setAnswerResult("")
            setSelectedAnswer(null)
            setQuestionStartTimestamp(0)
            setQuestionEndTimestamp(0)
            let obj = {}
            navigate("Questions", obj)
        }
    }

    return (
        <Layout
            scrollView={false}
            HeaderContent={
                <PageHeader backRoute={"Home"} pageTitle={unit.name} bgColor={"#17AAF5"} textColor={"#f7faf8"} />
            }
            tabbarVisible={false}
        // scrollView={false}
        >
            <View style={[tailwind`w-full h-full`]}>
                <ScrollView
                    style={[tailwind`w-full mb-9`]}
                    contentContainerStyle={tailwind``}
                >
                    <View style={[tailwind``]}>
                        <View
                            style={[
                                tailwind`w-full`,
                                { flexDirection: "row", alignItems: "center" },
                            ]}
                        >
                            <View
                                style={[{ justifyContent: "space-evenly", marginVertical: 10 }]}
                            >
                                <Text style={tailwind`text-[12px] text-center pr-1`}>
                                    {current_question_index + 1} of {questions.length}
                                </Text>
                            </View>
                            <View
                                style={[
                                    tailwind`mt-1 bg-slate-300 rounded-full h-2 bg-blue-200`,
                                    { flex: 1, flexDirection: "row" },
                                ]}
                            >
                                <View
                                    style={[
                                        tailwind`bg-[#17AAF5] h-2 rounded-full`,
                                        {
                                            width:
                                                (((current_question_index + 1) * questions.length) /
                                                    100) *
                                                100,
                                        },
                                    ]}
                                ></View>
                            </View>
                        </View>

                        {/* <Text>
                            Qid : {questions[current_question_index]?._id} and cid :{" "}
                            {selected_course._id} and QType : {questions[current_question_index].type_code}
                            User_id : {user._id}
                        </Text> */}
                    </View>

                    {
                        answer_result == "correct" &&
                        <>
                            {
                                (
                                    question.type_code == 'MCQ' || question.type_code == 'TF' || question.type_code == 'SEQ' || question.type_code == 'MTF' || question.type_code == 'GLC') && (
                                    <>
                                        <View style={tailwind` flex flex-row w-full mx-4 mt-4`}>
                                            <View style={[tailwind`px-0`, styles.card_sec1]}>
                                                <View style={tailwind``}>
                                                    <Image
                                                        source={require(`../../../../../../assets/datacharacter_illustrations/${correct_lesson_images[Math.floor(Math.random() * correct_lesson_images.length)]}`)}
                                                        style={[tailwind``, styles.img]}
                                                    />
                                                </View>
                                            </View>

                                            <View style={[tailwind`px-2 `, styles.card_sec2]}>
                                                <Text style={[tailwind`text-black font-bold text-[22px]`]}>
                                                    You did it!
                                                </Text>
                                                <Text style={[tailwind`text-slate-500 font-semibold text-[18px]`]}>
                                                    That was correct.
                                                </Text>
                                            </View>
                                        </View>

                                        <View style={[tailwind`px-2 shadow-lg mx-4 rounded py-4 text-center items-center`]}>
                                            <Text style={[tailwind`text-slate-500 font-semibold text-[18px]`]}>
                                                That's correct
                                            </Text>

                                            {
                                                (question.type_code == 'MCQ' || question.type_code == 'TF') &&
                                                <Text style={[tailwind`text-slate-500 font-semibold text-[16px] items-center`]}>
                                                    {selected_answer.feedback}
                                                </Text>
                                            }

                                            {
                                                (question.type_code == 'SEQ') &&
                                                <Text style={[tailwind`text-slate-500 font-semibold text-[16px] items-center`]}>
                                                    {question.feedback['correct']}
                                                </Text>
                                            }

                                            {
                                                (question.type_code == 'GLC') &&
                                                <Text style={[tailwind`text-slate-500 font-semibold text-[16px] items-center`]}>
                                                    {selected_answer.feedback}
                                                </Text>
                                            }
                                        </View>

                                        {/* <View style={[tailwind`absolute bottom-[20px]`, {
                                            bottom: 4,
                                            width: (wd - 40)
                                        }]}>
                                            <Pressable
                                                style={[tailwind``, styles.buttonStyle, {
                                                    backgroundColor: "#3EBD3E"
                                                }]}
                                                onPress={() => nextQuestion()}
                                            >
                                                <Text style={[styles.buttonText, {
                                                    color: "#FFFFFF"
                                                }]}> Next </Text>
                                            </Pressable>
                                        </View> */}

                                    </>

                                )
                            }
                        </>
                    }

                    {
                        answer_result == "incorrect" &&
                        <>
                            {
                                (question.type_code == 'MCQ' || question.type_code == 'TF' || question.type_code == 'SEQ' || question.type_code == 'MTF' || question.type_code == 'GLC') && (<>
                                    <View style={tailwind` flex flex-row w-full mx-4 mt-4`}>
                                        <View style={[tailwind`px-0`, styles.card_sec1]}>
                                            <View style={tailwind``}>
                                                <Image source={require(`../../../../../../assets/datacharacter_illustrations/${incorrect_lesson_images[Math.floor(Math.random() * incorrect_lesson_images.length)]}`)}
                                                    style={[tailwind``, styles.img]}
                                                />
                                            </View>
                                        </View>

                                        <View style={[tailwind`px-2 `, styles.card_sec2]}>
                                            <Text style={[tailwind`text-black font-bold text-[22px]`]}>
                                                Oh no!
                                            </Text>
                                            <Text style={[tailwind`text-slate-500 font-semibold text-[18px]`]}>
                                                That's not correct.
                                            </Text>
                                        </View>
                                    </View>

                                    <View style={[tailwind`px-2 shadow-lg mx-4 rounded py-4 text-center items-center`]}>
                                        <Text style={[tailwind`text-slate-500 font-semibold text-[18px]`]}>
                                            Ah! It's the wrong answer.
                                        </Text>

                                        {
                                            (question.type_code == 'MCQ' || question.type_code == 'TF') &&
                                            <Text style={[tailwind`text-slate-500 font-semibold text-[16px] items-center`]}>
                                                {selected_answer.feedback}
                                            </Text>
                                        }

                                        {
                                            (question.type_code == 'SEQ') &&
                                            <Text style={[tailwind`text-slate-500 font-semibold text-[16px] items-center`]}>
                                                {question.feedback['incorrect']}
                                            </Text>
                                        }

                                        {
                                            (question.type_code == 'GLC') &&
                                            <Text style={[tailwind`text-slate-500 font-semibold text-[16px] items-center`]}>
                                                {selected_answer.feedback}
                                            </Text>
                                        }
                                    </View>

                                    {/* <View style={[tailwind``, {
                                        position: 'absolute',
                                        bottom: 4,
                                        width: (wd - 40)
                                    }]}>
                                        <Pressable
                                            style={[tailwind``, styles.buttonStyle, {
                                                backgroundColor: "#3EBD3E"
                                            }]}
                                            onPress={() => nextQuestion()}
                                        >
                                            <Text style={[styles.buttonText, {
                                                color: "#FFFFFF"
                                            }]}> Next </Text>
                                        </Pressable>
                                    </View> */}
                                </>
                                )
                            }
                        </>
                    }
                </ScrollView>

                <View style={[tailwind`absolute bottom-[20px]`, {
                    bottom: 4,
                    width: (wd - 40)
                }]}>
                    <Pressable
                        style={[tailwind``, styles.buttonStyle, {
                            backgroundColor: "#3EBD3E"
                        }]}
                        onPress={() => nextQuestion()}
                    >
                        <Text style={[styles.buttonText, {
                            color: "#FFFFFF"
                        }]}> Next </Text>
                    </Pressable>
                </View>


            </View>
        </Layout >
    );
}

const styles = StyleSheet.create({
    img: {
        resizeMode: "contain",
        width: 200,
        height: 200,
        // objectFit: "contain"
    },
    loadercontainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
        marginTop: 50,
        marginBottom: "50%",
    },
    activityIndicator: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
    },
    buttonStyle: {
        borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 18,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginVertical: 30,
    },
    buttonText: {
        fontWeight: "bold",
        // color: "#fff",
    },

    card_sec1: {
        width: "50%",
        // backgroundColor: "red"
        justifyContent: "center",
        alignItems: "center",
    },
    card_sec2: {
        width: "50%",
        justifyContent: "center",
        // alignItems: "center",
        // backgroundColor: "blue"
    },
});
