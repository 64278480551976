import * as SecureStore from "expo-secure-store";
import { isWeb } from "../constants/constants";

const setItem = async (key, value) => {
  if (isWeb) {
    return localStorage.setItem(key, value);
  }

  return await SecureStore.setItemAsync(key, value);
};

const getItem = async (key) => {
  if (isWeb) {
    return localStorage.getItem(key);
  }

  return await SecureStore.getItemAsync(key);
};

const deleteItem = async (key) => {
  if (isWeb) {
    return localStorage.removeItem(key);
  }

  return await SecureStore.deleteItemAsync(key);
};

const LocalStorage = { setItem, getItem, deleteItem };

export default LocalStorage;
