import { Dimensions, Platform } from "react-native";

export const width = Dimensions.get("window").width;
export const height = Dimensions.get("window").height;

export const isWeb = Platform.OS === "web" ? true : false;

export const minutesCallBuffer = 5;
export const minutesJoinBuffer = 10;

export const timeSlotOptions = [
  { key: "1", value: "06:30 AM" },
  { key: "2", value: "07:00 AM" },
  { key: "3", value: "07:30 AM" },
  { key: "4", value: "08:00 AM" },
  { key: "5", value: "08:30 AM" },
  { key: "6", value: "09:00 AM" },
  { key: "7", value: "09:30 AM" },
  { key: "8", value: "10:00 AM" },
  { key: "9", value: "10:30 AM" },
  { key: "10", value: "11:00 AM" },
  { key: "11", value: "11:30 AM" },
  { key: "12", value: "12:00 PM" },
  { key: "13", value: "12:30 PM" },
  { key: "14", value: "01:00 PM" },
  { key: "15", value: "01:30 PM" },
  { key: "16", value: "02:00 PM" },
  { key: "17", value: "02:30 PM" },
  { key: "18", value: "03:00 PM" },
  { key: "19", value: "03:30 PM" },
  { key: "20", value: "04:00 PM" },
  { key: "21", value: "04:30 PM" },
  { key: "22", value: "05:00 PM" },
  { key: "23", value: "05:30 PM" },
  { key: "24", value: "06:00 PM" },
  { key: "25", value: "06:30 PM" },
  { key: "26", value: "07:00 PM" },
  { key: "27", value: "07:30 PM" },
  { key: "28", value: "08:00 PM" },
  { key: "29", value: "08:30 PM" },
  { key: "30", value: "09:00 PM" },
  { key: "31", value: "09:30 PM" },
  { key: "32", value: "10:00 PM" },
  { key: "33", value: "10:30 PM" },
  { key: "34", value: "11:00 PM" },
  { key: "35", value: "11:30 PM" },
];
