import React, { useEffect, useState } from "react";
import Modal from "../../../../../../components/modal";
import Text from "../../../../../../components/Text";
import { Image, Linking, TouchableOpacity, View } from "react-native";
import moment from "moment";
import useClasses from "../../../../../../context/classes";
import tailwind from "twrnc";
import { AntDesign } from "@expo/vector-icons";
import { TEACHERS } from "../../../../../../api/instance";
import useUser from "../../../../../../context/user";

const JoinClassDialog = ({ link, open, setOpen }) => {
  const { upcoming, isTeacher } = useClasses();
  const { user } = useUser();

  const [imagePath, setImagePath] = useState("");
  const [titleText, setTitleText] = useState("");
  const [messageBody, setMessageBody] = useState("");

  useEffect(() => {
    const currentTime = moment();
    const slotTime = moment.unix(upcoming.slot / 1000);
    const difference = currentTime.diff(slotTime, "minutes");

    if (difference < 0 && difference < -5) {
      // User is early
      setImagePath(
        require("../../../../../../../assets/img_girl_thumbs_up.png")
      );
      setTitleText("Wow! You are early.");
      setMessageBody(
        "You might have to wait a little but please be patient.\nHave a great class 👍"
      );
    } else if (difference < 0) {
      // User is on time
      setImagePath(require("../../../../../../../assets/img_girl_clap.png"));
      setTitleText("Great Job");
      setMessageBody(
        "You are on time. Being punctual is a fabulous quality.\nHave a great class 👍"
      );
    } else if (difference < 5) {
      // Slightly delayed
      setImagePath(
        require("../../../../../../../assets/img_girl_hands_waist.png")
      );
      setTitleText("Hey! You are slightly delayed.");
      setMessageBody(
        "But don't worry, just join quickly.\nAnd have a great class 👍"
      );
    } else {
      // Very delayed
      setImagePath(
        require("../../../../../../../assets/img_girl_folded_hands.png")
      );
      setTitleText("Oops! You are late for your class.");
      setMessageBody(
        "Please join quickly. Hope you are still not too late to attend the class\nHave a great class 👍"
      );
    }
  }, []);

  const openLink = async () => {
    Linking.openURL(link);
    if (upcoming.type === "SESSION") {

      if (isTeacher) {
        let res = await TEACHERS.post(`/session/call/${upcoming.id}/teacher/log`, {
          app_user_id: user._id,
        });
      } else {
        let res = await TEACHERS.post(`/session/call/${upcoming.id}/user/log`, {
          phone: user.phone,
        });
      }
    }

    if (upcoming.type === "WEBINAR") {
      if (isTeacher) {
        let res = await TEACHERS.post(`/webinar/class/${upcoming.id}/teacher/log`, {
          app_user_id: user._id,
        });
      } else {
        let res = await TEACHERS.post(`/webinar/class/${upcoming.id}/user/log`, {
          phone: user.phone,
        });
      }
    }

    setOpen(false);
  };
  return (
    <Modal visible={open} setVisible={setOpen}>
      <View style={tailwind``}>
        <View style={tailwind`flex flex-row justify-end`}>
          <AntDesign
            onPress={() => setOpen(false)}
            name="close"
            size={24}
            color="black"
          />
        </View>
        <View style={tailwind`flex items-center`}>
          {imagePath && <Image source={imagePath} style={tailwind`w-[100px] h-[140px]`} />}
          <View style={tailwind`border rounded-lg border-[#9E9E9E] px-5 py-3`}>
            <Text style={tailwind`text-[#2196F2] font-bold`}>{titleText}</Text>
            <Text style={tailwind`text-[#4F4F4F] mt-1 font-bold text-[12px]`}>
              {messageBody}
            </Text>
          </View>

          <TouchableOpacity
            onPress={openLink}
            style={tailwind`bg-[#FE995A] mt-5 px-5 py-3 rounded-lg`}
          >
            <Text style={tailwind`text-white font-bold`}>
              Take me to the class
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default JoinClassDialog;
