import useClasses from "../../../../../../context/classes";
import { UpcomingSessionCard } from "./upcoming_session_card";
import { UpcomingWebinarCard } from "./upcoming_webinar_card";

const UpcomingSession = () => {
  const { upcoming } = useClasses();

  return (
    <>
      {upcoming && (
        <>
          {upcoming.type === "SESSION" && <UpcomingSessionCard />}
          {upcoming.type === "WEBINAR" && <UpcomingWebinarCard />}
        </>
      )}
    </>
  );
};

export default UpcomingSession;
