import { View, Text, Image, StyleSheet, TouchableOpacity } from "react-native";
import { React, useState } from "react";
import tailwind from "twrnc";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import NotesDialog from "../../home/classes/upcoming_section/notes/notes_dialog";
import QuizDialog from "../../quizzes/quiz_dialog"
import useUser from "../../../../../context/user";

export default function CancelledItem({ item, i }) {
    let { user } = useUser();
    const { navigate } = useNavigation();
    let class_item = {};
    let teachers = [];
    let users = [];
    let status = {};

    let class_type = (item.session_id) ? "SESSION" : "WEBINAR"
    let cls_start_time = "";
    let cls_end_time = "";
    let time_duration = "";
    let topic_name = "";

    if (class_type == "SESSION") {
        teachers = item.session.teachers
        users = item.session.users
        cls_start_time = moment(item.slot_start_time, "hh:mm A");
        cls_end_time = moment(item.slot_end_time, "hh:mm A");
        time_duration = cls_end_time.diff(cls_start_time, "minutes");
        if (item.status) status = item.status;
        topic_name = item.topic_name ?? `${item?.session?.target_language} Class`;
    }

    if (class_type == "WEBINAR") {
        teachers = item.classTeachers
        users = item.users
        topic_name = `${item.webinar.name} - ${item.topic}`;
    }

    const [notesModalOpen, setNotesModalOpen] = useState(false);

    const [quizModalOpen, setQuizModalOpen] = useState(false);
    const [quizzes, setQuizzes] = useState([]);
    const [call, setCall] = useState(null);

    const handleQuiz = async (item, cls) => {
        setQuizzes(item)
        setQuizModalOpen(true)
        setCall(cls)
    };

    return (
        <View style={tailwind`bg-gray-200 mx-auto my-1 w-full`}>
            <View style={tailwind` p-2`}>
                {class_type == "SESSION" && <>
                    <View style={tailwind` flex flex-row w-full`} id={`session_call_id_${item.id}`}>
                        <View style={[tailwind`px-0`, styles.card_sec1]}>
                            <View style={tailwind``}>
                                <Image
                                    source={{ uri: teachers[0]?.profile?.avatar }}
                                    style={tailwind`w-[85px] h-[85px] rounded`}
                                />
                                <Text style={tailwind`text-left py-1 text-[12px]`}>
                                    {teachers[0]?.name}
                                </Text>
                            </View>
                        </View>

                        <View style={[tailwind`px-2 `, styles.card_sec2]}>

                            <Text>{topic_name}</Text>
                            <Text
                                style={tailwind``}
                            >
                                1 To 1 Class
                            </Text>
                            <View style={[tailwind`flex-row flex items-center pt-2`]}>

                                <Text style={[tailwind`pl-1 text-[12px]`]}>
                                    {teachers[0]?.name}
                                </Text>
                            </View>

                            <View style={[tailwind`flex-row pt-1 items-center`]}>
                                <Ionicons name="calendar-outline" size={18} color="#878787" />
                                <Text style={[tailwind`pl-1 text-[12px]`]}>
                                    {moment.unix(item.slot / 1000).format("hh:mm A MMM Do YYYY")}
                                </Text>
                            </View>

                            {(item.attached_quizzes?.length > 0 || item.attached_quizzes?.length > 0) && (
                                <>
                                    {call && <QuizDialog
                                        open={quizModalOpen}
                                        setOpen={setQuizModalOpen}
                                        quizzes={quizzes}
                                        call={call}
                                    />
                                    }

                                    <View style={[tailwind`flex-row pt-1`]}>
                                        <TouchableOpacity
                                            onPress={() => handleQuiz(item.attached_quizzes, item)}
                                            style={tailwind`flex flex-row items-center gap-x-1`}
                                        >
                                            <Ionicons
                                                name="file-tray-full-outline"
                                                size={18}
                                                color="#878787"
                                            />
                                            <Text style={tailwind`text-[12px] mt-[-2px] underline `}>
                                                Quizzes
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </>
                            )}

                            {(item.noteFiles?.length > 0 || item.noteLinks?.length > 0) && (
                                <>
                                    <NotesDialog
                                        open={notesModalOpen}
                                        setOpen={setNotesModalOpen}
                                        session={item}
                                    />
                                    <View style={[tailwind`flex-row pt-1`]}>
                                        <TouchableOpacity
                                            onPress={() => setNotesModalOpen(true)}
                                            style={tailwind`flex flex-row items-center gap-x-1`}
                                        >
                                            <Ionicons
                                                name="document-text-outline"
                                                size={18}
                                                color="#878787"
                                            />
                                            <Text style={tailwind`text-[12px] mt-[-2px] underline `}>
                                                View Class Material
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </>
                            )}
                        </View>

                        <View style={[tailwind`px-0 `, styles.card_sec3]}>
                            {status?.name && status?.name == "Rescheduled" && (
                                <Text
                                    style={tailwind`text-center bg-[#f44336] text-white text-xs p-0.5 rounded dark:bg-[#f44336] dark:text[#f44336]`}
                                >
                                    Rescheduled
                                </Text>
                            )}

                            {status?.name && status?.name == "Lapsed" && (
                                <Text
                                    style={tailwind`text-center bg-[#f6c23e] text-white text-xs p-0.5 rounded dark:bg-[#f6c23e] dark:text[#f6c23e]`}
                                >
                                    {item.status.name}
                                </Text>
                            )}

                            <View style={[tailwind`flex-row py-2`]}>
                                <Text
                                    style={tailwind`text-center bg-[#607d8b] text-white text-[12px] py-0.5 px-1 rounded dark:bg-[#607d8b] dark:text[#607d8b]`}
                                >
                                    {item.session?.source_language.substring(0, 2)}
                                </Text>

                                {item.session.call_type == "video" && (
                                    <Ionicons
                                        name="videocam"
                                        size={18}
                                        color="#2196f3"
                                        style={tailwind`px-1`}
                                    />
                                )}

                                {item.session.call_type == "audio" && (
                                    <Ionicons
                                        name="call"
                                        size={18}
                                        color="#2196f3"
                                        style={tailwind`px-1`}
                                    />
                                )}

                                <Text
                                    style={tailwind`text-center bg-[#607d8b] text-white text-[12px] py-0.5 px-1 rounded dark:bg-[#607d8b] dark:text[#607d8b]`}
                                >
                                    {item.session?.target_language.substring(0, 2)}
                                </Text>
                            </View>

                            <View style={[tailwind`flex-row`]}>
                                <Text
                                    style={tailwind`text-center bg-[#607d8b] text-white text-[12px] py-0.5 px-1 rounded dark:bg-[#607d8b] dark:text[#607d8b]`}
                                >
                                    {time_duration} mins
                                </Text>
                            </View>

                        </View>
                    </View>

                    {item.remarks && item.remarks.length > 0 && (
                        <View style={tailwind`flex flex-wrap`}>
                            {item.remarks.map((remark, remark_index) => (
                                remark && remark.rescheduled_reason && (
                                    <View
                                        style={tailwind`flex-row items-center justify-end px-1`}
                                        key={`${remark_index}-${remark.id}`}
                                    >
                                        <Ionicons name="alert-circle-outline" size={16} color="#1098ec" />
                                        <Text style={tailwind`text-[14px] pl-1`}>
                                            Reason : {remark.rescheduled_by && <Text>{remark.rescheduled_by} - </Text>}{remark.rescheduled_reason}
                                        </Text>
                                    </View>
                                )
                            ))}
                        </View>
                    )}

                </>
                }

                {class_type == "WEBINAR" && <>
                    <View style={tailwind` flex flex-row w-full`} id={`webinar_call_id_${item.id}`}>
                        <View style={[tailwind`px-0`, styles.card_sec1]}>
                            <View style={tailwind``}>
                                <Image
                                    source={{ uri: teachers[0]?.profile?.avatar }}
                                    style={tailwind`w-[85px] h-[85px] rounded`}
                                />
                                <Text style={tailwind`text-left py-1 text-[12px]`}>
                                    {teachers[0]?.name}
                                </Text>
                            </View>
                        </View>

                        <View style={[tailwind`px-2 `, styles.card_sec2]}>
                            <Text>{topic_name}</Text>
                            <Text
                                style={tailwind``}
                            >
                                Group Class
                            </Text>
                            <View style={[tailwind`flex-row flex items-center pt-2`]}>
                                <Text style={[tailwind`pl-1 text-[12px]`]}>
                                    {teachers[0]?.name}
                                </Text>
                            </View>

                            <View style={[tailwind`flex-row pt-1 flex items-center`]}>
                                <Ionicons name="calendar-outline" size={18} color="#878787" />
                                <Text style={[tailwind`pl-1 text-[12px]`]}>
                                    {moment.unix(item.slot / 1000).format("hh:mm A MMM Do YYYY")}
                                </Text>
                            </View>

                            {(item.attached_quizzes?.length > 0 || item.attached_quizzes?.length > 0) && (
                                <>
                                    {call && <QuizDialog
                                        open={quizModalOpen}
                                        setOpen={setQuizModalOpen}
                                        quizzes={quizzes}
                                        call={call}
                                    />
                                    }

                                    <View style={[tailwind`flex-row pt-1`]}>
                                        <TouchableOpacity
                                            onPress={() => handleQuiz(item.attached_quizzes, item)}
                                            style={tailwind`flex flex-row items-center gap-x-1`}
                                        >
                                            <Ionicons
                                                name="file-tray-full-outline"
                                                size={18}
                                                color="#878787"
                                            />
                                            <Text style={tailwind`text-[12px] mt-[-2px] underline `}>
                                                Quizzes
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </>
                            )}

                            {(item.noteFiles?.length > 0 || item.noteLinks?.length > 0) && (
                                <>
                                    <NotesDialog
                                        open={notesModalOpen}
                                        setOpen={setNotesModalOpen}
                                        session={item}
                                    />
                                    <View style={[tailwind`flex-row pt-1`]}>
                                        <TouchableOpacity
                                            onPress={() => setNotesModalOpen(true)}
                                            style={tailwind`flex flex-row items-center gap-x-1`}
                                        >
                                            <Ionicons
                                                name="document-text-outline"
                                                size={18}
                                                color="#878787"
                                            />
                                            <Text style={tailwind`text-[12px] mt-[-2px] underline `}>
                                                View Class Material
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </>
                            )}
                        </View>

                        <View style={[tailwind`px-0 `, styles.card_sec3]}>
                            {item.status == "canceled" && (
                                <Text
                                    style={tailwind`text-center bg-[#f44336] text-white text-xs p-0.5 rounded dark:bg-[#f44336] dark:text[#f44336]`}
                                >
                                    Rescheduled
                                </Text>
                            )}

                            <View style={[tailwind`flex-row pt-2`]}>
                                <Text
                                    style={tailwind`text-center bg-[#607d8b] text-white text-base py-0.5 px-1 rounded dark:bg-[#607d8b] dark:text[#607d8b] text-xs`}
                                >
                                    {item.webinar.duration} mins
                                </Text>
                            </View>
                        </View>
                    </View>


                    {item.rescheduled_status && (
                        <View style={tailwind`flex flex-wrap`}>
                            <View
                                style={tailwind`flex-row items-center justify-end px-1`}
                            >
                                <Ionicons name="alert-circle-outline" size={16} color="#1098ec" />
                                <Text style={tailwind`text-[14px] pl-1`}>
                                    Reason: {item.rescheduled_status.replace(/_/g, " ")}
                                </Text>
                            </View>
                        </View>
                    )}
                </>
                }
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    loadercontainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50%",
        marginBottom: "50%",
    },
    activityIndicator: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    card_sec1: {
        width: "25%",
        // backgroundColor: "red"
    },
    card_sec2: {
        width: "55%",
        // backgroundColor: "blue"
    },
    card_sec3: {
        width: "20%",
    },
});
