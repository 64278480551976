import React from "react";
import { ScrollView, View, Text } from "react-native";
import tailwind from "twrnc";
import { height, isWeb, width } from "../../constants/constants";
import Tabbar from "./tabbar";
import Header from "./headers/header";
import Toast from "react-native-toast-message";

const Layout = ({
  children,
  HeaderContent = () => { },
  tabbarVisible = true,
  scrollView = true,
}) => {
  const headerHeight = isWeb ? 60 : 100;
  const tabbarHeight = tabbarVisible ? 80 : 0;
  const contentHeight = height - headerHeight - tabbarHeight - 1;

  return (
    <>
      <View>
        <Header headerHeight={headerHeight}>{HeaderContent}</Header>

        {scrollView ? (
          <ScrollView
            style={tailwind`h-[${contentHeight}px]`}
            contentContainerStyle={[tailwind`items-center`, { flexGrow: 1 }]}
          >
            <View style={tailwind`w-[${width <= 500 ? width : 500}px] h-full`}>
              <View
                style={tailwind`flex flex-col items-start w-full px-5 h-full relative`}
              >
                {children}
              </View>
            </View>
          </ScrollView>
        ) : (
          <View style={tailwind`h-[${contentHeight}px] flex items-center`}>
            <View style={tailwind`w-[${width <= 500 ? width : 500}px] h-full`}>
              <View
                style={tailwind`flex flex-col items-start w-full px-5 h-full relative`}
              >
                {children}
              </View>
            </View>
          </View>
        )}

        {tabbarVisible && <Tabbar tabbarHeight={tabbarHeight} />}
      </View>
      <Toast />
    </>
  );
};

export default Layout;

