import { TouchableOpacity, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";
import { SelectList } from "react-native-dropdown-select-list";
import useClassesFilter from "./context";
import { React, useState } from 'react'

const CategoryInput = () => {
	const { status, setStatus, categorySelected, setCategorySelected } = useClassesFilter();

	const [categories, setCategories] = useState([
		{
			"name": "1 To 1",
			"slug": "SESSION"
		},
		{
			"name": "Group",
			"slug": "WEBINAR"
		}
	]);

	return (
		<View style={tailwind`mt-3`}>
			<View style={tailwind`flex items-center flex-row justify-between`}>
				<Text style={tailwind`text-[#878787] font-semibold text-[16px]`}>
					Category
				</Text>
				<TouchableOpacity
					onPress={() => setCategorySelected("")}
					style={tailwind`text-[#878787] font-semibold text-[12px]`}
				>
					<Text>Clear</Text>
				</TouchableOpacity>
			</View>

			{/* <SelectList
				setSelected={(val) => setCategorySelected(val)}
				data={statuses}
				save="value"
				boxStyles={tailwind`rounded py-2 px-2`}
				value={status}
			/> */}

			<SelectList
				setSelected={(slug) => {
					const category = categories.find((item) => item.slug === slug);
					setCategorySelected(category); // Set the selected category object
				}}
				data={categories.map((item) => ({ key: item.slug, value: item.name }))}
				save="key"
				boxStyles={tailwind`rounded py-2 px-2`}
				value={categorySelected ? categorySelected.name : ""}
			/>
		</View>
	);
};

export default CategoryInput;
