import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList
} from "react-native";
import React, { useState, useEffect } from "react";
import tailwind from "twrnc";
import { height, isWeb, width } from "../../../../../constants/constants";
import { useNavigation, useNavigationParam } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";

import { API } from "../../../../../api/instance";
import Layout from "../../../layout";
import PageHeader from "../../../headers/page.header";
let wd = width <= 500 ? width : 499.99
import LessonDialog from "./lesson_dialog";
import useQuestion from "./common/context";
import useUser from "../../../../../context/user";
import moment from "moment";

export default function Lessons(props) {
    const { navigate } = useNavigation();
    let { user } = useUser();

    const {
        setModuleStates,
        module,
        setModule,
        setLevel,
        unit,
        setUnit,
        setLesson,
        selected_course,
        setQuestions,
        setQuestionIds,
        setCurrentQuestionIndex,
        setLessonStartTimestamp,
        setTotalQuestion,
        setCurrentUnitIndex,
        setCurrentLessonIndex,
        current_level_index } = useQuestion();

    const headerHeight = isWeb ? 60 : 100;
    const headerHeight1 = isWeb ? 50 : 50;
    let back_route = props.route?.params?.from_page

    let [courses, setCourses] = useState([]);
    let [loading, setLoading] = useState(true);

    let units = module.levels[0].units
    const [lessonModalOpen, setLessonModalOpen] = useState(false);

    let [card_colors, setCardColors] = useState([
        "EBFFFF",
        "FFF5E9",
        "FFE8E7",
        "FFE8DF",
        "E7F0FF"
    ]);

    useEffect(() => {
    }, []);

    let handleRedirect = async (unit, unit_index) => {
        if (module.type == `GRAMMAR`) {
            if (unit.lessons.length > 0) {
                setUnit(unit)
                setCurrentUnitIndex(unit_index)
                setLessonModalOpen(true)
            }
        }

        if (module.type == `CONVERSATION` || module.type == "CUSTOM") {
            let question_ids = []
            if (unit.lessons.length == 1) {
                question_ids = unit.lessons[0].questions
                setCurrentLessonIndex(0)
            }

            if (question_ids.length > 0) {
                try {
                    let qns = await getQuestionByIds(question_ids)
                    let res = qns.data.data

                    setQuestions(res)
                    setCurrentQuestionIndex(0)
                    setLevel(module.levels[current_level_index])
                    setCurrentUnitIndex(unit_index)
                    setUnit(unit)
                    setLesson(unit.lessons[0])
                    setQuestionIds(question_ids)
                    setLessonStartTimestamp(moment().format('x'))
                    let obj = {
                        from_page: "Modules",
                    }

                    let total_qns = 0
                    const q_arr = res.filter(x =>
                        x.type_code != "LC" &&
                        x.type_code != "CVC" &&
                        x.type_code != "LCC"
                    );
                    setTotalQuestion(q_arr.length)

                    navigate("Questions", obj)
                } catch (error) {
                    console.log(error.response);
                }
            }

        }
    }

    async function getQuestionByIds(question_ids) {
        let params = {
            questions: question_ids,
        };
        return await API.post(`questions/ids`, params)
    }

    let selectLesson = async (props) => {
        let lesson_index = _.findIndex(unit.lessons, function (o) { return o._id == props._id; });
        let u_index = _.findIndex(module.levels[current_level_index].units, function (o) { return o._id == unit._id; });
        let question_ids = props.questions
        setCurrentLessonIndex(lesson_index)
        if (question_ids.length > 0) {
            try {
                let qns = await getQuestionByIds(question_ids)
                let res = qns.data.data

                const glc_question = res.filter(x =>
                    x.type_code == "GLC"
                );

                const no_glc_question = res.filter(x =>
                    x.type_code != "GLC"
                );

                let sorted_qns = [...glc_question, ...no_glc_question]

                setQuestions(sorted_qns)
                setCurrentQuestionIndex(0)
                setModule(module)
                setLevel(module.levels[current_level_index])
                setCurrentUnitIndex(u_index)
                setUnit(module.levels[current_level_index].units[u_index])
                setLesson(props)
                setQuestionIds(question_ids)
                setLessonStartTimestamp(moment().format('x'))

                let obj = {
                    from_page: "Modules",
                }

                let total_qns = 0
                const q_arr = res.filter(x =>
                    x.type_code != "LC" &&
                    x.type_code != "CVC" &&
                    x.type_code != "LCC"
                );
                setTotalQuestion(q_arr.length)

                navigate("Questions", obj)
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <Layout
            HeaderContent={
                <PageHeader backRoute={back_route} pageTitle={`Learn ${selected_course.target_language[0].toUpperCase() + selected_course.target_language.slice(1)} On Your Own`} />
            }
        // scrollView={false}
        >
            <Text style={tailwind`font-bold mt-5 mb-2 text-[18px]`}>
                {module.type} {module.type == `GRAMMAR` ? `UNITS` : `LESSONS`}
            </Text>
            <View style={tailwind`flex flex-row w-full`}>
                {module && unit && < LessonDialog
                    open={lessonModalOpen}
                    setOpen={setLessonModalOpen}
                    module={module}
                    unit={unit}
                    selectLesson={selectLesson}
                />}
                <FlatList
                    data={units}
                    numColumns={3}
                    style={{
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap"
                    }}
                    renderItem={({ item, index }) =>
                        <Pressable
                            key={moment().format('x')}
                            onPress={() => handleRedirect(item, index)}
                            // style={[styles.item]}
                            style={[tailwind`shadow-md`, styles.item, { backgroundColor: (index + 1 > card_colors.length) ? "#" + card_colors[index % card_colors.length] : "#" + card_colors[index] }]}
                        >
                            {/* <View style={[tailwind`w-full px-5`, { flexDirection: 'row', alignItems: 'center' }]}>
                                <View style={[tailwind`mt-1 bg-slate-300 rounded-full h-2 dark:bg-gray-700`, { flex: 1, flexDirection: 'row' }]}>
                                    <View style={[tailwind`bg-green-600 h-2 rounded-full`, { width: "0%" }]}>
                                    </View>
                                </View>
                                <View style={[{ justifyContent: 'space-evenly', }]}>
                                    <Text style={tailwind`text-[10px] text-center pl-1`}>0%</Text>
                                </View>
                            </View> */}

                            <View style={[tailwind`m-2 text-center w-full`, { alignItems: 'center', maxWidth: 90 }]}>
                                <View style={styles.img_view}>
                                    {module.type != `GRAMMAR` && <Image
                                        source={{ uri: item.lessons[0].lesson_background_image }}
                                        style={[tailwind`rounded`, styles.img]}
                                    />}

                                    {module.type == `GRAMMAR` && <Image
                                        source={{ uri: selected_course?.image }}
                                        style={[tailwind`rounded`, styles.img]}
                                    />}
                                </View>
                                <View style={[tailwind`py-2`, styles.unitname_custom]}>
                                    <Text
                                        style={tailwind`capitalize text-slate-500 w-full text-[10px] text-center mt-2 justify-center`}
                                    >
                                        {item.name}
                                    </Text>
                                </View>
                            </View>
                        </Pressable>
                    }
                    keyExtractor={(item, i) => moment().format('x') + i}
                />

            </View >
        </Layout>
    );
}

const styles = StyleSheet.create({
    unitname: {
        minHeight: 100
    },
    img: {
        // resizeMode: "cover",
        width: 70,
        height: 70
    },
    unitname_custom: {
        minHeight: 50
    },
    item: {
        margin: 5,
        padding: 5,
        // height: 100,
        width: (wd - 70) / 3,
        textAlign: "center",
        alignItems: 'center',
        // backgroundColor: "color: rgb(241 245 249)",

        // textAlignVertical: "center",
        // justifyContent: 'center',

        // border: "2px solid red"
    },
    img_view: {
        maxHeight: 70
    }
});

