import { Text as RNText } from "react-native";

const Text = (props) => {
  const { children, style } = props;
  return (
    <RNText style={style} {...props}>
      {children}
    </RNText>
  );
};

export default Text;
