import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList,
    Platform,
    ActivityIndicator,
    Button,
} from "react-native";
import React, { useState, useEffect } from "react";
import tailwind from "twrnc";
import { height, isWeb, width } from "../../../../constants/constants";
import { useNavigation, useNavigationParam } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";

import { API, TEACHERS } from "../../../../api/instance";
import Layout from "../../layout";
import PageHeader from "../../headers/page.header";
let wd = width <= 500 ? width : 499.99
import useQuestion from "./common/context";
import moment from "moment";
import useUser from "../../../../context/user";
import _ from "lodash";
import useQuiz from "./common/context";

export default function Feedback(props) {
    const { navigate } = useNavigation();
    let { user } = useUser();

    let [correct_lesson_images, setCorrectLessonImages] = useState([
        "HIN_BOY_thumbs_up_.png",
        "HIN_BOY_clap_.png",
        "HIN_GIRL_clap_.png",
        "HIN_GIRL_thumbs_up_.png"
    ]);

    let [incorrect_lesson_images, setIncorrectLessonImages] = useState([
        "HIN_BOY_folded_hands_.png",
        "HIN_BOY_hands_on_waist_.png",
        "HIN_GIRL_folded_hands_.png",
        "HIN_GIRL_hands_on_waist_.png"
    ]);

    let [other_correct_lesson_images, setOtherCorrectLessonImages] = useState([
        "HIN_BOY_thumbs_up_half.png",
        "HIN_GIRL_thumbs_up_half.png"
    ]);

    let [other_incorrect_lesson_images, setOtherIncorrectLessonImages] = useState([
        "HIN_BOY_straight_half.png",
        "HIN_GIRL_straight_half.png"
    ]);

    const {
        user_quiz_log_id,
        quiz,
        setQuiz,
        selected_answer,
        setSelectedAnswer,
        questions,
        setQuestions,
        question_ids,
        setQuestionIds,
        current_question_index,
        setCurrentQuestionIndex,
        answer_result,
        setAnswerResult,
        question,
        setQuestion,
        checked,
        setChecked,
        total_correct_answer,
        setTotalCorrectAnswer,
        total_incorrect_answer,
        setTotalIncorrectAnswer,
        total_question_attempt,
        setTotalQuestionAttempt,
        question_activity,
        setQuestionActivity,
        question_start_timestamp,
        setQuestionStartTimestamp,
        question_end_timestamp,
        setQuestionEndTimestamp,
        total_question,
        setTotalQuestion,
        class_id,
        setClassId,
        class_type,
        setClassType,
        wq_text_answer,
        setWqTextAnswer,
        text_eval_ans,
        setTextEvalAns,
        userEmail1,
        setUserEmail1,
        userEmail2,
        setUserEmail2,
        question_time_out,
        total_timeout,
        setTotalTimeout,
        quizTimers,
        setQuestionTimeDuration,
        setQuestionTimeOut
    } = useQuiz();

    useEffect(() => {
        let t1 = moment(question_start_timestamp, 'x')
        let t2 = moment(question_end_timestamp, 'x')

        let seconds = t2.diff(t1, 'seconds');

        let activity = {
            "log_id": user_quiz_log_id,
            "class_type": class_type,
            "total_correct_answer": + total_correct_answer,
            "total_incorrect_answer": +total_incorrect_answer,
            "total_question_attempt": +total_question_attempt,
            "last_question_id": questions[current_question_index].id,
            "question_log_details": {
                "question_id": questions[current_question_index].id,
                "question_start_timestamp": question_start_timestamp,
                "question_end_timestamp": question_end_timestamp,
                "is_correct": (answer_result == "correct") ? "yes" : "no"
            },
            "total_timeout": +total_timeout,
            "time_taken": seconds + 1,
            "finished_on_time": (question_time_out && question_time_out == "yes") ? "no" : "yes"
        }

        if (current_question_index + 1 == questions.length) {
            activity.is_quiz_finised = "yes"
            activity.quiz_end_timestamp = moment().format('x')
        } else {
            activity.is_quiz_finised = "no"
        }

        if (questions[current_question_index].type_code == 'SQ' || questions[current_question_index].type_code == 'WQ') {
            if (text_eval_ans) {
                activity.question_log_details.relevance_score = (text_eval_ans.relevance_score) ? +text_eval_ans.relevance_score : "0"

                activity.question_log_details.grammar_score = (text_eval_ans.grammar_score) ? +text_eval_ans.grammar_score : "0"

                activity.question_log_details.comments = (text_eval_ans.comments) ? text_eval_ans.comments : null

                activity.question_log_details.grammar_relavence_comments = (text_eval_ans.grammar_relavence_comments) ? text_eval_ans.grammar_relavence_comments : null

                activity.question_log_details.subject_relavence_comments = (text_eval_ans.subject_relavence_comments) ? text_eval_ans.subject_relavence_comments : null

                activity.question_log_details.answer = (wq_text_answer) ? wq_text_answer : null
            }

            // if (questions[current_question_index].type_code == 'WQ' && wq_text_answer)
            //     activity.question_log_details.answer = wq_text_answer
        }
        updateUserQuizLog(activity)
    }, []);

    async function updateUserQuizLog(data) {
        await TEACHERS.put(`/quiz/logs`, data)
            .then((response) => {
                let res = response.data

            })
            .catch((error) => {
                console.log(error.response.data);
            });
    }

    let nextQuestion = async () => {
        if (current_question_index + 1 == questions.length) {

            let payload = {
                "phone": user.phone,
                "quiz_id": quiz.id,
                "class_id": class_id,
                "class_type": class_type,
                "log_id": user_quiz_log_id,
                "user_email1": userEmail1,
                "user_email2": userEmail2,
            }

            await TEACHERS.post(`/generate/quiz/testreport`, payload)
                .then((response) => {

                })
                .catch((error) => {
                    console.log(error.response.data);
                });

            setChecked(null)
            setQuestion(null)
            setAnswerResult("")
            setSelectedAnswer(null)
            setQuestionStartTimestamp(0)
            setQuestionEndTimestamp(0)
            setTextEvalAns(null)
            setWqTextAnswer("")
            setQuestionTimeOut("no")
            setQuestionTimeDuration(0)
            navigate("QuizCompleted")
        } else {
            let new_index = current_question_index + 1
            setCurrentQuestionIndex(new_index)
            setQuestionTimeOut("no")
            let obj1 = quizTimers.find(item => item.question_type == questions[new_index].type_code)
            setQuestionTimeDuration(obj1.timer)
            setChecked(null)
            setQuestion(null)
            setAnswerResult("")
            setSelectedAnswer(null)
            setQuestionStartTimestamp(0)
            setQuestionEndTimestamp(0)
            setTextEvalAns(null)
            setWqTextAnswer("")
            setQuestionTimeOut("no")
            let obj = {}
            navigate("Quizzes")
        }
    }

    return (
        <Layout
            scrollView={false}
            HeaderContent={
                <PageHeader backRoute={"Home"} pageTitle={quiz.name} bgColor={"#17AAF5"} textColor={"#f7faf8"} />
            }
            tabbarVisible={false}
        // scrollView={false}
        >
            <View style={[tailwind`w-full h-full`]}>
                <ScrollView
                    style={[tailwind`w-full mb-9`]}
                    contentContainerStyle={tailwind``}
                >
                    <View style={[tailwind``]}>
                        <View
                            style={[
                                tailwind`w-full`,
                                { flexDirection: "row", alignItems: "center" },
                            ]}
                        >
                            <View
                                style={[
                                    tailwind``,
                                    { justifyContent: "space-evenly", marginVertical: 10, width: 50 },
                                ]}
                            >
                                <Text style={tailwind`text-[12px] text-center pr-1`}>
                                    {current_question_index + 1} of {questions.length}
                                </Text>
                            </View>
                            <View
                                style={[
                                    tailwind`mt-1 bg-slate-300 rounded-full h-2 bg-blue-200`,
                                    { flex: 1, flexDirection: "row", width: wd - 90 },
                                ]}
                            >
                                <View
                                    style={[
                                        tailwind`bg-[#17AAF5] h-2 rounded-full`,
                                        {
                                            width:
                                                ((current_question_index + 1) / questions.length) * (wd - 90),
                                        },
                                    ]}
                                ></View>
                            </View>
                        </View>
                    </View>

                    {
                        (questions[current_question_index].type_code != 'SQ' || questions[current_question_index].type_code != 'WQ') &&
                        <>
                            {
                                answer_result == "correct" &&
                                <>
                                    {
                                        (
                                            questions[current_question_index].type_code == 'MCQ' || questions[current_question_index].type_code == 'TF' || questions[current_question_index].type_code == 'SEQ' || questions[current_question_index].type_code == 'MTF' || questions[current_question_index].type_code == 'GLC' || questions[current_question_index].type_code == 'RC' ||
                                            questions[current_question_index].type_code == 'LST') && (
                                            <>
                                                <View style={tailwind` flex flex-row w-full mx-4 mt-4`}>
                                                    <View style={[tailwind`px-0`, styles.card_sec1]}>
                                                        <View style={tailwind``}>
                                                            <Image
                                                                source={require(`../../../../../assets/datacharacter_illustrations/${correct_lesson_images[Math.floor(Math.random() * correct_lesson_images.length)]}`)}
                                                                style={[tailwind``, styles.img]}
                                                            />
                                                        </View>
                                                    </View>

                                                    <View style={[tailwind`px-2 `, styles.card_sec2]}>
                                                        <Text style={[tailwind`text-black font-bold text-[22px]`]}>
                                                            You did it!
                                                        </Text>
                                                        <Text style={[tailwind`text-slate-500 font-semibold text-[18px]`]}>
                                                            That was correct.
                                                        </Text>
                                                    </View>
                                                </View>

                                                <View style={[tailwind`px-2 shadow-lg mx-4 rounded py-4 text-center items-center`]}>
                                                    <Text style={[tailwind`text-slate-500 font-semibold text-[18px]`]}>
                                                        That's correct
                                                    </Text>

                                                    {
                                                        (questions[current_question_index].type_code == 'MCQ' || questions[current_question_index].type_code == 'TF' || questions[current_question_index].type_code == 'RC' || questions[current_question_index].type_code == 'LST') &&
                                                        <Text style={[tailwind`text-slate-500 font-semibold text-[16px] items-center`]}>
                                                            {selected_answer.feedback}
                                                        </Text>
                                                    }

                                                    {
                                                        (questions[current_question_index].type_code == 'SEQ') &&
                                                        <Text style={[tailwind`text-slate-500 font-semibold text-[16px] items-center`]}>
                                                            {questions[current_question_index].feedback['correct']}
                                                        </Text>
                                                    }
                                                </View>

                                            </>

                                        )
                                    }
                                </>
                            }

                            {
                                answer_result == "incorrect" &&
                                <>
                                    {
                                        (questions[current_question_index].type_code == 'MCQ' || questions[current_question_index].type_code == 'TF' || questions[current_question_index].type_code == 'SEQ' || questions[current_question_index].type_code == 'MTF' || questions[current_question_index].type_code == 'GLC' || questions[current_question_index].type_code == 'RC' || questions[current_question_index].type_code == 'LST') && (<>
                                            <View style={tailwind` flex flex-row w-full mx-4 mt-4`}>
                                                <View style={[tailwind`px-0`, styles.card_sec1]}>
                                                    <View style={tailwind``}>
                                                        <Image source={require(`../../../../../assets/datacharacter_illustrations/${incorrect_lesson_images[Math.floor(Math.random() * incorrect_lesson_images.length)]}`)}
                                                            style={[tailwind``, styles.img]}
                                                        />
                                                    </View>
                                                </View>

                                                <View style={[tailwind`px-2 `, styles.card_sec2]}>
                                                    <Text style={[tailwind`text-black font-bold text-[22px]`]}>
                                                        Oh no!
                                                    </Text>
                                                    <Text style={[tailwind`text-slate-500 font-semibold text-[18px]`]}>
                                                        That's not correct.
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={[tailwind`px-2 shadow-lg mx-4 rounded py-4 text-center items-center`]}>
                                                <Text style={[tailwind`text-slate-500 font-semibold text-[18px]`]}>
                                                    Ah! It's the wrong answer.
                                                </Text>

                                                {
                                                    (questions[current_question_index].type_code == 'MCQ' || questions[current_question_index].type_code == 'TF' || questions[current_question_index].type_code == 'RC' || questions[current_question_index].type_code == 'LST') &&
                                                    <Text style={[tailwind`text-slate-500 font-semibold text-[16px] items-center`]}>
                                                        {selected_answer.feedback}
                                                    </Text>
                                                }

                                                {
                                                    (questions[current_question_index].type_code == 'SEQ') &&
                                                    <Text style={[tailwind`text-slate-500 font-semibold text-[16px] items-center`]}>
                                                        {questions[current_question_index].feedback['incorrect']}
                                                    </Text>
                                                }
                                            </View>
                                        </>
                                        )
                                    }

                                </>
                            }


                            {
                                (question_time_out && question_time_out == "yes") &&
                                <>
                                    {
                                        (questions[current_question_index].type_code == 'MCQ' || questions[current_question_index].type_code == 'TF' || questions[current_question_index].type_code == 'SEQ' || questions[current_question_index].type_code == 'MTF' || questions[current_question_index].type_code == 'GLC' || questions[current_question_index].type_code == 'RC' || questions[current_question_index].type_code == 'LST') && (<>
                                            <View style={tailwind` flex flex-row w-full mx-4 mt-4`}>
                                                <View style={[tailwind`px-0`, styles.card_sec1]}>
                                                    <View style={tailwind``}>
                                                        <Image source={require(`../../../../../assets/datacharacter_illustrations/${incorrect_lesson_images[Math.floor(Math.random() * incorrect_lesson_images.length)]}`)}
                                                            style={[tailwind``, styles.img]}
                                                        />
                                                    </View>
                                                </View>

                                                <View style={[tailwind`px-2 `, styles.card_sec2]}>
                                                    <Text style={[tailwind`text-black font-bold text-[22px]`]}>
                                                        Oh no!
                                                    </Text>
                                                    <Text style={[tailwind`text-slate-500 font-semibold text-[18px]`]}>
                                                        Time Up!
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={[tailwind`px-2 shadow-lg mx-4 rounded py-4 text-center items-center`]}>

                                                <Text style={[tailwind`text-slate-500 font-semibold text-[16px] items-center`]}>
                                                    You weren’t able to answer within given time.
                                                </Text>


                                            </View>
                                        </>
                                        )
                                    }

                                </>
                            }

                        </>
                    }

                    {
                        (questions[current_question_index].type_code == 'SQ' || questions[current_question_index].type_code == 'WQ') && answer_result &&
                        <>
                            <View style={tailwind` flex flex-row w-full text-center items-center`}>
                                <View style={[tailwind`w-full px-0 text-center items-center`]}>
                                    {
                                        answer_result == "correct" &&
                                        <Image
                                            source={require(`../../../../../assets/datacharacter_illustrations/${other_correct_lesson_images[Math.floor(Math.random() * other_correct_lesson_images.length)]}`)}
                                            style={[tailwind``, styles.img]}
                                        />
                                    }

                                    {
                                        answer_result == "incorrect" &&
                                        <Image
                                            source={require(`../../../../../assets/datacharacter_illustrations/${other_incorrect_lesson_images[Math.floor(Math.random() * other_incorrect_lesson_images.length)]}`)}
                                            style={[tailwind``, styles.img]}
                                        />
                                    }
                                </View>

                            </View>

                            <View style={[tailwind`px-2 shadow-lg mx-4 rounded py-4 text-center items-center`]}>
                                {
                                    answer_result == "correct" &&
                                    <Text style={[tailwind`text-black font-bold text-[20px]`]}>
                                        Awesome...
                                    </Text>
                                }

                                {
                                    answer_result == "incorrect" &&
                                    <Text style={[tailwind`text-black font-bold text-[20px]`]}>
                                        You Can Do Much Better!
                                    </Text>
                                }

                                <View style={tailwind`mt-5 flex flex-row w-full text-center items-center justify-center`}>

                                    {answer_result == "correct" && <View style={tailwind`flex flex-row text-center items-center`}>
                                        <Ionicons name="checkmark-circle-outline" size={23} color="#14b8a6" />
                                        <Text style={[tailwind`pl-2 text-slate-500 font-semibold text-[16px] items-center`]}>
                                            {text_eval_ans.relevance_score}/10
                                        </Text>
                                    </View>
                                    }

                                    {answer_result == "incorrect" && <View style={tailwind`flex flex-row text-center items-center justify-center`}>
                                        <Ionicons name="close-circle-outline" size={23} color="#eb0712" />
                                        <Text style={[tailwind`pl-2 text-slate-500 font-semibold text-[16px] items-center`]}>
                                            {text_eval_ans.relevance_score}/10
                                        </Text>
                                    </View>
                                    }
                                </View>

                                {text_eval_ans && text_eval_ans.comments && <View style={tailwind`mt-5 flex flex-row w-full text-center items-center justify-center`}>
                                    <Text style={tailwind`text-justify`}>{text_eval_ans.comments}</Text>
                                </View>}
                            </View>
                        </>
                    }


                    {
                        (questions[current_question_index].type_code == 'SQ' || questions[current_question_index].type_code == 'WQ') && (question_time_out && question_time_out == "yes" && answer_result == "") &&
                        <>
                            <View style={tailwind` flex flex-row w-full mx-4 mt-4`}>
                                <View style={[tailwind`px-0`, styles.card_sec1]}>
                                    <View style={tailwind``}>
                                        <Image source={require(`../../../../../assets/datacharacter_illustrations/${incorrect_lesson_images[Math.floor(Math.random() * incorrect_lesson_images.length)]}`)}
                                            style={[tailwind``, styles.img]}
                                        />
                                    </View>
                                </View>

                                <View style={[tailwind`px-2 `, styles.card_sec2]}>
                                    <Text style={[tailwind`text-black font-bold text-[22px]`]}>
                                        Oh no!
                                    </Text>
                                    <Text style={[tailwind`text-slate-500 font-semibold text-[18px]`]}>
                                        Time Up!
                                    </Text>
                                </View>
                            </View>

                            <View style={[tailwind`px-2 shadow-lg mx-4 rounded py-4 text-center items-center`]}>

                                <Text style={[tailwind`text-slate-500 font-semibold text-[16px] items-center`]}>
                                    You weren’t able to answer within given time.
                                </Text>


                            </View>
                        </>
                    }
                </ScrollView>

                <View style={[tailwind`absolute bottom-[20px]`, {
                    bottom: 4,
                    width: (wd - 40)
                }]}>
                    <Pressable
                        style={[tailwind``, styles.buttonStyle, {
                            backgroundColor: "#3EBD3E"
                        }]}
                        onPress={() => nextQuestion()}
                    >
                        <Text style={[styles.buttonText, {
                            color: "#FFFFFF"
                        }]}> Next </Text>
                    </Pressable>
                </View>


            </View>
        </Layout >
    );
}

const styles = StyleSheet.create({
    img: {
        resizeMode: "contain",
        width: 200,
        height: 200,
        // objectFit: "contain"
    },
    loadercontainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
        marginTop: 50,
        marginBottom: "50%",
    },
    activityIndicator: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
    },
    buttonStyle: {
        borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 18,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginVertical: 30,
    },
    buttonText: {
        fontWeight: "bold",
        // color: "#fff",
    },

    card_sec1: {
        width: "50%",
        // backgroundColor: "red"
        justifyContent: "center",
        alignItems: "center",
    },
    card_sec2: {
        width: "50%",
        justifyContent: "center",
        // alignItems: "center",
        // backgroundColor: "blue"
    },
    verticleLine: {
        height: '100%',
        width: 1,
        backgroundColor: '#909090',
    }
});
