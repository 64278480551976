import React, { useState, useEffect } from "react";
import {
	TouchableOpacity,
	View,
	ActivityIndicator,
	StyleSheet,
	FlatList,
	TextInput,
	Pressable,
	Keyboard,
} from "react-native";
import tailwind from "twrnc";
import { Feather } from "@expo/vector-icons";
import FilterModal from "../common/filter_modal";
import useClassesFilter from "../common/context";
import { TEACHERS } from "../../../../../api/instance";
import useUser from "../../../../../context/user";
import Text from "../../../../../components/Text";
import WebinarCalls from "./webinar_calls";
import moment from "moment";
import { Ionicons } from "@expo/vector-icons";

export default function Favourites() {
	let { user } = useUser();
	const [modalOpen, setModalOpen] = useState(false);
	let [calls, setCalls] = useState([]);
	let [loading, setLoading] = useState(true);

	let [webinarStatuses, setWebinarStatuses] = useState([]);
	let [search, setSearch] = useState("");
	let [isSearched, setIsSearched] = useState(false);
	let [currentPage, setCurrentPage] = useState(1);
	let [lastPage, setLastPage] = useState(0);
	let [totalRecords, setTotalRecords] = useState(0);
	let [loadMoreLoader, setLoadMoreLoader] = useState(false);

	// Access Range here
	const {
		range,
		setRange,
		status,
		setStatus,
		setStatuses,
		teacherSelected,
		setTeacherSelected,
		slot,
		setSlot,
	} = useClassesFilter();

	useEffect(() => {
		setDefaultFilterValue();
		// getWebinarClasses();
		getWebinarStatuses();
		setSearch("");

		if (user.is_live_teacher) {
			getTeacherWebinarClasses()
		} else {
			getWebinarClasses();
		}
	}, []);

	let setDefaultFilterValue = () => {
		setRange({
			startDate: new Date(),
			endDate: new Date(),
		});

		setStatus("");
		setTeacherSelected(null);
		setSlot("");
	};

	async function getWebinarStatuses() {
		await TEACHERS.get(`/statuses?type=WEBINAR`)
			.then((response) => {
				let res = response.data.data;
				setWebinarStatuses(response.data.data);
				let newArray = res.map((item, index) => {
					return { key: index + 1, value: item.slug };
				});
				setStatuses(newArray);
			})
			.catch((error) => {
				setWebinarStatuses([]);
				console.log(error.response);
			});
	}

	let handleSearch = () => {
		Keyboard.dismiss();
		if (search) {
			setIsSearched(true);
			setCalls([]);
			setLoadMoreLoader(false);
			setLoading(true);
			setCurrentPage(1);
			setLastPage(1);
			setTotalRecords(0);
			getWebinarClasses();
		} else {
			setIsSearched(false);
		}
	};

	let handleSearchClear = () => {
		Keyboard.dismiss();
		setSearch("");
		search = "";
		setIsSearched(false);
		setCalls([]);
		setLoadMoreLoader(false);
		setLoading(true);
		setCurrentPage(1);
		setLastPage(1);
		setTotalRecords(0);
		getWebinarClasses();
	};

	async function getWebinarClasses(getMore = false) {
		let params = {
			phone: user.phone,
			limit: 15,
			page: getMore ? currentPage + 1 : 1,
		};
		if (range && range.startDate && range.endDate) {
			params.start_date = moment(range.startDate).format("DD-MM-YYYY")
			params.end_date = moment(range.endDate).format("DD-MM-YYYY")
		}

		if (status) {
			params.status = status;
		}

		if (teacherSelected) {
			params.teacher_id = teacherSelected.id;
		}

		if (slot) {
			params.slot = slot;
		}

		if (search) {
			params.search = search;
		}

		await TEACHERS.post(`/webinars/all`, params)
			.then((response) => {
				setCalls((prev) => [...prev, ...response.data.data.data]);
				setLastPage(response.data.data.last_page);
				setTotalRecords(response.data.data.total);
				setLoading(false);
				setLoadMoreLoader(false);
			})
			.catch((error) => {
				console.log(error.response.data);
				setLoading(false);
				setLoadMoreLoader(false);
			});
	}

	async function getTeacherWebinarClasses(getMore = false) {
		let params = {
			phone: user.phone,
			app_user_id: user._id,
			limit: 15,
			page: getMore ? currentPage + 1 : 1,
		};
		if (range && range.startDate && range.endDate) {
			params.start_date = moment(range.startDate).format("DD-MM-YYYY")
			params.end_date = moment(range.endDate).format("DD-MM-YYYY")
		}

		if (status) {
			params.status = status;
		}

		if (teacherSelected) {
			params.teacher_id = teacherSelected.id;
		}

		if (slot) {
			params.slot = slot;
		}

		if (search) {
			params.search = search;
		}

		await TEACHERS.post(`/teacher/webinars/all`, params)
			.then((response) => {
				setCalls((prev) => [...prev, ...response.data.data.data]);
				setLastPage(response.data.data.last_page);
				setTotalRecords(response.data.data.total);
				setLoading(false);
				setLoadMoreLoader(false);
			})
			.catch((error) => {
				console.log(error.response.data);
				setLoading(false);
				setLoadMoreLoader(false);
			});
	}


	let renderLoader = () => {
		return loadMoreLoader ? (
			<View>
				<ActivityIndicator />
			</View>
		) : null;
	};

	let loadMoreItem = () => {
		if (!loadMoreLoader) {
			if (lastPage != currentPage) {
				setLoadMoreLoader(true);
				setCurrentPage((prev) => prev + 1);
				// getWebinarClasses(true);
				if (user.is_live_teacher) {
					getTeacherWebinarClasses(true)
				} else {
					getWebinarClasses(true);
				}
			} else setLoadMoreLoader(false);
		}
	};

	let filterApply = () => {
		setCalls([]);
		setLoadMoreLoader(false);
		setLoading(true);
		setCurrentPage(1);
		setLastPage(1);
		setTotalRecords(0);

		if (user.is_live_teacher) {
			getTeacherWebinarClasses()
		} else {
			getWebinarClasses();
		}
	};

	return (
		<View style={tailwind`w-full mt-5 flex-1`}>
			<View style={[tailwind`flex-row w-full`, styles.inputContainer]}>
				<View style={{ width: isSearched ? "95%" : "100%" }}>
					<TextInput
						style={styles.inputStyle}
						placeholder={"Search"}
						value={search}
						onChangeText={(item) => setSearch(item)}
					/>
					<Pressable onPress={handleSearch} style={styles.icon}>
						<Ionicons name="search" size={20} color="#2096F3" />
					</Pressable>
				</View>

				{isSearched && (
					<View>
						<Pressable onPress={handleSearchClear} style={styles.icon2}>
							<Ionicons name="close" size={20} color="#ef4444" />
						</Pressable>
					</View>
				)}
			</View>

			<FilterModal
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
				getClasses={filterApply}
			/>
			<TouchableOpacity
				onPress={() => setModalOpen(true)}
				style={tailwind`border border-[#9E9E9E] min-h-[80px] rounded px-5 py-3 w-full bg-[#fafafa] relative`}
			>
				<Feather
					style={tailwind`absolute right-[10px] top-[10px]`}
					name="filter"
					size={24}
					color="black"
				/>

				<View style={[tailwind`flex-row`]}>
					{range.startDate && range.endDate && (
						<View style={[tailwind`flex-row`]}>
							<Text
								style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-[12px]`}
							>
								{moment(range.startDate).format("DD-MM-YYYY")}
							</Text>
							<Text style={tailwind`text-center mx-1`}>-</Text>
							<Text
								style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-[12px]`}
							>
								{moment(range.endDate).format("DD-MM-YYYY")}
							</Text>
						</View>
					)}

					{status && (
						<Text
							style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-xs text-[12px] mx-2`}
						>
							{status}
						</Text>
					)}

					{slot && (
						<Text
							style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-xs text-[12px] mx-2`}
						>
							{slot}
						</Text>
					)}

					{teacherSelected && (
						<Text
							style={tailwind`text-center bg-blue-400 text-white text-base py-0 px-1 rounded dark:bg-blue-400 text-xs text-[12px] mx-2`}
						>
							{teacherSelected.name}
						</Text>
					)}
				</View>

				{(!range.startDate && !status && !slot && !teacherSelected) && (
					<View style={[tailwind`flex-row`]}>
						<Text>Add Filters</Text>
					</View>
				)}
			</TouchableOpacity>

			{!loading && calls.length > 0 && (
				<View style={tailwind`mt-2 flex-1`}>
					<Text style={tailwind`text-blue-500 font-bold`}>
						Total Records : {calls.length}/{totalRecords}
					</Text>

					<FlatList
						data={calls}
						renderItem={({ item }) => (
							<WebinarCalls item={item} getWebinarClasses={getWebinarClasses} />
						)}
						keyExtractor={(item, i) => i}
						ListFooterComponent={renderLoader}
						onEndReached={loadMoreItem}
						onEndReachedThreshold={0.2}
						progressViewOffset={5}
					/>
				</View>
			)}

			{loading && !loadMoreLoader && (
				<View style={[tailwind` my-4`, styles.loadercontainer]}>
					<ActivityIndicator />
				</View>
			)}

			{!loading && !loadMoreLoader && calls.length == 0 && (
				<View
					style={[tailwind` my-4 w-full justify-center h-100 items-center`]}
				>
					<Text>Records Not Found.</Text>
				</View>
			)}
		</View>
	);
}

const styles = StyleSheet.create({
	loadercontainer: {
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		verticalAlign: "middle",
		marginTop: 40,
	},
	activityIndicator: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		verticalAlign: "middle",
	},

	icon: {
		position: "absolute",
		right: 10,
		textAlign: "center",
		margin: "auto",
		justifyContent: "center",
		height: "100%",
		alignItems: "center",
		flex: 1,
		// top: "20%"
	},
	icon2: {
		position: "absolute",
		// right: 10,
		textAlign: "center",
		margin: "auto",
		justifyContent: "center",
		height: "100%",
		alignItems: "center",
		flex: 1,
		// top: "20%"
	},
	inputContainer: {
		marginBottom: 10,
		marginTop: 0,
	},
	inputStyle: {
		// borderWidth: 1,
		borderBottomWidth: 1.4,
		borderColor: "rgba(0, 0, 0, 0.3)",
		paddingHorizontal: 0,
		paddingVertical: 0,
		borderRadius: 2,
	},
});
