import React from "react";
import { WebView } from "react-native-webview";
import { height, isWeb, width } from "../../../../../../../constants/constants";
import { View } from "react-native";
import tailwind from "twrnc";
import SecondaryHeader from "../../../../../headers/secondary.header";
import Header from "../../../../../headers/header";

const WebviewRN = ({ route }) => {
  const { url } = route.params;

  const isPdf = url.includes("pdf");

  if (isWeb) {
    return (
      <>
        {!isPdf && (
          <Header headerHeight={65}>
            <SecondaryHeader title="Note" />
          </Header>
        )}
        <View style={tailwind`w-[${width}px] h-[${height}px] relative`}>
          <iframe src={url} width={width} height={height - 65}></iframe>

          {isPdf && (
            <View
              style={tailwind`absolute top-0 left-0 w-full h-[65px] bg-white`}
            >
              <Header headerHeight={65}>
                <SecondaryHeader title="Note" />
              </Header>
            </View>
          )}
        </View>
      </>
    );
  }
  return (
    <>
      <Header headerHeight={65}>
        <SecondaryHeader title="Note" />
      </Header>
      <WebView source={{ uri: url }} style={{ flex: 1 }} />
    </>
  );
};

export default WebviewRN;
