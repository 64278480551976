import React from "react";
import Text from "../../../../../../components/Text";
import tailwind from "twrnc";
import { Linking, TouchableOpacity, View } from "react-native";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import moment from "moment";
import useUser from "../../../../../../context/user";
import useChat from "../context";

const FileMessage = (props) => {
  const { user } = useUser();
  const { currentMessage: message } = props;

  const { report, messageReportIds, setMessageReportIds } = useChat();

  const is_deleted = message.is_deleted;
  const is_flagged = message.is_flagged;
  const showCheck =
    user._id !== message.sender_id && report && !is_deleted && !is_flagged;

  const isSender = message.sender_id === user._id;

  const checked = messageReportIds.includes(message._id);

  const isVisible = is_deleted || is_flagged ? isSender : true;

  const handleToggle = () => {
    if (messageReportIds.includes(message._id)) {
      setMessageReportIds(messageReportIds.filter((id) => id !== message._id));
    } else {
      setMessageReportIds([...messageReportIds, message._id]);
    }
  };

  const getFileText = () => {
    if (is_deleted && !isVisible) {
      return "File Deleted";
    }

    if (is_flagged && !isVisible) {
      return "File Flagged";
    }

    return message.file_name;
  };

  return (
    <View
      style={tailwind`flex ${showCheck ? "flex-row items-center" : "flex-col"}`}
    >
      {showCheck && (
        <TouchableOpacity
          onPress={handleToggle}
          style={tailwind`w-[15px] h-[15px]  rounded-full mr-2 ${
            checked ? "bg-blue-600" : "border"
          }`}
        ></TouchableOpacity>
      )}

      <TouchableOpacity
        onPress={() => Linking.openURL(message.file)}
        style={tailwind.style(
          "flex-row items-center bg-gray-100 rounded-lg p-2"
        )}
      >
        <MaterialCommunityIcons
          name="file-outline"
          size={24}
          color="#4A4A4A"
          style={tailwind.style("mr-2")}
        />
        <View>
          <Text
            style={tailwind.style(
              `${isVisible ? "text-blue-500" : "text-[#8B8B8B]"}  max-w-[180px]`
            )}
          >
            {getFileText()}
          </Text>
        </View>
        {/* Assuming you have a timestamp or similar in your message object */}
        <Text style={tailwind.style("text-gray-500 text-[10px] ml-2")}>
          {" "}
          {moment.unix(message.time_seconds).format("hh:mm a")}
        </Text>
      </TouchableOpacity>

      {is_flagged && (
        <View
          style={tailwind`flex flex-row items-center gap-x-1 ${
            props.position === "left" ? "" : "flex-row-reverse"
          }`}
        >
          <Ionicons name="flag" size={20} color="#FD891E" />
          <Text
            style={tailwind`max-w-[180px] mb-1 text-[#FD891E] text-[12px] mt-1 ${
              props.position === "left" ? "" : "text-right"
            }`}
          >
            {message.flagged_by === "AUTO"
              ? "This file has been flagged by the system. Please wait for the Admin to review it"
              : "This file has been reported"}
          </Text>
        </View>
      )}

      {is_deleted && (
        <View
          style={tailwind`flex flex-row items-center gap-x-1 ${
            props.position === "left" ? "" : "flex-row-reverse"
          }`}
        >
          <MaterialIcons name="delete" size={20} color="#FF5555" />

          <Text
            style={tailwind`max-w-[180px] mb-1 text-[#FF5555] text-[12px] mt-1 ${
              props.position === "left" ? "" : "text-right"
            }`}
          >
            This message has been deleted by the Admin
          </Text>
        </View>
      )}
    </View>
  );
};

export default FileMessage;
