import { createContext, useContext, useEffect, useState } from "react";
import useUser from "./user";
import { TEACHERS, API } from "../api/instance";
const AppCoursesContext = createContext();

const useAppCourses = () => useContext(AppCoursesContext);

export const AppCoursesContextProvider = ({ children }) => {
    const { user, isTeacher } = useUser();
    let [app_courses, setAppCourses] = useState([]);
    let [app_raw_courses, setAppRawCourses] = useState([]);


    const getAppCourses = async () => {
        try {
            const { data } = await API.get(
                `/courses`
            );

            // console.log("getAppCourses ++++++++++++++++++++++++++++++")
            // console.log(data.data)

            setAppCourses(data.data);

            // console.log("app_courses")
            // console.log(app_courses)
        } catch (error) {
            console.warn(
                "Error occured while fetching app courses",
                JSON.stringify(error)
            );
        }
    };

    const getAppCoursesHome = async () => {
        try {
            // console.log("user._id : ", user._id)

            let uid = "5bef8fdeb1d6dd001086139c"
            // let uid = "5c67ba6071e7f500100242b2"

            // let uid = user._id
            const { data } = await API.get(
                `/homepagecourse/${uid}`
            );

            let res = data.data

            setAppRawCourses(res)
            // console.log(res)


            let obj_keys = Object.keys(res)
            let courses = []
            for (let index = 0; index < obj_keys.length; index++) {
                let ky = obj_keys[index]
                let items = res[ky]
                let progress = 0;
                let total_lessons = 0
                let crs = null

                // console.log("items items")
                // console.log("items.length : ", items.length)
                if (items.length > 1) {
                    crs = items[0]
                    for (let index1 = 0; index1 < items.length; index1++) {
                        let item = items[index1]
                        progress = progress + item.progress
                        total_lessons = total_lessons + item.total_lessons
                    }
                    crs.progress = progress / items.length
                    crs.total_lessons = total_lessons
                    courses.push(crs)
                } else {
                    courses.push(items[0])
                }
            }
            // console.log(courses)
            setAppCourses(courses);
        } catch (error) {
            console.warn(
                "Error occured while fetching getAppCoursesHome",
                JSON.stringify(error)
            );
        }
    };

    useEffect(() => {
        if (user) {
            // console.log("innnnnnnnnnnnnnnn")
            getAppCoursesHome()
        }
    }, [user]);

    return (
        <AppCoursesContext.Provider
            value={{
                app_courses,
                app_raw_courses
            }}
        >
            {children}
        </AppCoursesContext.Provider>
    );
};

export default useAppCourses;
