import React, { useState, useEffect } from "react";
import { Image, View, ScrollView, Pressable } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import useAppCourses from "../../../../../context/app_courses";
import { useNavigation } from "@react-navigation/native";
import useQuestion from "../../courses/partials/common/context";
import useUser from "../../../../../context/user";
import { TEACHERS, API } from "../../../../../api/instance";

const LearnOnYourOwn = () => {
    let { app_courses, app_raw_courses } = useAppCourses();
    const { navigate } = useNavigation();
    const { user } = useUser();

    const {
        setAllModuleStates,
        setModuleStates,
        setSelectedCourse,
        setModule,
        setLevel,
        setCurrentLevelIndex,
        setUnit,
        setCurrentUnitIndex,
        setLesson,
        setCurrentLessonIndex,
        setSelectedAnswer,
        setQuestions,
        setQuestionIds,
        setCurrentQuestionIndex,
        setAnswerResult,
        setQuestion,
        setChecked,
        setLessonStartTimestamp,
        setLessonEndTimestamp,
        setTotalCorrectAnswer,
        setTotalIncorrectAnswer,
        setTotalQuestionAttempt,
        setQuestionActivity,
        setQuestionStartTimestamp,
        setQuestionEndTimestamp,
        setTotalQuestion } = useQuestion();

    useEffect(() => {

    }, []);

    let handleRedirect = async (item) => {
        setSelectedCourse(null)
        setModuleStates(null)
        setAllModuleStates([])
        setModule(null)
        setLevel(null)
        setCurrentLevelIndex(0)
        setUnit(null)
        setCurrentUnitIndex("")
        setLesson(null)
        setCurrentLessonIndex("")
        setSelectedAnswer(null)
        setQuestions([])
        setQuestionIds([])
        setCurrentQuestionIndex("")
        setAnswerResult("")
        setQuestion(null)
        setChecked(null)
        setLessonStartTimestamp(0)
        setLessonEndTimestamp(0)
        setTotalCorrectAnswer(0)
        setTotalIncorrectAnswer(0)
        setTotalQuestionAttempt(0)
        setQuestionActivity([])
        setQuestionStartTimestamp(0)
        setQuestionEndTimestamp(0)
        setTotalQuestion(0)
        let crs = app_raw_courses[item.target_language]

        if (crs.length > 1) {
            let obj = {
                current_course: item,
                courses: crs,
                from_page: "Home"
            }
            navigate("MultipleSourceLanguages", { obj })
        } else {
            setSelectedCourse(item)

            navigate("Modules", {
                from_page: "Home"
            })

            let { data: results } = await getModuleState(item._id)

            if (results) {
                let course = item

                user.current_course_id = course._id
                user.current_course_state = {
                    course_id: course._id,
                    course_name: course.display_text,
                    sourceLanguage: course.source_language,
                    target_language: course.target_language
                }

                let course_states = (user.course_states && user.course_states.length > 0) ? user.course_states : []

                if (course_states.length == 0) {
                    user.course_states = []
                    user.course_states.push({
                        course_id: course._id,
                        course_name: course.display_text,
                        sourceLanguage: course.source_language,
                        target_language: course.target_language
                    })
                } else {
                    let obj = user.course_states.find(o => o.course_id === course._id);
                    if (!obj) {
                        user.course_states.push({
                            course_id: course._id,
                            course_name: course.display_text,
                            sourceLanguage: course.source_language,
                            target_language: course.target_language
                        })
                    }
                }

                updateUserCourse(user)

                if (results?.data?.length == 0) {
                    for (let index = 0; index < course.modules.length; index++) {
                        let module = course.modules[index]

                        let unit_states = []
                        let lesson_states = []

                        for (let index1 = 0; index1 < module.levels[0].units.length; index1++) {
                            let unit = module.levels[0].units[index1]
                            let data = {
                                is_current: (index1 == 0) ? true : false,
                                is_finished: false,
                                is_skipped: false,
                                is_unlocked: true,
                                unit_id: unit._id
                            }
                            unit_states.push(data)
                            let lessons = unit.lessons
                            for (let index2 = 0; index2 < lessons.length; index2++) {
                                let lesson = lessons[index2]
                                let lsn_data = {
                                    is_current: (index1 == 0 && index2 == 0) ? true : false,
                                    is_finished: false,
                                    is_skipped: false,
                                    is_unlocked: true,
                                    lesson_id: lesson._id
                                }
                                lesson_states.push(lsn_data)
                            }
                        }

                        let data = {
                            course_id: course._id,
                            module_type: module.type,
                            user_id: user._id,
                            module_state: {
                                current_lesson_id: module.levels[0].units[0].lessons[0]._id,
                                current_level_id: module.levels[0]._id,
                                current_unit_id: module.levels[0].units[0]._id,
                                module_type: module.type,
                                lesson_states: lesson_states,
                                level_states: [{
                                    is_current: true,
                                    is_finished: false,
                                    is_skipped: false,
                                    is_unlocked: true,
                                    level_id: module.levels[0]._id
                                }],
                                unit_states: unit_states
                            }
                        }
                        updateModuleState(data)
                    }

                }
            }
        }
    }

    async function getModuleState(course_id) {
        let params = {
            course_id: course_id,
        };
        return await API.get(`/course_module_state/${user._id}`, { params })
    }

    async function updateModuleState(data) {
        await API.post(`/module_state`, data)
            .then((response) => {
                let res = response.data
                if (res?.data)
                    setModuleStates(res.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function updateUserCourse(data) {
        await API.put(`/user/${user._id}`, data)
            .then((response) => {
                let res = response.data
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <View style={tailwind`w-full`}>
            {app_courses.length > 0 && (
                <>
                    <View
                        style={tailwind`flex flex-row justify-between w-full items-center`}
                    >
                        <Text style={tailwind`font-bold mt-5 mb-5 text-[18px]`}>
                            Learn On Your Own
                        </Text>

                        <Text
                            onPress={() => navigate("Courses")}
                            style={tailwind`text-[#2096F3] font-semibold`}
                        >
                            View All
                        </Text>
                    </View>

                    <ScrollView
                        horizontal
                        style={{ maxWidth: "100%", }}
                    >
                        {app_courses.map((item, i) => (
                            <Pressable
                                onPress={() => handleRedirect(item)}
                                key={i}
                            >
                                <View key={i} style={tailwind`mr-2 bg-slate-100`}>
                                    <View style={tailwind`mt-1 w-full bg-slate-300 rounded-full h-2 dark:bg-gray-700`}>
                                        <View style={[tailwind`bg-green-600 h-2 rounded-full`, { width: (item.progress) ? item.progress + "%" : 0 }]}>

                                        </View>
                                    </View>
                                    <Text style={tailwind`text-[10px] text-center`}>{(item.progress) ? item.progress : 0}%</Text>

                                    <Image
                                        source={(item.target_language == "english") ? require("../../../../../../assets/english.png") : { uri: item?.image }}
                                        style={tailwind`w-[110px] h-[110px] rounded`}
                                    />

                                    <Text
                                        style={tailwind`capitalize text-slate-500 w-[100px] text-[16px] text-center mt-2 font-semibold`}
                                    >
                                        {item.target_language}
                                    </Text>

                                    <Text style={tailwind`text-[10px] text-slate-500 w-[100px] text-center`}> {item.total_lessons} Free Lessons</Text>
                                </View>
                            </Pressable>

                        ))}
                    </ScrollView>
                </>
            )}
        </View>
    );

};

export default LearnOnYourOwn;
