import {
	View,
	Text,
	Image,
	StyleSheet,
	TouchableOpacity,
	Alert,
} from "react-native";
import { React, useState } from "react";
import tailwind from "twrnc";
import { Ionicons, AntDesign } from "@expo/vector-icons";
import moment from "moment";
import Modal from "../../../../../components/modal";
import useUser from "../../../../../context/user";
import { TEACHERS } from "../../../../../api/instance";
import ChatButton from "../chat_button";
import { useNavigation } from "@react-navigation/native";
import NotesDialog from "../../home/classes/upcoming_section/notes/notes_dialog";
import QuizDialog from "../../quizzes/quiz_dialog"

export default function WebinarCalls({ item, getWebinarClasses }) {
	const { navigate } = useNavigation();

	let webinar = {};
	let teachers = [];

	if (item.webinar) webinar = item.webinar;

	if (item.classTeachers && item.classTeachers.length > 0)
		teachers = item.classTeachers;

	let { user } = useUser();
	const [warningModalOpen, setWarningModalOpen] = useState(false);
	const [removedFavouritesLoading, setRemovedFavouritesLoading] =
		useState(false);
	const [currentItem, setCurrentItem] = useState(null);

	let removeFromFavouritesModelOpen = (item) => {
		setCurrentItem(item);
		setRemovedFavouritesLoading(false);
		setWarningModalOpen(true);
	};

	let removeFromFavourites = async () => {
		setRemovedFavouritesLoading(true);
		let payload = {
			phone: user.phone,
			webinar_id: currentItem.webinar.id,
		};

		await TEACHERS.post(`/webinar/removefavourite`, payload)
			.then(() => {
				setRemovedFavouritesLoading(false);
				setWarningModalOpen(false);
				getWebinarClasses();
				Alert.alert("Removed From Favourites Successfully");
			})
			.catch((error) => {
				console.log(error.response);
				setRemovedFavouritesLoading(false);
				Alert.alert("Something went wrong");
			});
	};

	const [notesModalOpen, setNotesModalOpen] = useState(false);

	const [quizModalOpen, setQuizModalOpen] = useState(false);
	const [quizzes, setQuizzes] = useState([]);
	const [call, setCall] = useState(null);

	const handleQuiz = async (item, cls) => {
		setQuizzes(item)
		setCall(cls)
		setQuizModalOpen(true)
	};

	return (
		<View>
			<View style={tailwind`bg-gray-200 mx-auto my-1 w-full`}>
				<View style={tailwind` p-2`}>
					<View style={tailwind` flex flex-row w-full`}>
						<View style={[tailwind`px-0`, styles.card_sec1]}>
							<View style={tailwind``}>
								<Image
									source={{ uri: teachers[0]?.profile?.avatar }}
									style={tailwind`w-[85px] h-[85px] rounded`}
								/>
								<Text style={tailwind`text-left py-1 text-[12px]`}>
									{teachers[0]?.name}
								</Text>
							</View>
						</View>

						<View style={[tailwind`px-2 `, styles.card_sec2]}>
							<Text>{item.topic}</Text>
							<View style={[tailwind`flex-row flex items-center pt-2`]}>
								<ChatButton class_user={user} teacher={teachers[0]} />
								<Text style={[tailwind`pl-1 text-[12px]`]}>
									{teachers[0]?.name}
								</Text>
							</View>

							<View style={[tailwind`flex-row pt-1 flex items-center`]}>
								<Ionicons name="calendar-outline" size={18} color="#878787" />
								<Text style={[tailwind`pl-1 text-[12px]`]}>
									{moment.unix(item.slot / 1000).format("hh:mm A MMM Do")}
								</Text>
							</View>

							{item.status == "complete" && (
								<View style={[tailwind`flex-row pt-1`]}>
									<TouchableOpacity
										style={tailwind`flex flex-row items-center gap-x-1`}
										onPress={() =>
											navigate("Recording", {
												type: "WEBINAR",
												id: +item.id,
											})
										}
									>
										<Ionicons name="play-outline" size={18} color="#878787" />
										<Text
											style={tailwind`text-[#f5b436] text-[12px] mt-[-2px] underline `}
										>
											View Recording
										</Text>
									</TouchableOpacity>
								</View>
							)}

							{(item.attached_quizzes?.length > 0 || item.attached_quizzes?.length > 0) && (
								<>
									{call && <QuizDialog
										open={quizModalOpen}
										setOpen={setQuizModalOpen}
										quizzes={quizzes}
										call={call}
									/>
									}

									<View style={[tailwind`flex-row pt-1`]}>
										<TouchableOpacity
											onPress={() => handleQuiz(item.attached_quizzes, item)}
											style={tailwind`flex flex-row items-center gap-x-1`}
										>
											<Ionicons
												name="file-tray-full-outline"
												size={18}
												color="#878787"
											/>
											<Text style={tailwind`text-[12px] mt-[-2px] underline `}>
												Quizzes
											</Text>
										</TouchableOpacity>
									</View>
								</>
							)}

							{(item.noteFiles?.length > 0 || item.noteLinks?.length > 0) && (
								<>
									<NotesDialog
										open={notesModalOpen}
										setOpen={setNotesModalOpen}
										session={item}
									/>
									<View style={[tailwind`flex-row pt-1`]}>
										<TouchableOpacity
											onPress={() => setNotesModalOpen(true)}
											style={tailwind`flex flex-row items-center gap-x-1`}
										>
											<Ionicons
												name="document-text-outline"
												size={18}
												color="#878787"
											/>
											<Text style={tailwind`text-[12px] mt-[-2px] underline `}>
												View Class Material
											</Text>
										</TouchableOpacity>
									</View>
								</>
							)}

							<View style={[tailwind`flex-row pt-1`]}>
								<TouchableOpacity
									onPress={() => {
										removeFromFavouritesModelOpen(item);
									}}
									style={tailwind`flex flex-row items-center gap-x-1`}
								>
									<Ionicons name="bookmark-outline" size={18} color="#878787" />
									<Text style={tailwind`text-[12px] mt-[-2px] underline `}>
										Remove From Favourites
									</Text>
								</TouchableOpacity>
							</View>
						</View>

						<View style={[tailwind`px-0 `, styles.card_sec3]}>
							{item.status == "complete" && (
								<Text
									style={tailwind`text-center bg-[#4caf50] text-white text-xs p-0.5 rounded dark:bg-[#4caf50] dark:text[#4caf50]`}
								>
									Complete
								</Text>
							)}

							{item.status == "canceled" && (
								<Text
									style={tailwind`text-center bg-[#f44336] text-white text-xs p-0.5 rounded dark:bg-[#f44336] dark:text[#f44336]`}
								>
									Cancelled
								</Text>
							)}

							{item.status == "scheduled" && (
								<Text
									style={tailwind`text-center bg-[#2196f3] text-white text-xs p-0.5 rounded dark:bg-[#2196f3] dark:text[#2196f3]`}
								>
									Scheduled
								</Text>
							)}

							<View style={[tailwind`flex-row pt-2`]}>
								<Text
									style={tailwind`text-center bg-[#607d8b] text-white text-base py-0.5 px-1 rounded dark:bg-[#607d8b] dark:text[#607d8b] text-xs`}
								>
									{webinar.duration} mins
								</Text>
							</View>
						</View>
					</View>
				</View>
			</View>

			<Modal
				visible={warningModalOpen}
				setVisible={setWarningModalOpen}
				maxHeight={250}
			>
				<View>
					<TouchableOpacity style={[tailwind`flex-row justify-end`]}>
						<AntDesign
							onPress={() => setWarningModalOpen(false)}
							name="close"
							size={24}
							color="black"
						/>
					</TouchableOpacity>

					<View style={tailwind` mx-4`}>
						<Text
							style={tailwind`text-center font-bold text-gray-500 text-[18px]`}
						>
							Remove From Favourites
						</Text>
					</View>

					<View style={tailwind` m-4`}>
						<Text style={tailwind`text-center text-gray-500 text-[14px]`}>
							If you proceed, this batch will not be available in your favourite
							section and you will stop receiving notifications related to it.
						</Text>
					</View>

					{!removedFavouritesLoading && (
						<TouchableOpacity
							style={[
								tailwind` mx-4 bg-blue-500 text-white font-bold py-2 px-4 rounded`,
							]}
							onPress={removeFromFavourites}
						>
							<Text
								style={[tailwind`text-white text-center font-bold text-[16px]`]}
							>
								{" "}
								Remove From Favourites{" "}
							</Text>
						</TouchableOpacity>
					)}

					{removedFavouritesLoading && (
						<TouchableOpacity
							style={[
								tailwind` mx-4 bg-gray-500 text-white font-bold py-2 px-4 rounded`,
							]}
							onPress={null}
						>
							<Text
								style={[tailwind`text-white text-center font-bold text-[16px]`]}
							>
								{" "}
								Removing...{" "}
							</Text>
						</TouchableOpacity>
					)}
				</View>
			</Modal>
		</View>
	);
}

const styles = StyleSheet.create({
	loadercontainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginTop: "50%",
		marginBottom: "50%",
	},
	activityIndicator: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	card_sec1: {
		width: "26%",
		// backgroundColor: "red"
	},
	card_sec2: {
		width: "55%",
		// backgroundColor: "blue"
	},
	card_sec3: {
		width: "19%",
	},
});
