import React from "react";
import { Image } from "react-native";
import Carousel from "react-native-reanimated-carousel";
import tailwind from "twrnc";
import { width } from "../../../constants/constants";

const CarouselLogin = () => {
  return (
    <Carousel
      loop
      width={width < 500 ? width : 500}
      height={"300"}
      autoPlay={true}
      data={[...new Array(3).keys()]}
      scrollAnimationDuration={3000}
      renderItem={({ index }) => {
        const imageSrc = [
          require("../../../../assets/login_carousel_1.png"),
          require("../../../../assets/login_carousel_2.png"),
          require("../../../../assets/login_carousel_3.png"),
        ];
        return (
          <>
            <Image
              source={imageSrc[index]}
              style={tailwind`w-[100%] h-[100%]`}
            />
          </>
        );
      }}
    />
  );
};

export default CarouselLogin;
