import { useEffect, useState } from "react";
import SecondaryHeader from "../../headers/secondary.header";
import { ActivityIndicator, FlatList, View } from "react-native";
import Text from "../../../../components/Text";
import { TEACHERS } from "../../../../api/instance";
import useUser from "../../../../context/user";
import tailwind from "twrnc";
import NotificationItem from "./notification_item";
import Layout from "../../layout";
import { useNavigation } from "@react-navigation/native";
import Alert from "../../../../utils/alert";

const Notifications = () => {
  const { user, isTeacher } = useUser();
  const { goBack } = useNavigation();
  const [notifications, setNotifications] = useState({
    data: [],
    page: 0,
    listEnd: false,
    moreLoading: false,
  });

  const [loading, setLoading] = useState(true);

  const getNotifications = async (page = 1) => {

    const path = isTeacher
      ? `/messages/teachers/${user._id}?limit=15&page=${page}`
      : `/messages/users`;

    try {
      if (isTeacher) {
        let { data } = await TEACHERS.get(path);
        if (page === 1) {
          setNotifications({ ...data.data, moreLoading: false });
        }

        if (page > 1) {
          setNotifications((prev) => {
            return {
              ...data.data,
              moreLoading: false,
              data: [...prev.data, ...data.data.data],
            };
          });
        }

        setLoading(false);
      } else {
        let params = {
          phone: user.phone,
          page: page,
          limit: 15
        }

        let { data } = await TEACHERS.post(path, params);

        if (page === 1) {
          setNotifications({ ...data.data, moreLoading: false });
        }

        if (page > 1) {
          setNotifications((prev) => {
            return {
              ...data.data,
              moreLoading: false,
              data: [...prev.data, ...data.data.data],
            };
          });
        }

        setLoading(false);
      }


    } catch (error) {
      Alert.alert("An error occured");
      goBack();
    }
  };

  const getMoreData = () => {
    if (notifications.page === notifications.lastPage) {
      return setNotifications((prev) => ({ ...prev, listEnd: true }));
    }

    if (!notifications.moreLoading) {
      setNotifications((prev) => ({ ...prev, moreLoading: true }));
      getNotifications(notifications.page + 1);
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Layout
      tabbarVisible={false}
      HeaderContent={<SecondaryHeader title="Notifications" />}
      scrollView={false}
    >
      <View style={tailwind`w-full`}>
        {loading ? (
          <View style={tailwind`w-full h-full flex items-center mt-10`}>
            <Text>Loading...</Text>
          </View>
        ) : (
          <>
            {!notifications.data[0] ? (
              <View>
                <Text>No Notifications</Text>
              </View>
            ) : (
              <FlatList
                data={notifications.data}
                renderItem={({ item }) => (
                  <NotificationItem notification={item} />
                )}
                ListFooterComponent={
                  <Footer
                    isEnd={notifications.listEnd}
                    isLoading={notifications.moreLoading}
                  />
                }
                onEndReached={getMoreData}
                onEndReachedThreshold={0.2}
                contentContainerStyle={tailwind`mt-5 mb-10`}
              />
            )}
          </>
        )}
      </View>
    </Layout>
  );
};

export const Footer = ({ isEnd, isLoading }) => {
  return (
    <View style={tailwind`mb-[100px] flex justify-center items-center`}>
      {isLoading && <ActivityIndicator />}
      {isEnd && <Text>No more notifications</Text>}
    </View>
  );
};

export default Notifications;
