import AuthLayout from "../layout";
import { Image, Pressable, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../components/Text";
import { PasswordInput } from "../../../components/password-input";
import { BarPasswordStrengthDisplay as StrengthBar } from "react-native-password-strength-meter";
import { useState } from "react";
import { API } from "../../../api/instance";
import useUser from "../../../context/user";
import LocalStorage from "../../../utils/localStorage";
import Alert from "../../../utils/alert";

const CreatePassword = ({ route }) => {
    const [password, setPassword] = useState("");
    const [repeatPass, setRepeatPass] = useState("");

    const [loading, setLoading] = useState(false);

    const { checkIfLoggedIn } = useUser();

    const { otp_id, otp } = route.params.params;

    const handleSubmit = async () => {
        if (password !== repeatPass) Alert.alert("Passwords do not match");
        try {
            setLoading(true);

            const { data } = await API.post("/auth/set-password", {
                otp,
                otp_id,
                password,
            });

            await LocalStorage.setItem("user", JSON.stringify(data));

            setLoading(false);
            checkIfLoggedIn();
        } catch (error) {
            console.log(error);
            setLoading(false);
            Alert.alert("An error occured");
        }
    };

    const btnDisabled = loading;

    return (
        <AuthLayout showHeader={false}>
            <Image
                source={require("../../../../assets/logo_with_shadow.png")}
                style={tailwind`w-[80px] h-[80px]`}
            ></Image>

            <Text style={tailwind`font-bold mt-5 text-[20px]`}>
                Create a Password
            </Text>

            <View>
                <Text style={tailwind`mb-2 text-[14px] mt-5`}>Enter new password</Text>
                <PasswordInput password={password} setPassword={setPassword} />
            </View>

            <View>
                <Text style={tailwind`mb-2 text-[14px] mt-5`}>Repeat password</Text>
                <PasswordInput password={repeatPass} setPassword={setRepeatPass} />
            </View>

            <StrengthBar
                password={password}
                wrapperStyle={tailwind`mt-5`}
                levels={[
                    {
                        label: "Very weak",
                        labelColor: "#ff5400",
                        activeBarColor: "#ff5400",
                    },
                    {
                        label: "Weak",
                        labelColor: "#ff6900",
                        activeBarColor: "#ff6900",
                    },

                    {
                        label: "Average",
                        labelColor: "#f3d331",
                        activeBarColor: "#f3d331",
                    },
                    {
                        label: "Fair",
                        labelColor: "#f2cf1f",
                        activeBarColor: "#f2cf1f",
                    },
                    {
                        label: "Strong",
                        labelColor: "#14eb6e",
                        activeBarColor: "#14eb6e",
                    },
                    {
                        label: "Very strong",
                        labelColor: "#0af56d",
                        activeBarColor: "#0af56d",
                    },
                ]}
            />

            <Pressable
                disabled={btnDisabled}
                onPress={handleSubmit}
                style={tailwind`mt-5 flex absolute bottom-[100px] items-center justify-center py-3 w-[200px] rounded-lg ${btnDisabled ? "bg-[#c1c1c1]" : "bg-[#14C761]"
                    }`}
            >
                <Text style={tailwind`text-[#fff] font-bold text-[18px]`}>
                    {loading ? "Loading..." : "Submit"}
                </Text>
            </Pressable>
        </AuthLayout>
    );
};

export default CreatePassword;
