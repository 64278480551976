import moment from "moment";
import React, { useState } from "react";
import { Alert, View, Text } from "react-native";
import {
	DatePickerModal,
	en,
	registerTranslation,
} from "react-native-paper-dates";
import tailwind from "twrnc";
import useClassesFilter from "./context";

import { height, isWeb } from "../../../../../constants/constants";

export default function RangePicker({ range, setRange, open, setOpen }) {
	const { errorMessage, setErrorMessage, st_date_validate, ed_date_validate } =
		useClassesFilter();

	const onDismiss = React.useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	const disabledEndDate = ed_date_validate && Number(ed_date_validate) > 0
		? moment(Number(ed_date_validate)).toDate()
		: undefined;

	const disabledStartDate = st_date_validate && Number(st_date_validate) > 0
		? moment(Number(st_date_validate)).toDate()
		: undefined;

	const onConfirm = React.useCallback(

		({ startDate, endDate }) => {
			if (!startDate || !endDate) {
				return Alert.alert("Please choose start date and end date");
			}
			const startMoment = moment(startDate);
			const endMoment = moment(endDate);

			// const monthDifference = endMoment.diff(startMoment, "months");
			// if (monthDifference > 0) {
			// 	if (isWeb) {
			// 		setOpen(false);
			// 		setRange({ startDate, endDate });
			// 		return setErrorMessage("The difference between start date and end date is more than a month.")
			// 	} else {
			// 		return Alert.alert(
			// 			"The difference between start date and end date is more than a month."
			// 		);
			// 	}
			// } else {
			// 	setErrorMessage("")
			// }

			setOpen(false);
			setRange({ startDate, endDate });
		},
		[setOpen, setRange]
	);

	React.useEffect(() => {
		registerTranslation("en", en);
	}, []);

	return (
		<View style={{ justifyContent: "center" }}>
			<DatePickerModal
				locale="en"
				mode="range"
				visible={open}
				onDismiss={onDismiss}
				startDate={range.startDate}
				endDate={range.endDate}
				onConfirm={onConfirm}
				presentationStyle="pageSheet"
				disableStatusBarPadding
				// validRange={
				// 	disabledEndDate
				// 		? { endDate: disabledEndDate }
				// 		: undefined
				// }

				// validRange={{
				// 	startDate: moment("2024-01-01").toDate(), // Set the start of selectable range
				// 	endDate: moment("2024-12-31").toDate()    // Set the end of selectable range
				// }}

				validRange={{
					startDate: (disabledStartDate) ? disabledStartDate : undefined,
					endDate: (disabledEndDate) ? disabledEndDate : undefined
				}}

			/>
		</View>
	);
}
