import { Ionicons } from "@expo/vector-icons";
import React, { useState } from "react";
import { ActivityIndicator, TouchableOpacity } from "react-native";
import tailwind from "twrnc";
import { TEACHERS } from "../../../../../../api/instance";
import useClasses from "../../../../../../context/classes";
import useUser from "../../../../../../context/user";
import { useNavigation } from "@react-navigation/native";
import Alert from "../../../../../../utils/alert";
import Text from "../../../../../../components/Text";

const ChatButton = ({ webinar = false, completed = null, teacher = "" }) => {
  const [loading, setLoading] = useState(false);

  const { upcoming } = useClasses();

  const data = completed ? completed : upcoming;

  const { navigate } = useNavigation();

  const { user } = useUser();

  const createChat = async () => {
    setLoading(true);
    let params = {
      teacher_id: webinar
        ? data.classTeachers[0].id
        : data.session.teachers[0].id,
      user_phone: webinar ? user.phone : data.session.users[0].phone,
    };

    try {
      const { data } = await TEACHERS.post(`/conversation/create`, params);
      setLoading(false);

      navigate("Chat", data.data);
    } catch (error) {
      Alert.alert("An error occured");
      setLoading(false);
    }
  };

  return (
    <>
      <TouchableOpacity
        disabled={loading}
        onPress={createChat}
        style={tailwind`${completed && `flex flex-row items-center gap-x-1 mt-2`
          }`}
      >
        <TouchableOpacity
          disabled={loading}
          onPress={createChat}
          style={tailwind`${!completed &&
            "bg-white rounded-full w-[30px] h-[30px] flex items-center justify-center"
            } `}
        >
          {loading ? (
            <ActivityIndicator />
          ) : (
            <Ionicons
              name="chatbox-ellipses-outline"
              size={18}
              color="#2096F3"
            />
          )}
        </TouchableOpacity>

        {completed && (
          <Text style={tailwind`text-[#878787] text-[12px] mt-[-2px]`}>
            {teacher?.name}
          </Text>
        )}
      </TouchableOpacity>
    </>
  );
};

export default ChatButton;
