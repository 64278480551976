import React, { useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import NotesDialog from "./upcoming_section/notes/notes_dialog";
import { useNavigation } from "@react-navigation/native";
import ChatButton from "./upcoming_section/chat_button";
import Alert from "../../../../../utils/alert";
import QuizDialog from "../../quizzes/quiz_dialog"

const CompeltedClassItem = ({ item, i }) => {
	const [notesModalOpen, setNotesModalOpen] = useState(false);

	const { navigate } = useNavigation();

	const [quizModalOpen, setQuizModalOpen] = useState(false);
	const [quizzes, setQuizzes] = useState([]);
	const [call, setCall] = useState(null);

	const handleQuiz = async (item, cls) => {
		setQuizzes(item)
		setCall(cls)
		setQuizModalOpen(true)
	};

	let topicName = "";
	var teacher = {};

	//   var isExpired = false;

	if (item.type == "WEBINAR") {
		topicName = `${item.webinar.name} - ${item.topic}`;
		teacher = item.classTeachers[0];
		if (
			item.webinar?.userSubscriptions != null &&
			item.webinar.userSubscriptions.isNotEmpty
		) {
			//   isExpired = item.webinar.userSubscriptions.every((element) =>
			//     DateTime.now().isAfter(
			//       DateTime.fromMillisecondsSinceEpoch(element.endDateSlot)
			//     )
			//   );
		}
	} else {
		topicName = item.topic_name ?? `${item?.session?.target_language} Class`;
		teacher = item?.session?.teachers[0];
	}


	return (
		<View key={i} style={tailwind`mr-5`}>
			<Image
				source={{ uri: teacher?.profile?.avatar }}
				style={tailwind`w-[180px] h-[180px] rounded`}
			/>
			<Text
				style={tailwind`capitalize text-[#4F4F4F] w-[180px] text-[16px] mt-2`}
			>
				{/* {item.topic_name ?? `${item.session.target_language} class`} */}
				{topicName}
			</Text>

			<Text style={tailwind`text-[13px]`}>with {teacher?.name}</Text>

			<ChatButton completed={item} teacher={teacher} />

			<TouchableOpacity
				style={tailwind`flex flex-row items-center gap-x-1 mt-1`}
			>
				<Ionicons name="people-outline" size={18} color="#878787" />
				<Text style={tailwind`text-[#878787] text-[12px] mt-[-2px]`}>
					{item.type === "WEBINAR" ? "Group Class" : "1 to 1 Class"}
				</Text>
			</TouchableOpacity>

			<TouchableOpacity
				style={tailwind`flex flex-row items-center gap-x-1 mt-1`}
			>
				<Ionicons name="calendar-outline" size={18} color="#878787" />
				<Text style={tailwind`text-[#878787] text-[12px] mt-[-2px]`}>
					{moment.unix(item.slot / 1000).format("hh:mm A MMM Do YYYY")}
				</Text>
			</TouchableOpacity>

			{(item.attached_quizzes?.length > 0 || item.attached_quizzes?.length > 0) && (
				<>
					{call && <QuizDialog
						open={quizModalOpen}
						setOpen={setQuizModalOpen}
						quizzes={quizzes}
						call={call}
					/>
					}

					<View style={[tailwind`flex-row pt-1`]}>
						<TouchableOpacity
							onPress={() => handleQuiz(item.attached_quizzes, item)}
							style={tailwind`flex flex-row items-center gap-x-1`}
						>
							<Ionicons
								name="file-tray-full-outline"
								size={18}
								color="#878787"
							/>
							<Text style={tailwind`text-[12px] mt-[-2px] underline `}>
								Quizzes
							</Text>
						</TouchableOpacity>
					</View>
				</>
			)}

			{(item.noteFiles[0] || item.noteLinks[0]) && (
				<>
					<NotesDialog
						open={notesModalOpen}
						setOpen={setNotesModalOpen}
						session={item}
					/>

					<TouchableOpacity
						onPress={() => setNotesModalOpen(true)}
						style={tailwind`flex flex-row items-center gap-x-2 mt-2`}
					>
						<Ionicons
							name="document-attach-outline"
							size={18}
							color="#878787"
						/>
						<Text
							style={tailwind`text-[#878787] text-[12px] mt-[-2px] underline`}
						>
							View Class Material
						</Text>
					</TouchableOpacity>
				</>
			)}

			<TouchableOpacity
				onPress={() =>
					navigate("Recording", {
						type: item.type,
						id: item.id,
					})
				}
				style={tailwind`flex flex-row items-center gap-x-1 mt-1`}
			>
				<Ionicons name="play-outline" size={18} color="#878787" />
				<Text
					style={tailwind`text-[#878787] text-[14px] mt-[-2px] text-[#2096F3] underline `}
				>
					View Recording
				</Text>
			</TouchableOpacity>
		</View>
	);
};

export default CompeltedClassItem;
