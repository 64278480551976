import { Pressable, View, TouchableOpacity, StyleSheet } from "react-native";
import tailwind from "twrnc";
import Text from "../../../components/Text";
import React, { useRef, useState } from "react";
import PhoneInput from "react-native-phone-number-input";
import CarouselLogin from "./carousel";
import AuthLayout from "../layout";
import { useNavigation } from "@react-navigation/native";
import { PasswordInput } from "../../../components/password-input";
import { API, APIV2 } from "../../../api/instance";
import useUser from "../../../context/user";
import RecaptchaNative from "react-native-recaptcha-that-works";
import LocalStorage from "../../../utils/localStorage";
import { isWeb } from "../../../constants/constants";
import RecaptchaWeb from "react-google-recaptcha";
import Alert from "../../../utils/alert";

const Login = () => {
	const { navigate } = useNavigation();

	const { checkIfLoggedIn } = useUser();

	const [phone, setPhone] = useState("");
	const [countryCode, setCountryCode] = useState("91");
	const [isChecked, setChecked] = useState(true);

	const $recaptcha = useRef();
	// $recaptcha.current.execute();

	const [password, setPassword] = useState("");

	const [loading, setLoading] = useState(false);

	const loginWithPass = async () => {
		if (!phone || !countryCode)
			return Alert.alert("Please enter a valid phone number");
		if (!password) return Alert.alert("Please enter a valid password");

		setLoading(true);

		const params = {
			phone,
			phone_formatted: `+${countryCode}${phone}`,
			password,
			opt_in: isChecked
		};

		try {
			const { data } = await API.post("/auth/login", params);

			await LocalStorage.setItem("user", JSON.stringify(data));
			setLoading(false);
			checkIfLoggedIn();
		} catch (error) {
			setLoading(false);
			console.log(JSON.stringify(error));
			return Alert.alert("The phone number or password is invalid.");
		}
	};

	const forgotPassword = async (token) => {
		if (!phone || !countryCode) {
			return Alert.alert("Please enter a valid phone number");
		}
		try {
			setLoading(true);

			const params = {
				phone,
				phone_formatted: `+${countryCode}${phone}`,
				phone_country_code: `+${countryCode}`,
				captcha_token: token,
			};
			const { data } = await APIV2.post("/auth/otp/generate", params);

			setLoading(false);
			navigate("OTP", {
				params: {
					id: data.data.otp_id,
					type: "forgot_password",
				},
			});
		} catch (error) {
			Alert.alert("An error occured");
			setLoading(false);
		}
	};

	const handleOpenPress = async () => {
		if (isWeb) {
			const token = await $recaptcha.current.executeAsync();
			return forgotPassword(token);
		}

		return $recaptcha.current.open();
	};

	const loginWithOtp = async () => {
		if (isWeb) {
			const token = await $recaptcha.current.executeAsync();
			return otpLogin(token);
		}

		return $recaptcha.current.open();
	};

	const otpLogin = async (token) => {
		if (!phone || !countryCode) {
			return Alert.alert("Please enter a valid phone number");
		}
		try {
			setLoading(true);

			const params = {
				phone,
				phone_formatted: `+${countryCode}${phone}`,
				phone_country_code: `+${countryCode}`,
				captcha_token: token,
				opt_in: isChecked
			};
			const { data } = await APIV2.post("/auth/otp/generate", params);

			setLoading(false);
			navigate("OTP", {
				params: {
					id: data.data.otp_id,
					type: "otp_login",
				},
			});
		} catch (error) {
			Alert.alert("An error occured");
			setLoading(false);
		}
	};

	return (
		<AuthLayout>
			<CarouselLogin />

			<View style={tailwind`w-full`}>
				<Text style={tailwind`mb-2 text-[12px] mt-5`}>Enter Mobile Number</Text>
			</View>

			{isWeb && (
				<RecaptchaWeb
					ref={$recaptcha}
					size="invisible"
					sitekey="6LecgIsjAAAAAK7TwGA22kf1fEVJ_lNtLymQm92u"
				/>
			)}

			{!isWeb && (
				<RecaptchaNative
					ref={$recaptcha}
					lang="en"
					siteKey="6LecgIsjAAAAAK7TwGA22kf1fEVJ_lNtLymQm92u"
					baseUrl="http://127.0.0.1"
					size={"invisible"}
					theme="light"
					onError={(err) => {
						console.log("error", err);
					}}
					onVerify={(token) => {
						forgotPassword(token);
					}}
				/>
			)}

			<PhoneInput
				defaultCode="IN"
				layout="first"
				onChangeText={(text) => setPhone(text)}
				onChangeCountry={(x) => setCountryCode(x.callingCode[0])}
				containerStyle={tailwind.style("h-[35px] w-full bg-[#fff]")}
				textContainerStyle={tailwind.style(
					"h-[35px] bg-[#fff] border border-[#F2F2F2] rounded px-3"
				)}
				textInputStyle={tailwind.style("h-[35px] w-full text-[14px] pl-1")}
				countryPickerButtonStyle={tailwind.style(
					"border border-[#F2F2F2] rounded mr-3"
				)}
				flagButtonStyle={tailwind`w-[90px]`}
				codeTextStyle={tailwind.style("text-[14px] h-[35px] mt-[18px]")}
				autoFocus
			/>

			<View style={tailwind`w-full`}>
				<Text style={tailwind`mb-2 text-[12px] mt-5`}>Password</Text>

				<PasswordInput password={password} setPassword={setPassword} />

				<TouchableOpacity
					style={[tailwind` pt-4`, styles.checkboxContainer]}
					onPress={() => setChecked(!isChecked)}
				>
					<View style={[styles.checkbox, isChecked && styles.checked]} />
					<Text style={styles.label}>
						By continuing you agree to receive notifications and call from our team on the phone number and email id provided for both informational and marketing purposes. Consent is not a condition to purchase.
					</Text>
				</TouchableOpacity>

				<Pressable disabled={loading} onPress={() => handleOpenPress()}>
					<Text style={tailwind`text-[#58ABF6] font-medium mt-2 text-[14px]`}>
						Forgot Password ?
					</Text>
				</Pressable>

				<Pressable
					disabled={loading}
					onPress={() => loginWithPass()}
					style={tailwind`mt-5 flex items-center justify-center py-3 rounded-lg ${loading ? "bg-[#c3c3c3]" : "bg-[#14C761]"
						}`}
				>
					<Text style={tailwind`text-[#fff] font-bold text-[18px]`}>
						{loading ? "Loading..." : "Login"}
					</Text>
				</Pressable>
			</View>
			<Text style={tailwind`text-[#878787] font-medium mt-3 text-[14px]`}>
				New user?{" "}
				<Text
					onPress={() => loginWithOtp()}
					style={tailwind`text-[#58ABF6]`}
				>
					Login with OTP																			`
				</Text>
			</Text>

			<Text style={tailwind`text-[#878787] mt-3 text-[12px] mt-4`}>
				Terms & Conditions
			</Text>
		</AuthLayout>
	);
};

export default Login;

const styles = StyleSheet.create({
	checkboxContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 20,
	},
	checkbox: {
		height: 12,
		width: 12,
		borderRadius: 4,
		borderWidth: 2,
		borderColor: 'gray',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 10,
	},
	checked: {
		backgroundColor: 'green',
	},
	label: {
		fontSize: 12,
	},
});


