import { Image, TouchableOpacity, View } from "react-native";
import Text from "../../../../../../components/Text";
import useClasses from "../../../../../../context/classes";
import tailwind from "twrnc";
import moment from "moment";
import {
	minutesCallBuffer,
	minutesJoinBuffer,
} from "../../../../../../constants/constants";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { useState } from "react";
import JoinClassDialog from "./join_class_dialog";
import CallDialog from "./call_dialog";
import { TEACHERS } from "../../../../../../api/instance";
import NotesDialog from "./notes/notes_dialog";
import ChatButton from "./chat_button";
import Alert from "../../../../../../utils/alert";

import QuizDialog from "../../../quizzes/quiz_dialog"

export const UpcomingWebinarCard = () => {
	const { upcoming, isTeacher } = useClasses();

	const [joinModalOpen, setModalOpen] = useState(false);

	const [notesModalOpen, setNotesModalOpen] = useState(false);

	const [callModalOpen, setCallModal] = useState(false);
	const [callLoading, setCallLoading] = useState(true);

	const sessionDateTime = moment.unix(upcoming.slot / 1000);
	const currentDateTime = moment();

	const sessionStartsInDuration = moment.duration(
		moment(sessionDateTime).diff(currentDateTime)
	);

	const joinCallEnabled =
		sessionStartsInDuration.asMinutes() < minutesJoinBuffer;

	const callTeacherEnabled =
		sessionStartsInDuration.asMinutes() < minutesCallBuffer;

	const handleJoinClass = () => {
		if (!joinCallEnabled) {
			return Alert.alert(
				`You can join the class ${minutesJoinBuffer} mins prior`
			);
		}

		if (!upcoming.meeting) {
			return Alert.alert("Meeting link not available");
		}

		setModalOpen(true);
	};

	const handleCallTeacher = async () => {
		if (!callTeacherEnabled) {
			return Alert.alert(
				`You can only initiate the call ${minutesCallBuffer} mins prior to the Class`
			);
		}
		setCallLoading(true);
		setCallModal(true);
		try {
			await TEACHERS.post("/clicktocall/initiate", {
				caller: "USER",
				caller_phone: "9680480916",
				session_id: upcoming.id,
				type: upcoming.type,
			});
			setCallLoading(false);
		} catch (error) {
			Alert.alert("An error occured");
			setCallModal(false);
		}
	};

	const [quizModalOpen, setQuizModalOpen] = useState(false);
	const [quizzes, setQuizzes] = useState([]);
	const [call, setCall] = useState(null);
	const handleQuiz = async (item, cls) => {
		setQuizzes(item)
		setCall(cls)
		setQuizModalOpen(true)
	};

	return (
		<View style={tailwind`w-full`}>
			<View style={tailwind`flex items-center flex-row justify-between w-full`}>
				<Text style={tailwind`font-bold mt-5 mb-5 text-[18px]`}>
					Next Class
				</Text>
			</View>

			<JoinClassDialog
				open={joinModalOpen}
				setOpen={setModalOpen}
				link={upcoming?.meeting?.invite_link}
			/>

			<CallDialog
				open={callModalOpen}
				setOpen={setCallModal}
				loading={callLoading}
			/>

			<NotesDialog
				open={notesModalOpen}
				setOpen={setNotesModalOpen}
				session={upcoming}
			/>

			{call && <QuizDialog
				open={quizModalOpen}
				setOpen={setQuizModalOpen}
				quizzes={quizzes}
				call={call}
			/>}

			<View
				style={tailwind`bg-[#51AADD] px-4 py-4 rounded w-full flex flex-row gap-x-2`}
			>
				<View>
					<Image
						source={{ uri: upcoming?.teachers[0].teacher?.profile?.avatar }}
						style={tailwind`w-[130px] h-[130px] rounded`}
					/>
					<Text
						style={tailwind`mt-1 text-white font-semibold text-[16px] max-w-[130px]`}
					>
						{upcoming?.teachers[0]?.teacher.name}
					</Text>

					<View style={tailwind`flex flex-row items-center gap-x-3 mt-2`}>
						<TouchableOpacity
							onPress={handleCallTeacher}
							style={tailwind`bg-white rounded-full w-[30px] h-[30px] flex items-center justify-center`}
						>
							<Ionicons name="call" size={18} color="#2096F3" />
						</TouchableOpacity>

						{!isTeacher && <ChatButton webinar />}
					</View>
				</View>

				<View style={tailwind`flex items-start`}>
					<View style={tailwind`flex flex-row items-start gap-x-2`}>
						<Ionicons name="people-outline" size={20} color="#fff" />
						<Text style={tailwind`text-[#fff] w-[150px]`}>
							{upcoming.webinar.name} - {upcoming.topic}
						</Text>
					</View>

					<View style={tailwind`flex flex-row items-center gap-x-2 mt-2`}>
						<AntDesign name="clockcircleo" size={20} color="#fff" />
						<Text style={tailwind`text-white`}>
							{upcoming.duration / 60000} mins
						</Text>
					</View>

					<View style={tailwind`flex flex-row items-center gap-x-2 mt-2`}>
						<Ionicons name="calendar-outline" size={20} color="#fff" />
						<Text style={tailwind`text-white max-w-[150px]`}>
							{sessionStartsInDuration.asMinutes() < 0
								? `Ongoing (${upcoming.webinar.slot_start_time || upcoming.slot_start_time
								} - ${upcoming.webinar.slot_end_time || upcoming.slot_end_time
								})`
								: moment.unix(upcoming.slot / 1000).calendar(null, {
									sameDay: "[Today] hh:mm a",
									nextDay: "[Tomorrow] hh:mm a",
									nextWeek: "ddd MMM D, h:mm a",
									lastDay: "ddd MMM D, h:mm a",
									lastWeek: "ddd MMM D, h:mm a",
									sameElse: "ddd MMM D, h:mm a",
								})}
						</Text>
					</View>

					{(upcoming.noteFiles[0] || upcoming.noteLinks[0]) && (
						<TouchableOpacity
							onPress={() => setNotesModalOpen(true)}
							style={tailwind`flex flex-row items-center gap-x-2 mt-2`}
						>
							<Ionicons name="document-attach-outline" size={20} color="#fff" />
							<Text style={tailwind`text-white underline`}>
								View Class Material
							</Text>
						</TouchableOpacity>
					)}

					{(upcoming.attached_quizzes && upcoming.attached_quizzes.length > 0) && (
						<TouchableOpacity
							onPress={() => handleQuiz(upcoming.attached_quizzes, upcoming)}
							style={tailwind`flex flex-row items-center gap-x-2 mt-2`}
						>
							<Ionicons name="file-tray-full-outline" size={20} color="#fff" />
							<Text style={tailwind`text-white underline`}>
								Quizzes
							</Text>
						</TouchableOpacity>
					)}

					<TouchableOpacity
						onPress={handleJoinClass}
						style={tailwind`mt-2 px-4 py-2 rounded ${joinCallEnabled ? "bg-[#FF995A]" : "bg-[#a6a6a6]"
							}`}
					>
						<Text style={tailwind`text-white font-semibold`}>Join Class</Text>
					</TouchableOpacity>
				</View>
			</View>
		</View>
	);
};
