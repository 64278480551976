import React, { useEffect, useState } from "react";
import Text from "../../../../components/Text";
import Layout from "../../layout";
import SecondaryHeader from "../../headers/secondary.header";
import { ActivityIndicator, TouchableOpacity, View } from "react-native";
import tailwind from "twrnc";
import useUser from "../../../../context/user";
import { TEACHERS } from "../../../../api/instance";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import Alert from "../../../../utils/alert";

const Chats = () => {
  const [loading, setLoading] = useState(true);

  const { user, isTeacher } = useUser();

  const { navigate } = useNavigation();

  const [chats, setChats] = useState([]);

  const getChats = async () => {
    const path = isTeacher
      ? `/conversation/teacher/${user._id}/all?limit=15`
      : `conversation/student/${user.phone}/all?limit=15`;
    setLoading(true);
    try {
      const { data } = await TEACHERS.get(path);
      setChats(data.data.items);

      setLoading(false);
    } catch (error) {
      Alert.alert("An error occured");
      setLoading(false);
    }
  };

  useEffect(() => {
    getChats();
  }, []);

  return (
    <Layout
      tabbarVisible={false}
      HeaderContent={<SecondaryHeader title="Chats" />}
      scrollView={false}
    >
      <View style={tailwind`w-full`}>
        {loading ? (
          <View style={tailwind`w-full h-full flex items-center mt-10`}>
            <ActivityIndicator />
          </View>
        ) : (
          <>
            {!chats[0] ? (
              <View style={tailwind`w-full h-full flex items-center mt-10`}>
                <Text>No Chats Available</Text>
              </View>
            ) : (
              <View style={tailwind`mt-5`}>
                {chats.map((chat, i) => {
                  const name = isTeacher ? chat.user_name : chat.teacher_name;

                  return (
                    <TouchableOpacity
                      onPress={() => navigate("Chat", chat)}
                      key={i}
                      style={tailwind`mb-5 flex flex-row justify-between`}
                    >
                      <View>
                        <Text
                          style={tailwind`font-bold text-[#4F4F4F] text-[16px]`}
                        >
                          {name}
                        </Text>

                        <Text style={tailwind`text-[#757575] max-w-[200px]`}>
                          {chat.last_message}
                        </Text>
                      </View>

                      <View style={tailwind`flex flex-col items-end`}>
                        {isTeacher
                          ? chat.teacher_unread_count > 0 && (
                            <View
                              style={tailwind`bg-[#FF9958] rounded-full w-[17px] h-[17px] flex mb-1 items-center justify-center`}
                            >
                              <Text style={tailwind`text-white text-[11px]`}>
                                {chat.teacher_unread_count}
                              </Text>
                            </View>
                          )
                          : chat.student_unread_count > 0 && (
                            <View
                              style={tailwind`bg-[#FF9958] rounded-full w-[17px] h-[17px] flex mb-1 items-center justify-center`}
                            >
                              <Text style={tailwind`text-white text-[11px]`}>
                                {chat.student_unread_count}
                              </Text>
                            </View>
                          )}
                        <Text
                          style={tailwind`text-[#757575] text-[12px] font-medium`}
                        >
                          {moment
                            .unix(chat.last_message_time / 1000)
                            .format("hh:mm a MMM D")}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  );
                })}
              </View>
            )}
          </>
        )}
      </View>
    </Layout>
  );
};

export default Chats;
