import { View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../components/Text";

const ProfileHeader = () => {
  return (
    <View style={tailwind` px-5 my-0 py-0 flex flex-row h-full items-center`}>
      <Text style={tailwind`text-[18px] font-semibold`}>Profile</Text>
    </View>
  );
};

export default ProfileHeader;
