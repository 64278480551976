import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import tailwind from "twrnc";
import Text from "../../../../../components/Text";
import moment from "moment";
import RangePicker from "./range_picker_modal";
import useClassesFilter from "./context";

const RangePickerInput = () => {
	const { range, setRange } = useClassesFilter();

	const [open, setOpen] = useState(false);
	return (
		<View style={tailwind`mt-5`}>
			<View style={tailwind`flex items-center flex-row justify-between`}>
				<Text style={tailwind`text-[#878787] font-semibold text-[16px]`}>
					Date
				</Text>
				<TouchableOpacity
					style={tailwind`text-[#878787] font-semibold text-[12px]`}
					onPress={() => setRange({ startDate: undefined, endDate: undefined })}
				>
					<Text>Clear</Text>
				</TouchableOpacity>
			</View>

			<TouchableOpacity
				onPress={() => setOpen(true)}
				style={tailwind`w-full border border-[#9E9E9E] rounded px-2 py-2`}
			>
				<Text>
					{range.startDate && range.endDate
						? range.startDate === range.endDate
							? moment(range.startDate).format("Do MMM YYYY")
							: `${moment(range.startDate).format("Do MMM YYYY")} - ${moment(
								range.endDate
							).format("Do MMM YYYY")}`
						: ""}
				</Text>
			</TouchableOpacity>

			<RangePicker
				range={range}
				setRange={setRange}
				open={open}
				setOpen={setOpen}
			/>
		</View>
	);
};

export default RangePickerInput;
