import { View, Text, Image, StyleSheet, TouchableOpacity } from "react-native";
import { React, useState } from "react";
import tailwind from "twrnc";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import ChatButton from "../chat_button";
import { useNavigation } from "@react-navigation/native";
import NotesDialog from "../../home/classes/upcoming_section/notes/notes_dialog";
import QuizDialog from "../../quizzes/quiz_dialog"

export default function SessionItem({ item, i }) {
	const { navigate } = useNavigation();
	// console.log(item)
	let session = {};
	let teachers = [];
	let users = [];
	let status = {};

	if (item.session) session = item.session;

	if (session.teachers && session.teachers.length > 0)
		teachers = session.teachers;

	if (session.users && session.users.length > 0) users = session.users;

	if (item.status) status = item.status;

	let cls_start_time = "";
	let cls_end_time = "";
	let time_duration = "";
	if (item) {
		cls_start_time = moment(item.slot_start_time, "hh:mm A");
		cls_end_time = moment(item.slot_end_time, "hh:mm A");
		time_duration = cls_end_time.diff(cls_start_time, "minutes");
	}

	const [notesModalOpen, setNotesModalOpen] = useState(false);

	const [quizModalOpen, setQuizModalOpen] = useState(false);
	const [quizzes, setQuizzes] = useState([]);
	const [call, setCall] = useState(null);

	const handleQuiz = async (item, cls) => {
		setQuizzes(item)
		setQuizModalOpen(true)
		setCall(cls)
	};

	return (
		<View style={tailwind`bg-gray-200 mx-auto my-1 w-full`}>
			<View style={tailwind` p-2`}>
				<View style={tailwind` flex flex-row w-full`}>
					<View style={[tailwind`px-0`, styles.card_sec1]}>
						<View style={tailwind``}>
							<Image
								source={{ uri: teachers[0]?.profile?.avatar }}
								style={tailwind`w-[85px] h-[85px] rounded`}
							/>
							<Text style={tailwind`text-left py-1 text-[12px]`}>
								{teachers[0]?.name}
							</Text>
						</View>
					</View>

					<View style={[tailwind`px-2 `, styles.card_sec2]}>
						<Text>{item.topic_name}</Text>
						<View style={[tailwind`flex-row flex items-center pt-2`]}>
							<ChatButton class_user={users[0]} teacher={teachers[0]} />

							<Text style={[tailwind`pl-1 text-[12px]`]}>
								{teachers[0]?.name}
							</Text>
						</View>

						<View style={[tailwind`flex-row pt-1 items-center`]}>
							<Ionicons name="calendar-outline" size={18} color="#878787" />
							<Text style={[tailwind`pl-1 text-[12px]`]}>
								{moment.unix(item.slot / 1000).format("hh:mm A MMM Do")}
							</Text>
						</View>

						{status?.name && status?.name == "Complete" && (
							<View style={[tailwind`flex-row pt-1`]}>
								<TouchableOpacity
									style={tailwind`flex flex-row items-center gap-x-1`}
									onPress={() =>
										navigate("Recording", {
											type: "SESSION",
											id: item.id,
										})
									}
								>
									<Ionicons name="play-outline" size={18} color="#878787" />
									<Text
										style={tailwind`text-[#f5b436] text-[14px] mt-[-2px] underline `}
									>
										View Recording
									</Text>
								</TouchableOpacity>
							</View>
						)}

						{(item.attached_quizzes?.length > 0 || item.attached_quizzes?.length > 0) && (
							<>
								{call && <QuizDialog
									open={quizModalOpen}
									setOpen={setQuizModalOpen}
									quizzes={quizzes}
									call={call}
								/>
								}

								<View style={[tailwind`flex-row pt-1`]}>
									<TouchableOpacity
										onPress={() => handleQuiz(item.attached_quizzes, item)}
										style={tailwind`flex flex-row items-center gap-x-1`}
									>
										<Ionicons
											name="file-tray-full-outline"
											size={18}
											color="#878787"
										/>
										<Text style={tailwind`text-[12px] mt-[-2px] underline `}>
											Quizzes
										</Text>
									</TouchableOpacity>
								</View>
							</>
						)}

						{(item.noteFiles?.length > 0 || item.noteLinks?.length > 0) && (
							<>
								<NotesDialog
									open={notesModalOpen}
									setOpen={setNotesModalOpen}
									session={item}
								/>
								<View style={[tailwind`flex-row pt-1`]}>
									<TouchableOpacity
										onPress={() => setNotesModalOpen(true)}
										style={tailwind`flex flex-row items-center gap-x-1`}
									>
										<Ionicons
											name="document-text-outline"
											size={18}
											color="#878787"
										/>
										<Text style={tailwind`text-[12px] mt-[-2px] underline `}>
											View Class Material
										</Text>
									</TouchableOpacity>
								</View>
							</>
						)}
					</View>

					<View style={[tailwind`px-0 `, styles.card_sec3]}>
						{status?.name && status?.name == "Complete" && (
							<Text
								style={tailwind`text-center bg-[#4caf50] text-white text-xs p-0.5 rounded dark:bg-[#4caf50] dark:text[#4caf50]`}
							>
								{item.status.name}
							</Text>
						)}

						{status?.name && status?.name == "Schedule" && (
							<Text
								style={tailwind`text-center bg-[#2196f3] text-white text-xs p-0.5 rounded dark:bg-[#2196f3] dark:text[#2196f3]`}
							>
								{item.status.name}
							</Text>
						)}

						<View style={[tailwind`flex-row py-2`]}>
							<Text
								style={tailwind`text-center bg-[#607d8b] text-white text-[12px] py-0.5 px-1 rounded dark:bg-[#607d8b] dark:text[#607d8b]`}
							>
								{session.source_language.substring(0, 2)}
							</Text>

							{session.call_type == "video" && (
								<Ionicons
									name="videocam"
									size={18}
									color="#2196f3"
									style={tailwind`px-1`}
								/>
							)}

							{session.call_type == "audio" && (
								<Ionicons
									name="call"
									size={18}
									color="#2196f3"
									style={tailwind`px-1`}
								/>
							)}

							<Text
								style={tailwind`text-center bg-[#607d8b] text-white text-[12px] py-0.5 px-1 rounded dark:bg-[#607d8b] dark:text[#607d8b]`}
							>
								{session.target_language.substring(0, 2)}
							</Text>
						</View>

						<View style={[tailwind`flex-row`]}>
							<Text
								style={tailwind`text-center bg-[#607d8b] text-white text-[12px] py-0.5 px-1 rounded dark:bg-[#607d8b] dark:text[#607d8b]`}
							>
								{time_duration} mins
							</Text>
						</View>
					</View>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	loadercontainer: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		marginTop: "50%",
		marginBottom: "50%",
	},
	activityIndicator: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	card_sec1: {
		width: "25%",
		// backgroundColor: "red"
	},
	card_sec2: {
		width: "55%",
		// backgroundColor: "blue"
	},
	card_sec3: {
		width: "20%",
	},
});
