import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import SecondaryHeader from "../../../headers/secondary.header";
import { ActivityIndicator, View } from "react-native";
import tailwind from "twrnc";
import { TEACHERS } from "../../../../../api/instance";
import useUser from "../../../../../context/user";
import Guidelines from "../modals/guidelines";
import { useNavigation } from "@react-navigation/native";
import Expired from "../modals/expired";
import Chat from "./chat";
import Alert from "../../../../../utils/alert";

const ChatContainer = ({ route }) => {
  const { params } = route;

  const { isTeacher } = useUser();
  const { goBack } = useNavigation();

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();

  const getStatus = async () => {
    setLoading(true);
    try {
      const { data } = await TEACHERS.get(
        `/conversation/availability?user_phone=${params.user_phone}&teacher_id=${params.teacher_id}&is_teacher=${isTeacher}`
      );
      setStatus(data.data);
      setLoading(false);
      setModalOpen(true);
    } catch (error) {
      Alert.alert("Error");
      setLoading(false);
      goBack();
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <Layout
      tabbarVisible={false}
      HeaderContent={
        <SecondaryHeader
          title={!isTeacher ? params.teacher_name : params.user_name}
          isChat
        />
      }
      scrollView={false}
    >
      <View style={tailwind`w-full`}>
        {loading ? (
          <View style={tailwind`w-full h-full flex items-center mt-10`}>
            <ActivityIndicator />
          </View>
        ) : (
          <>
            {status?.activeClasses && !status?.blocked && (
              <>
                <Guidelines open={modalOpen} setOpen={setModalOpen} />
                <Chat chat={params} />
              </>
            )}

            {!status?.activeClasses && !status?.blocked && (
              <Expired
                open={modalOpen}
                setOpen={setModalOpen}
                goBack={goBack}
              />
            )}
          </>
        )}
      </View>
    </Layout>
  );
};

export default ChatContainer;
