import {
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Image,
    Pressable,
    ScrollView,
    FlatList,
    Platform,
    ActivityIndicator,
    Button,
    ImageBackground,
    Linking
} from "react-native";
import React, { useState, useEffect } from "react";
import tailwind from "twrnc";
import { height, isWeb, width } from "../../../../../constants/constants";
import { useNavigation, useNavigationParam } from "@react-navigation/native";
import { AntDesign, Ionicons } from "@expo/vector-icons";

import { API } from "../../../../../api/instance";
import Layout from "../../../layout";
import PageHeader from "../../../headers/page.header";
let wd = width <= 500 ? width : 499.99
import useQuestion from "./common/context";
import moment from "moment";
import useUser from "../../../../../context/user";

export default function CourseCompleted(props) {
    const { navigate } = useNavigation();
    let { user } = useUser();

    const {
        module,
        unit,
        selected_course,
        total_correct_answer,
        total_question,
        current_level_index } = useQuestion();

    let percentage = (total_correct_answer / total_question) * 100
    percentage = Math.round(percentage)

    let [course_completed_images, setCourseCompletedImages] = useState([
        "HIN_BOY_thumbs_up_half.png",
        "HIN_GIRL_thumbs_up_half.png",
        "HIN_BOY_kisses_half.png",
        "HIN_GIRL_kisses_half.png"
    ]);

    const [total_lessons, setTotalLessons] = useState(0);

    useEffect(() => {
        let count = 0
        let total_lessons = module.levels[current_level_index].units.map(item => {
            let lsn = item.lessons.length
            count = count + +lsn
        })
        setTotalLessons(count)
    }, []);


    let skipToHome = () => {
        navigate("Home");
    }

    let redirectClassesWebsite = async () => {
        // let url = `https://classes.multibhashi.com/courses`

        let url = `https://classes.multibhashi.com/courses`
        if (selected_course.classes_web_url)
            url = selected_course.classes_web_url

        const supported = await Linking.canOpenURL(url);

        if (supported) {
            // Open the link
            await Linking.openURL(url);
        } else {
            Alert.alert(`Don't know how to open this URL: ${url}`);
        }
    }

    return (
        <Layout
            HeaderContent={
                <PageHeader backRoute={"Home"} pageTitle={unit.name} bgColor={"#17AAF5"} textColor={"#f7faf8"} />
            }
            tabbarVisible={false}
        // scrollView={false}
        >
            <View style={[tailwind`w-full h-full`]}>
                <View style={[tailwind`w-full h-[300px]`, {
                }]} >
                    <ImageBackground
                        source={require("../../../../../../assets/course_complete_bg.png")}
                        resizeMode="contain"
                        style={[tailwind`w-full `, {
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }]}
                    >
                        <Image
                            source={require(`../../../../../../assets/datacharacter_illustrations/${course_completed_images[Math.floor(Math.random() * course_completed_images.length)]}`)}
                            style={[tailwind``, {
                                width: 200,
                                height: 280,
                                marginBottom: 0,

                            }]}
                        />
                    </ImageBackground>
                </View>


                {/* </View> */}

                <View style={[tailwind`px-2 shadow-lg  rounded py-4 text-center items-center`]}>
                    <Text style={[tailwind`text-black font-bold text-[26px]`]}>
                        Great Job!!
                    </Text>

                    <Text style={[tailwind`px-4 mt-6 text-slate-500 font-bold text-[20px]`]}>
                        You Have Finished All the Free {selected_course.title}
                        Lessons!!
                    </Text>
                </View>
                <View style={[tailwind`px-2 mx-4  py-4 flex flex-row text-center items-center justify-center  border-t-0 border-b border-slate-300`]}>
                    <Ionicons name="document-text-outline" size={25} color="#2096f3" />
                    <Text style={[tailwind`pl-2 text-slate-500 font-semibold text-[20px] items-center`]}>

                        {
                            (module.type == "CONVERSATION" || module.type == "CUSTOM") ? `All ${module.levels[current_level_index].units.length} Lessons Completed!!` : `All ${module.levels[current_level_index].units.length} Units and ${total_lessons} Lessons Completed!!`

                        }
                    </Text>
                </View>

                <View style={[tailwind`px-2 shadow-lg  rounded py-4 mb-4 mt-2`]}>

                    <View style={tailwind`px-4 flex flex-row w-full`}>
                        <View style={[tailwind``, styles.card_sec1]}>
                            <Text style={[tailwind`text-black font-semibold text-[20px]`]}>
                                Enroll for {selected_course.display_text}
                            </Text>
                        </View>

                        <View style={[tailwind``, styles.card_sec2]}>
                            <TouchableOpacity
                                onPress={() => skipToHome()}
                                style={tailwind`flex flex-row items-center gap-x-2 mt-2`}
                            >
                                <Text style={tailwind`underline`}>
                                    Skip to Home
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <Text style={[tailwind`px-4 mt-6 text-slate-500 font-semibold text-[16px]`]}>
                        Time to Level Up and Learn with Expert Trainers
                    </Text>


                    <View style={[tailwind`px-4 mt-4`, {
                        width: (wd - 40)
                    }]}>
                        <Pressable
                            style={[tailwind``, styles.buttonStyle, {
                                backgroundColor: "#3EBD3E"
                            }]}
                            onPress={() => redirectClassesWebsite()}
                        >
                            <Text style={[styles.buttonText, {
                                color: "#FFFFFF"
                            }]}> Enrol Now </Text>
                        </Pressable>
                    </View>
                </View>
            </View>
        </Layout >
    );
}

const styles = StyleSheet.create({
    img: {
        resizeMode: "contain",
        width: 200,
        height: 200,
        // objectFit: "contain"
    },
    loadercontainer: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
        marginTop: 50,
        marginBottom: "50%",
    },
    activityIndicator: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        verticalAlign: "middle",
    },
    buttonStyle: {
        // borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 18,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginVertical: 30,
    },
    buttonText: {
        fontWeight: "bold",
        // color: "#fff",
    },

    lineStyle: {
        borderWidth: 0.01,
        borderColor: "#909090",
        margin: 10,
    },

    card_sec1: {
        width: "80%",
        // backgroundColor: "red"
        // justifyContent: "center",
        // alignItems: "center",
    },
    card_sec2: {
        width: "50%",
        // justifyContent: "center",
        // alignItems: "center",
        // backgroundColor: "blue"
    },
});
